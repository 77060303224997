import React, { Component } from "react";
import { Icon, Comment, Empty } from 'antd';
import PostAcommentView from '@/components/ForumView/PostAcomment/PostAcomment';
import AvatarView from '../AvatarView/AvatarView';
import * as api from '@/axios/api';
import activitiesCode from '@/axios/activitiesCode';
import './CommentListView.less';
import Truncate from 'react-truncate';
import _ from 'lodash';
import moment from "moment";

export default class CommentListView extends Component {
    constructor(props) {
        super(props);
        this.state = {
            userName: this.props.userInfo.username || '',
            value: 1,
            comments: this.props.commentsList || [],
            action: null,
            myActionList: {
                likes: [],
                dislikes: []
            },
            isShowPostAReply: false,
            postAReplyIndex: '',
            isShowMoblieCommentsControlView: false,
            isShowCommentsList: true,
            //show more/less变量 
            truncated: [],
            expanded: [],
            hoverCommentIndex: -1,
            myEditCommentIndex: -1,
            operateRepliesIndex: -1,
            showMoreFun: false
        }
    }
    componentWillReceiveProps(newProps) {
        if (newProps.commentsList !== this.state.comments) {
            this.setState({
                comments: newProps.commentsList
            })
        }
    }

    like = (index, cid) => {
        const { likes, dislikes } = this.state.myActionList;
        const newComments = this.state.comments;
        let newlikes = _.cloneDeep(likes);
        let newDislikes = _.cloneDeep(dislikes);
        let { sid } = this.props.OperateRelatedId

        if (likes.indexOf(index) !== -1) {
            //取消like
            if (newComments[index].sup > 0) {
                newComments[index].sup -= 1
            }
            newlikes = likes.filter(likesIndex => index !== likesIndex)
            this.setState({
                comments: newComments,
                myActionList: {
                    ...this.state.myActionList,
                    likes: newlikes
                }
            });

            let data = {
                type: -1,
                like: true
            }

            api.likeComments(sid, cid, data).then((reslove) => {
                //this.props.updateCommentsList()
                //activitiesApi
                let uid = this.props.userInfo.uid
                let body = {
                    action: activitiesCode.upOrDownStory,
                    objectid: cid,
                    meta: JSON.stringify(data),
                }
                api.activitiesApi(uid, body).then(response => {
                })
            }, reject => {
                //this.props.updateCommentsList()
            })
            return
        }
        newComments[index].sup += 1
        if (dislikes.indexOf(index) !== -1) {
            if (newComments[index].sdown > 0) {
                newComments[index].sdown -= 1;
            }
            newDislikes = dislikes.filter(disLikeIndex => index !== disLikeIndex)

            let data = {
                type: -1,
                like: false
            }

            api.likeComments(sid, cid, data).then((reslove) => {
                //this.props.updateCommentsList()
                //activitiesApi
                let uid = this.props.userInfo.uid
                let body = {
                    action: activitiesCode.upOrDownStory,
                    objectid: cid,
                    meta: JSON.stringify(data),
                }
                api.activitiesApi(uid, body).then(response => {
                })
            }, reject => {
                //this.props.updateCommentsList()
            })
        }

        this.setState({
            comments: newComments,
            myActionList: {
                likes: [...likes, index],
                dislikes: newDislikes
            }
        });


        let data = {
            type: 1,
            like: true
        }

        api.likeComments(sid, cid, data).then((reslove) => {
            //this.props.updateCommentsList()
            //activitiesApi
            let uid = this.props.userInfo.uid
            let body = {
                action: activitiesCode.upOrDownStory,
                objectid: cid,
                meta: JSON.stringify(data),
            }
            api.activitiesApi(uid, body).then(response => {
            })
        }, reject => {
            //this.props.updateCommentsList()
        })
    };

    dislike = (index, cid) => {

        const { likes, dislikes } = this.state.myActionList;
        const newComments = this.state.comments;
        let newlikes = _.cloneDeep(likes);
        let newDislikes = _.cloneDeep(dislikes);
        let { sid } = this.props.OperateRelatedId


        if (dislikes.indexOf(index) !== -1) {
            //取消dislike
            if (newComments[index].sdown > 0) {
                newComments[index].sdown -= 1;
            }
            newDislikes = dislikes.filter(disLikeIndex => index !== disLikeIndex)
            this.setState({
                comments: newComments,
                myActionList: {
                    ...this.state.myActionList,
                    dislikes: newDislikes
                }
            });
            let data = {
                type: -1,
                like: false
            }

            api.likeComments(sid, cid, data).then((reslove) => {
                // this.props.updateCommentsList()
                //activitiesApi
                let uid = this.props.userInfo.uid
                let body = {
                    action: activitiesCode.upOrDownStory,
                    objectid: cid,
                    meta: JSON.stringify(data),
                }
                api.activitiesApi(uid, body).then(response => {
                })
            }, reject => {
                // this.props.updateCommentsList()
            })
            return
        }

        newComments[index].sdown += 1

        if (likes.indexOf(index) !== -1) {
            if (newComments[index].sup > 0) {
                newComments[index].sup -= 1
            }
            newlikes = likes.filter(likesIndex => index !== likesIndex)
            let data = {
                type: -1,
                like: true
            }

            api.likeComments(sid, cid, data).then((reslove) => {
                //this.props.updateCommentsList()
                //activitiesApi
                let uid = this.props.userInfo.uid
                let body = {
                    action: activitiesCode.upOrDownStory,
                    objectid: cid,
                    meta: JSON.stringify(data),
                }
                api.activitiesApi(uid, body).then(response => {
                })
            }, reject => {
                //this.props.updateCommentsList()
            })
        }
        this.setState({
            comments: newComments,
            myActionList: {
                likes: newlikes,
                dislikes: [...dislikes, index]
            }
        });

        let data = {
            type: 1,
            like: false
        }

        api.likeComments(sid, cid, data).then((reslove) => {
            // this.props.updateCommentsList()
            //activitiesApi
            let uid = this.props.userInfo.uid
            let body = {
                action: activitiesCode.upOrDownStory,
                objectid: cid,
                meta: JSON.stringify(data),
            }
            api.activitiesApi(uid, body).then(response => {
            })
        }, reject => {
            // this.props.updateCommentsList()
        })
    };

    postACommentListener = (type, id, textValue) => {
        let newComment = {
            name: this.state.userName,
            date: moment.format(),
            text: textValue,
            likes: 0,
            dislikes: 0,
            replies: [],
            author: this.props.userInfo.username
        }
        if (type === "replayAComment") {
            if (this.state.comments) {
                //console.log("this.state.comments",this.state.comments)
                //this.state.comments.push(newComment);
                this.state.comments[id].replies.unshift(newComment)
                //console.log("this.state.comments22", this.state.comments)
                this.setState({
                    comments: this.state.comments,
                    textValue: '',
                    isShowPostAReply: false
                })
            }
        }
    }

    replyAComment = (index, comment, cid) => {

        this.setState({
            textValue: '',
            isShowPostAReply: false,
            operateRepliesIndex: index
        })

        let body = {
            comment
        }

        let { sid } = this.props.OperateRelatedId
        api.replyComments(sid, cid, body).then((reslove) => {
            this.props.updateCommentsList()
            //activitiesApi
            let uid = this.props.userInfo.uid
            let body = {
                action: activitiesCode.reply,
                objectid: cid,
                meta: cid.toString(),
            }
            api.activitiesApi(uid, body).then(response => {
            })
        }, reject => {
            this.props.updateCommentsList()
        })
    }
    inputReplyText = ({ target: { value } }) => {
        this.setState({ replyValue: value });
    };

    hideReplies = (index) => {
        this.setState({
            operateRepliesIndex: -1
        })
    }
    showReplies = (index) => {
        this.setState({
            operateRepliesIndex: index
        })
    }

    replyTo = (index) => {
        this.setState({
            isShowPostAReply: true,
            postAReplyIndex: index,
        })
    }


    changeTruncated(index) {
        //let newState = !this.state.truncated[index]
        let newTruncated = _.cloneDeep(this.state.truncated);
        newTruncated[index] = !newTruncated[index]
        //this.state.truncated[index] = newState
        this.setState({
            //truncated: this.state.truncated
            truncated: newTruncated
        })
    }
    hoverComment = (index) => {
        this.setState({
            hoverCommentIndex: index
        })
    }
    hoverOutComment = () => {
        this.setState({
            hoverCommentIndex: -1
        })
    }
    editMyComment = (index) => {
        //this.props.hiddenCommentAStory(true)
        //
        this.setState({
            myEditCommentIndex: index,
            myEditComment: this.state.comments[index].content,
            showMoreFun: false
        })

    }
    deleteMyComment = (index) => {
        //this.props.hiddenCommentAStory(true)
        const { comments } = this.state;
        // let newCommentsList = _.cloneDeep(comments)
        // newCommentsList.splice(index, 1);
        this.setState({
            //comments: newCommentsList,
            showMoreFun: false
        })
        let { sid } = this.props.OperateRelatedId
        api.deleteReply(sid, comments[index].cid).then(() => {
            this.props.updateCommentsList()
            //activitiesApi
            let uid = this.props.userInfo.uid
            let body = {
                action: activitiesCode.deleteReply,
                objectid: comments[index].cid,
                meta: comments[index].cid.toString(),
            }
            api.activitiesApi(uid, body).then(response => {
            })
        }, reject => {
            this.props.updateCommentsList()
        })
    }
    ReEditMyComment = (index, value) => {
        const { comments } = this.state;
        // let newCommentsList = _.cloneDeep(comments)
        // newCommentsList[index].text = value
        this.setState({
            myEditCommentIndex: -1,
            //  comments: newCommentsList
        })
        let body = {
            comment: value
        }
        let { sid } = this.props.OperateRelatedId
        api.updateReply(sid, comments[index].cid, body).then(() => {
            this.props.updateCommentsList()
            //activitiesApi
            let uid = this.props.userInfo.uid
            let body = {
                action: activitiesCode.updateReply,
                objectid: comments[index].cid,
                meta: comments[index].content,
            }
            api.activitiesApi(uid, body).then(response => {
            })
        }, reject => {
            this.props.updateCommentsList()
        })
    }
    cancelReEditMyComment = () => {
        this.setState({
            myEditCommentIndex: -1
        })
    }
    showMoreFun = () => {
        this.setState({
            showMoreFun: true
        })
    }
    updateCommentsList = () => {
        this.props.updateCommentsList()
    }
    render() {
        const { comments, isShowPostAReply, postAReplyIndex, truncated,
            hoverCommentIndex, operateRepliesIndex, myEditCommentIndex, myEditComment, showMoreFun, myActionList } = this.state;
        const { isFromMyCommentsPage, isSmallAvatar, userInfo, isSubCommentListView, OperateRelatedId, intl } = this.props;

        //console.log("render", isSubCommentListView, comments)

        return (<div className='commentsList' id='commentsList'>
            {comments ? comments.map((comment, index) => {
                return (
                    myEditCommentIndex === index ?
                        <div style={{ marginTop: '16px' }}
                            key={index + '_'}>
                            <PostAcommentView
                                // userName={userName}
                                textValue={myEditComment}
                                postACommentListener={(value) => this.ReEditMyComment(index, value)}
                                cancelCommentListener={(value) => this.cancelReEditMyComment()}
                                {...this.props}>
                            </PostAcommentView>
                        </div>
                        :
                        <Comment
                            key={index + '_'}
                            author={isFromMyCommentsPage ? null : <span
                                onMouseEnter={() => this.hoverComment(index)}
                                onMouseLeave={() => this.hoverOutComment()}
                                className='commentAuthor'>{comment.user && comment.user.username}</span>}
                            avatar={
                                isFromMyCommentsPage ?
                                    null
                                    :
                                    <AvatarView isSmallAvatar={isSmallAvatar} userInfo={comment.user}
                                    />
                            }
                            content=
                            {
                                <div style={{ position: 'relative', display: 'flex', flexDirection: 'column' }}
                                    onMouseEnter={() => this.hoverComment(index)}
                                    onMouseLeave={() => this.hoverOutComment()}>
                                    {
                                        (userInfo && userInfo.uid === comment.uid)
                                            ?
                                            <div className='commentOperationDiv' >
                                                <Icon type=' ' className='more' onClick={this.showMoreFun}></Icon>
                                                <div className='operation' style={{ visibility: showMoreFun && (hoverCommentIndex === index) ? 'visible' : 'hidden' }}>
                                                    <div className='operBtnWrapper' onClick={() => this.editMyComment(index)}>
                                                        <Icon type=' ' className='EditBtn' />{intl.get("Edit")}
                                                    </div>
                                                    <div className='operBtnWrapper' onClick={() => this.deleteMyComment(index)}>
                                                        <Icon type=' ' className='DeleteBtn' />{intl.get("Delete")}
                                                    </div>
                                                </div>
                                            </div>
                                            : null
                                    }
                                    <Truncate
                                        className='TruncateTxt'
                                        lines={!truncated[index] && 3}
                                        ellipsis={
                                            <span >...<br></br>
                                                <span className='unfold'
                                                    onClick={() => this.changeTruncated(index)}>Unfold
                                                </span>
                                            </span>
                                        }>
                                        {
                                            <div className='commentContentTxt'>
                                                <span>{comment.content}</span>
                                                <br></br>
                                                {
                                                    truncated[index] ?
                                                        <span className='fold' onClick={() => this.changeTruncated(index)}>Collapse</span>
                                                        :
                                                        null
                                                }
                                            </div>
                                        }
                                    </Truncate>

                                    <div className='replyAndCommentDateDiv'>
                                        <span className='commentDate'>{moment(comment.time).format("dddd MMMM Do, h:mm a")}</span>
                                        <div className='replyDiv'>
                                            <span key="comment-basic-like">
                                                <Icon
                                                    style={{ color: '#9AA2AA', width: '15px', height: '15px' }}
                                                    type="like"
                                                    theme={myActionList.likes.indexOf(index) !== -1 ? 'filled' : 'outlined'}
                                                    onClick={() => this.like(index, comment.cid)}
                                                />
                                                <span className='likesNum ml_10'>{comment.sup}</span>
                                            </span>
                                            <span key=' key="comment-basic-dislike"'>
                                                <Icon
                                                    style={{ color: '#9AA2AA', width: '15px', height: '15px' }}
                                                    type="dislike"
                                                    theme={myActionList.dislikes.indexOf(index) !== -1 ? 'filled' : 'outlined'}
                                                    onClick={() => this.dislike(index, comment.cid)}
                                                />
                                                <span className='dislikesNum ml_10' >{comment.sdown}</span>
                                            </span>
                                            <span style={{ cursor: "pointer" }} onClick={() => { this.replyTo(index) }} key="comment-basic-reply-to" className='replyTo ml_10'>{intl.get("Reply to")}</span>
                                        </div>
                                    </div>
                                </div>
                            }
                        >
                            {
                                isShowPostAReply && (postAReplyIndex === index) ?
                                    <div style={{ marginTop: '20px', width: "calc(100% - 48px)" }}>
                                        <PostAcommentView
                                            // userName={userName}
                                            postACommentListener={(value) => this.replyAComment(index, value, comment.cid)}
                                            cancelCommentListener={() => { this.setState({ isShowPostAReply: false, postAReplyIndex: -1 }) }}
                                            textValue={isSubCommentListView ? `@${comment.user.username} ` : ''}
                                            {...this.props}></PostAcommentView>
                                    </div>
                                    : null
                            }
                            {
                                comment.replies && comment.replies.length >= 1 ?
                                    operateRepliesIndex === index ?
                                        <div>
                                            <div className='checkRepliesDiv'>
                                                <Icon type="caret-up" onClick={() => this.hideReplies(index)} style={{ color: '#F28B30', cursor: "pointer" }} />
                                                <span className='hideReplies' style={{ cursor: "pointer" }} onClick={() => this.hideReplies(index)}>Hide {comment.replies.length} replies</span>

                                            </div>
                                            {
                                                <CommentListView
                                                    isSmallAvatar={true}
                                                    commentsList={comment.replies}
                                                    userInfo={this.props.userInfo}
                                                    OperateRelatedId={OperateRelatedId}
                                                    isLogIn={this.props.isLogIn}
                                                    updateCommentsList={this.updateCommentsList}
                                                    isSubCommentListView={true}
                                                    intl={intl}
                                                />
                                            }
                                        </div>
                                        :
                                        <div className='checkRepliesDiv'>
                                            <Icon type="caret-down" style={{ color: '#F28B30', cursor: "pointer" }} onClick={() => this.showReplies(index)} />
                                            <span className='showReplies' style={{ cursor: "pointer" }} onClick={() => this.showReplies(index)}>View {comment.replies.length} replies</span>
                                        </div>
                                    :
                                    null
                            }
                        </Comment>
                )
            }) : isSubCommentListView ? null : <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />}
        </div >)
    }
}