import React, { Component } from "react";
import CommentsView from '@/components/ForumView/CommentsView';
import StoryInformationView from '@/components/StoryView/StoryInformationView';
import StoryView from '@/view/StoryViewForComment';
import RelatedStoriesView from './RelatedStory/RelatedStoriesView';
import RateAndShareView from './RateAndShare/RateAndShareView';

// import ShareModalView from '@/components/ShareModalView/index';
import activitiesCode from '@/axios/activitiesCode';
import InfiniteScroll from 'react-infinite-scroller';

import * as api from '@/axios/api';


import '@/pages/common.less';
import './CommentPage.less';

import _ from 'lodash';
const compareTime=new Date("2021-11-2")
export default class CommentPage extends Component {
    constructor(props) {
        super(props);
        this.state = {
            loadingComments: false,
            isLoadedCommentsAll: false,
            loadingRelatedStory: false,
            isRelatedStoriesLoadedAll: false,
            //refreshLoading: true,
            hasMore: true,
            layoutType: '',
            page: 0,
            dataset: '',
            story: null,
            comments: [],
            isRatedBefore: false,
            preSid: this.props.match.params.sid || -1,
            // nextRelatedStoryPage: 0,
        }
        this.per_page = 5
    }

    handleInfiniteOnLoad = () => {
        let nextPage = this.state.page + 1
        this.setState({
            page: nextPage
        })
        if (!this.props.userEnd.startsWith('i') && !this.state.isLoadedCommentsAll) { //加载评论
            this.setState({
                loadingComments: true
            })
            const { sid } = this.props.OperateRelatedId;
            this.queryCommentsList(sid, nextPage)
        }
    }
    queryCommentsList = (sid, page) => {
        // const { sid } = this.props.OperateRelatedId;
        let params = {
            per_page: 10,
            page: page || 0,
            sort: 'time'
        }
        api.queryCommentsByStoryId(sid, params).then((comments) => {
            this.setState({
                loadingComments: false,
            })

            if (this.state.page === 0) {
                this.setState({
                    comments: comments
                })
            } else {
                if (comments.commentTree && comments.commentTree.length !== 0) {
                    let newCommentTree = _.cloneDeep(this.state.comments.commentTree).concat(comments.commentTree)
                    this.setState({
                        comments: {
                            ...this.state.comments,
                            commentTree: newCommentTree
                        }
                    })
                } else {
                    this.setState({
                        isLoadedCommentsAll: true
                    })
                }
            }

        })
    }

    mediaQueryChanged = (mql) => {
        let isMobilephoneEnd;
        if (mql.matches) { // 媒体查询
            isMobilephoneEnd = true
            this.props.changeUserEnd(isMobilephoneEnd)
        } else {
            isMobilephoneEnd = false
            this.props.changeUserEnd(isMobilephoneEnd)
        }
    }

    componentWillMount() {
        // 创建媒体查询列表对象
        var mql = window.matchMedia(`(max-width: 675px)`);
        this.mediaQueryChanged(mql)
        // 添加监听器
        mql.addListener(this.mediaQueryChanged);
        let { sid } = this.props.match.params
        this.initStoryAndRateInfo(sid);
        this.queryCommentsList(sid);
    }

    componentWillReceiveProps() {
        let splitArray = window.location.href.split("/")
        let sidPos = splitArray.length
        let newSid = splitArray[sidPos - 1]
        if (newSid !== this.state.preSid) {
            //从related story列表里点击的更新
            this.setState({
                preSid: newSid,
                isRatedBefore: false
            })
            this.initStoryAndRateInfo(newSid)
        }
    }
    initStoryAndRateInfo = (sid) => {
        //console.log("initStoryAndRateInfo...")
        this.props.updateOperateRelatedId({ ...this.props.OperateRelatedId, sid })
        api.queryStoriesBySid(sid).then((response) => {
            let story = response.story.story
            if (!story) return
            const { facts, relations, data, schema, storyParameter, aggregationLevel, resultCoverage, fileName, real_filename,showSuggestion,subtitle,dataresLocal,schemaFilterLocal,filtersLocal,filtersTitleLocal  } = JSON.parse(story.json)
            let storyTitle = story.title
            this.props.updateCommentStoryInfo(facts, relations, data, schema, storyTitle, fileName,real_filename, storyParameter, aggregationLevel, resultCoverage, showSuggestion,subtitle,dataresLocal,schemaFilterLocal,filtersLocal,filtersTitleLocal )
            story = { ...story, title: storyTitle, facts, relations, data, schema, storyParameter, aggregationLevel, resultCoverage }

            if (this.props.isLogIn) {
                api.getStoryRate(sid).then((data) => {
                    let userRating = data.rating ? data.rating.rating : story.rating
                    let newStory = _.cloneDeep(story)
                    newStory.rating = userRating
                    this.setState({
                        story: newStory
                    })
                    // this.queryRelatedStory()
                }, () => {
                    this.setState({
                        story
                    })
                    //this.queryRelatedStory()
                })
            } else {
                this.setState({
                    story
                })
                //this.queryRelatedStory()
            }
            let did = story.did
            this.props.updateOperateRelatedId({ ...this.props.OperateRelatedId, did })
            api.queryDatasetByDid(did).then((dataset) => {
                this.setState({
                    dataset
                })
            })

        })
        //记录浏览量接口
        api.viewStory(sid).then((response) => {
        })
        //activitiesApi
        let uid = this.props.userInfo.uid
        let body = {
            action: activitiesCode.viewStory,
            objectid: parseInt(sid),
            meta: null,
        }
        api.activitiesApi(uid, body).then(response => {
        })
    }

    updateCommentsList = () => {
        this.setState({
            isLoadedCommentsAll: false
        })
        const { sid } = this.props.OperateRelatedId;
        //TODO: 从0加载到当前页面数据
        let params = {
            per_page: 100,
            page: 0,
            sort: 'time'
        }
        api.queryCommentsByStoryId(sid, params).then((comments) => {
            this.setState({
                loadingComments: false,
            })

            if (this.state.page === 0) {
                this.setState({
                    comments: comments
                })
            } else {
                if (comments.commentTree && comments.commentTree.length !== 0) {
                    this.setState({
                        comments: comments
                    })
                } else {
                    this.setState({
                        isLoadedCommentsAll: true
                    })
                }
            }

        })
    }

    viewARelatedStory = (story) => {
        api.queryDatasetByDid(story.did).then((dataset) => {
            this.setState({
                dataset,//在initStoryAndRateInfo会一起更新数据集
                //refreshLoading: true,
                page: 0
            })
            let sid = story.sid
            this.props.updateOperateRelatedId({ ...this.props.OperateRelatedId, sid })
            this.props.history.push({
                pathname: `/index/commentAStory/${sid}`,
            })
            this.queryCommentsList(sid)
        })
    }

    clickDataset = () => {
        this.props.history.push("/index/explore/datasets")
    }

    render() {

        const { getCardNumber, userInfo, isMobilephoneEnd, layoutType, intl } = this.props;
        const { story, loadingComments, dataset, comments, isLoadedCommentsAll } = this.state;
        //console.log("comment page render", layoutType)
        this.per_page = getCardNumber
        const DataInfomationView = ({ dataset, story }) => (
            < div className='rowDiv dataInfoDiv' >
                <div style={{ width: '100%' }} className='rowDiv sourceAndFileName'>
                    <div className='datasetImage'></div>
                    <div className='datafile' onClick={this.clickDataset}>{this.props.intl.get("Data file")}<span className='datafileName' style={{ marginLeft: '4px' }}>{(dataset && dataset.data) || "--"}</span></div>
                    <div className='source'>{this.props.intl.get("Source")}<span className='sourceName' style={{ marginLeft: '4px' }}>{(dataset && dataset.source) || "--"}</span></div>
                </div>
                <RateAndShareView story={story} layoutType={layoutType} intl={this.props.intl} OperateRelatedId={this.props.OperateRelatedId} updateOperateRelatedId={this.props.updateOperateRelatedId} userInfo={userInfo} />
            </div >
        )

        return <InfiniteScroll className='commentPageContent'
            initialLoad={false}
            pageStart={0}
            loadMore={this.handleInfiniteOnLoad}
            hasMore={this.state.hasMore}
            useWindow={false}
        >
            <div className='middleContent'>
                <StoryInformationView story={story} {...this.props} />
                <div className='storyLineDiv'>
                    <StoryView storyFacts={story && story.facts} storyRelations={story && story.relations} storyData={story && story.data}{...this.props}   oldStory={story&&compareTime>new Date(story.time.split("T")[0])}/>
                </div>
                <DataInfomationView dataset={dataset} story={story} />
                <div className='commentviewWrapper'>
                    <CommentsView comments={comments} loadingComments={loadingComments} updateCommentsList={() => this.updateCommentsList()}
                        // refreshCommentList={(comments) => { this.refreshCommentList(comments) }} //携带最新数据
                        {...this.props}
                    />
                    {
                        isLoadedCommentsAll ?
                            <span style={{ scolor: 'grey', textAlign: "center", display: 'block' }}> {intl.get("no more comment")}</span>
                            :
                            null
                    }
                </div>
            </div>
            <div className='relatedStoryWrapper'>
                <p className='relatedTxt'>{intl.get("Related Stories")}</p>
                {
                    <RelatedStoriesView story={story} isMobilephoneEnd={isMobilephoneEnd} userInfo={userInfo} onViewARelatedStoryListener={(story) => this.viewARelatedStory(story)}  {...this.props}  oldStory={story&&compareTime>new Date(story.time.split("T")[0])}/>
                    // refreshLoading ?
                    //     <div className="loading-container">
                    //         <Spin tip={intl.get("Loading")} />
                    //     </div>
                    //     :
                }
            </div>
        </InfiniteScroll >

    }
}