import React from 'react';
import {  Collapse, Select,Slider } from 'antd';
import "./index.less";
// import _ from 'lodash';
const { Panel } = Collapse;
const {Option} = Select;
const marks={
    1:'',
    2:'',
    3:'',
    4:'',
    5:''
}
export default class AlgorithmSettings extends React.Component{
    constructor(props) {
        super(props);
        this.state = {
            algorithm:this.props.algorithm,
            calSimilarityType:this.props.calSimilarityType,
            maximumFact:this.props.maximumFact
        }
    }
    componentDidMount() {
      
    }
    componentDidUpdate() {
        
    }
    // shouldComponentUpdate(nextProps) {
    //     let {algorithm,calSimilarityType} = this.state;
    //     return !_.isEqual(algorithm, nextProps.algorithm)||!_.isEqual(calSimilarityType,nextProps.calSimilarityType);
    // }
    // algorithm configuration
    onAlgorithmSelect=(value) =>{  
        this.props.changeAlgorithm(value,this.state.calSimilarityType)
        this.setState({
            algorithm:value
        })
    }
    onAlSimilaritySelect=(value)=>{
        this.props.changeAlgorithm(this.state.algorithm,value)
        this.setState({
            calSimilarityType:value
        })
    }
    onMaximumFactSet=(value)=>{
        this.props.changeAlgorithm(this.state.algorithm,this.state.calSimilarityType,value)
        this.setState({
            maximumFact:value
        })
    }
    render() {
        const { intl } = this.props;
        return(
        <Collapse defaultActiveKey={["algorithm-1"]}>
            <Panel header={intl.get("Algorithm")} key="algorithm-1" >

            {/*choose interpolated algorithm & ways of similarity calculation */}
            <div>
                <div style={{display: "inline-block",height:"32px"}}>
                    <p style={{lineHeight:"2.2"}}>{ intl.get("Maximum number of facts")}{this.state.maximumFact}</p>
                </div> 
                <div style={{display:"inline-block",width:intl.options.currentLocale==="en-US"?"130px":"110px",height:"32px",float:"right",marginTop:"-4px", marginRight:"0px"}}>   
                    <Slider marks={marks} defaultValue={this.state.maximumFact} min={1} max={5} onChange={this.onMaximumFactSet}></Slider>
                </div>
                {/* <div style={{display: "inline-block",height:"32px"}}>
                    <p style={{lineHeight:"2.2"}}>{intl.get("Algorithm")}</p>
                </div> */}
                {/* <div style={{display: "inline-block"}}>
                    <Select defaultValue={this.state.algorithm}  style = {{width:130,marginRight:"5px",marginLeft:"5px",textAlign:"center"}} onChange = {this.onAlgorithmSelect}>
                        <Option value = {"MCTS"}>MCTS</Option>
                        <Option value = {"QL"}>Q-Learning</Option>
                    </Select>
                    <Select defaultValue={this.state.calSimilarityType}  style = {{width:130,textAlign:"center"}} onChange = {this.onAlSimilaritySelect}>
                        <Option value = {"trans"}>Sentence Bert</Option>
                        <Option value = {"w2v"}>Word2Vec</Option>
                    </Select>
                </div> */}
            </div>

            </Panel>
        </Collapse>
        )
    }
} 