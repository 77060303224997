import React, { Component } from "react";
import { Icon, message, Input, Upload } from 'antd';
import TagsInputView from '../DatasetsTable/TagsInput/TagsInputView';

import './UploadPannelView.less';
const { Dragger } = Upload;

export default class UploadPannelView extends Component {

    constructor(props) {
        super(props)
        this.state = {
            dataSource: '',
            isShowPannel: false,
            isUploadFileCompelete: false,
            tags: [],
            uploadFileInfo: '',
            originFileOb: {}
        }
    }
    Upload = () => {
        const { intl } = this.props
        if (!this.state.isUploadFileCompelete) {
            message.info(intl.get("please upload your data"))
            return
        }
        if (this.state.tags.length < 3) { //tag不能少于三个
            message.info(intl.get("please input at least 3 tags"))
            return
        }
        if (this.state.dataSource === '') { //source不能为空
            message.info(intl.get("please input a data source"))
            return
        }
        let displayKeyWords = '';
        this.state.tags.map((tag, index) => {
            if (index === this.state.tags.length - 1) {
                displayKeyWords = displayKeyWords.concat(tag.text)
            } else {
                displayKeyWords = displayKeyWords.concat(tag.text + ';')
            }
            return tag
        })
        const { dataSource, originFileOb } = this.state;
        let newDataset =
        {
            "data": originFileOb.name.split('.csv')[0],
            "keywords": displayKeyWords,
            "source": dataSource,
            'size': originFileOb.size,
            "did": "",
        }
        this.props.onUploadDataListener(originFileOb, newDataset)
        this.setState({
            isShowPannel: false
        })
    }

    Cancel = () => {
        this.setState({
            dataSource: '',
            tags: []
        })
    }

    onUploadChange = (info) => {
        //console.log("onUploadChange...",info)
        const { status } = info.file;
        if (info.file.size <= 1048576 * 5) {
            if (status !== 'uploading') {
                //message.success(`${info.file.name} file uploaded successfully.`);
                this.setState({
                    isUploadFileCompelete: true,
                    originFileOb: info.file.originFileObj
                })
            }
        } else if (status === 'error') {
            message.error(`File size exceeds limit(5MB).`);
        }
    }

    tagsChanged = (tags) => {
        this.setState({
            tags: tags
        })
    }
    onSourceInputChange = (e) => {
        this.setState({
            dataSource: e.target.value
        })
    }
    showPannel = () => {
        this.setState({
            isShowPannel: !this.state.isShowPannel
        })
    }
    deleteFile = () => {
        this.setState({
            isUploadFileCompelete: false
        })
    }
    render() {
        const { isShowPannel, dataSource, originFileOb, isUploadFileCompelete, tags } = this.state
        const { intl } = this.props
        return (
            <div className='uploadFile'>
                <div className='uploadFileHead' onClick={this.showPannel}>
                    <span >{intl.get("Upload your data file")}</span>
                    <Icon type={isShowPannel ? "up" : 'down'} className='arrow' />
                </div>
                <div style={{ display: isShowPannel ? "inline-block" : "none" }} className='uploadPannelWrapper'>
                    <div className='uploadPannel'>
                        <div className='dragfile'>
                            <Dragger
                                onChange={this.onUploadChange}
                                showUploadList={false}
                                accept=".csv"
                            >
                                <p className="ant-upload-drag-icon">
                                    <Icon type=' ' className='fileImage' />
                                </p>
                                {
                                    isUploadFileCompelete ?
                                        <div className='uploadedFileDiv'>
                                            <Icon style={{ fontSize: "14px" }} type='link' />
                                            <span className="fileNametext">{originFileOb.name}</span>
                                            <Icon className='fileDeleteBtn' type='delete' onClick={this.deleteFile} />
                                        </div>
                                        :
                                        <p className="ant-upload-text" >{intl.get("Click or drag")}</p>
                                }
                            </Dragger>
                        </div>
                    </div>
                    <div style={{ marginTop: '28px', width: '100%', height: '120px', display: isShowPannel ? "inline-block" : "none" }}>
                        <TagsInputView
                            intl={intl}
                            tags={tags}
                            onTagsChangedListener={(tags) => this.tagsChanged(tags)} />
                    </div>
                    <div className='sourcePannel'>
                        <span>{intl.get("Source")}</span>
                        <Input
                            className='datasourceInput'
                            placeholder={intl.get("input a data source")}
                            value={dataSource}
                            onChange={(e) => this.onSourceInputChange(e)}
                        ></Input>
                    </div>
                </div>
                <div style={{ display: isShowPannel ? "flex" : "none" }} className='datasourceUploadWrapper'>
                    <div onClick={this.Cancel}>{intl.get("Cancel")}</div>
                    <div onClick={this.Upload}>{intl.get("Upload")}</div>
                </div>

            </div >)
    }
}