import { connect } from 'react-redux';
import { data, schema, selectedFactIndex, selectedFact, fileName, method ,dataresLocal, filtersLocal, filtersTitleLocal, schemaFilterLocal,recommendedSfacts,recommendedEfacts,recommendedMfacts,facts,calSimilarityType} from '@/selector/story';
import FactEditor from './FactEditor';
import * as factAction from '../../action/factAction';
import { editstate } from '../../selector/story';

const mapStateToProps = (state) => ({
    data: data(state),
    schema: schema(state),
    selectedFactIndex: selectedFactIndex(state),
    selectedFact: selectedFact(state),
    fileName: fileName(state),
    method: method(state),
    filtersLocal:filtersLocal(state),
    filtersTitleLocal:filtersTitleLocal(state),
    dataresLocal:dataresLocal(state),
    schemaFilterLocal:schemaFilterLocal(state), 
    recommendedSfacts:recommendedSfacts(state),
    recommendedEfacts:recommendedEfacts(state),
    recommendedMfacts:recommendedMfacts(state),
    facts: facts(state),
    calSimilarityType:calSimilarityType(state),
    editstate:editstate(state),

})

const mapDispatchToProps = dispatch => {
    return {
        updateFact: (index, fact) => dispatch(factAction.updateFact(index, fact)),
        addSFact:(facts) => dispatch(factAction.addSFact(facts)),
        addMFact:(facts) => dispatch(factAction.addMFact(facts)),
        addEFact:(facts) => dispatch(factAction.addEFact(facts)),
        deleteSFact:(index) => dispatch(factAction.deleteSFact(index)),
        deleteMFact:(index) => dispatch(factAction.deleteMFact(index)),
        deleteEFact:(index) => dispatch(factAction.deleteEFact(index)),
        insertFact: (index, insert) => dispatch(factAction.insertFact(index, insert)),
        inserRecommendedFact: (index, insert,factLocation) => dispatch(factAction.inserRecommendedFact(index, insert,factLocation)),
        changeCurrentEditing:(editstate,editingCurrentFact)=>dispatch(factAction.changeCurrentEditing(editstate,editingCurrentFact)),

    }
}

export default connect(mapStateToProps, mapDispatchToProps, null, { forwardRef: true })(FactEditor);