/* eslint no-eval: 0 */
import React from 'react';
import { Spin,message } from 'antd';
import uuidv4 from 'uuid/v4';
import * as api from '@/axios/api';
import _ from 'lodash';
// import activitiesCode from '@/axios/activitiesCode';
// import record_activity from '@/axios/recordactivities';
import FactCard from './FactCard';
import config from '@/axios/config'
import OperationType from '@/constant/OperationType'
import Fact from '@/model/fact';
import { genFactSentence, genSubtitle } from '../../../sentencer/index';

import {Collapse} from 'antd';
import "../../../view/FactEditorNew/Fact/rightFactChartEditor.less"
const { Panel } = Collapse;

const addFactNum=10

export default class RfactsSettings extends React.Component{
    constructor(props) {
        super(props);
        this.state = {
        }
    }
    componentDidMount() {
        
        // let factIndex=this.props.editingFact.index;
        // if(factIndex===0 && this.props.recommendedSfacts.length===0){
        //     this.addFacthere('first',factIndex) 
        // }
        // if(factIndex===this.props.facts.length-1 && this.props.recommendedEfacts.length===0){
        //     this.addFacthere('last',factIndex) 
        // }
        // if(factIndex>0&&factIndex<this.props.facts.length-1){
        //     if(!this.props.facts[factIndex-1].chart===""&&!this.props.facts[factIndex+1].chart===""){
        //         this.props.recommendedMfacts.map((d,i)=>{
        //             this.props.deleteMFact(0)
        //             return null
        //         })    
        //         this.addFacthere('middle',factIndex)    
        //     }
        // }

    }
    shouldComponentUpdate(nextprops){
        let factIndexPre= this.props.editingFact.index
        let factIndexAfter= nextprops.editingFact.index
        return (!_.isEqual(factIndexAfter, factIndexPre)||(!_.isEqual(this.props.recommendedEfacts,nextprops.recommendedEfacts))||(!_.isEqual(this.props.recommendedSfacts,nextprops.recommendedSfacts))||(!_.isEqual(this.props.recommendedMfacts,nextprops.recommendedMfacts)))
        // let factIndexPre= this.props.editingFact.index
        // let factIndexAfter= nextprops.editingFact.index
        // return !_.isEqual(factIndexAfter, factIndexPre)||!_.isEqual(this.props.recommendedEfacts,nextprops.recommendedEfacts)||!_.isEqual(this.props.recommendedSfacts,nextprops.recommendedSfacts)||!_.isEqual(this.props.recommendedMfacts,nextprops.recommendedMfacts);
    }
    // componentDidUpdate(){
    //     let factIndex=this.props.editingFact.index;
    //     if(factIndex===0 && this.props.recommendedSfacts.length===0){
    //         this.addFacthere('first',factIndex) 
    //     }
    //     if(factIndex===this.props.facts.length-1 && this.props.recommendedEfacts.length===0){
    //         this.addFacthere('last',factIndex) 
    //     }
    //     if(factIndex>0&&factIndex<this.props.facts.length-1){
    //         if(this.props.facts[factIndex-1].chart!==""&&this.props.facts[factIndex+1].chart!==""){
    //             this.props.recommendedMfacts.map((d,i)=>{
    //                 this.props.deleteMFact(0)
    //                 return null
    //             })    
    //             this.addFacthere('middle',factIndex)    
    //         }
    //     }
    // }
    addFacthere = (fact_location,factIndex) => {

        this.setState({
            operateState: OperationType.ADDING
        })
        let startFact={}
        let endFact={}
        let startEvent={}
        let endEvent={}
        if(fact_location==="middle"){
            startEvent = this.props.facts[factIndex-1]
            endEvent = this.props.facts[factIndex+1]
            if(startEvent.type!=="") 
            startFact={
                "type":startEvent.type,
                "measure":startEvent.measure,
                "groupby":startEvent.groupby,
                "subspace":startEvent.subspace,
                "focus":startEvent.focus
            } 
            if(endEvent.type!=="") 
                endFact={
                    "type":endEvent.type,
                    "measure":endEvent.measure,
                    "groupby":endEvent.groupby,
                    "subspace":endEvent.subspace,
                    "focus":endEvent.focus
                } 
        }
        
        //TODO:会有重复的fact
        this.adding(config.url.interpolateMCTS,fact_location,startFact,endFact).then(response => {
            let tempdata
            if(response.data.story) tempdata=response.data
            else tempdata=eval('('+response.data+')')
            let facts = tempdata.story.facts;
            if(fact_location==="middle") facts=facts.facts
            if(facts===null) facts=[]
            facts=facts.slice(0,addFactNum)
            
            let tempFacts = [];
            for (let factDict of facts) {
                let fact = new Fact(
                    factDict['type'],
                    factDict['measure'],
                    factDict['subspace'],
                    factDict['groupby'],
                    factDict['focus'],
                    factDict['parameter'],
                    "", // chart
                    factDict['score'],
                    factDict['information'],
                    factDict['significance']
                );
                fact.uuid = uuidv4();
                tempFacts.push(fact);
            }
            if(fact_location==='first')
            this.props.addSFact(tempFacts);
            if(fact_location==='middle')
            this.props.addMFact(tempFacts);
            if(fact_location==='last')
            this.props.addEFact(tempFacts);
        });
    }

    adding = (url,fact_location,startFact,endFact) => {
        return new Promise(async (resolve, reject) => {
            let fileName = this.props.fileName;
            let filters = _.cloneDeep(this.props.filtersLocal);
            
            let data;
            if(fact_location==='middle'){
                data= {
                    file_name: fileName,
                    max_story_length: addFactNum,
                    fact_location:fact_location,
                    start_fact:startFact,
                    end_fact:endFact,
                    similarity:this.props.calSimilarityType,
                    filters: JSON.stringify(filters)
                    // method: 'mcts'
                }
            }
            else{
                data= {
                    file_name: fileName,
                    max_story_length: addFactNum,
                    fact_location:fact_location,
                    filters: JSON.stringify(filters),
                    // method: 'mcts'
                }
            }
           
            const response = await api.interpolate(url, data)
            if (response.data.fail) {
                message.info(this.props.intl.get("cannot interpolate."))
                this.setState({
                    operateState: OperationType.ADD_FAILED,
                    errorMessage: response.data.fail
                })
                reject()
            // record_activity(activitiesCode.generateMoreFacts, 0 , {filename:fileName, response: response});
            }
            if (response.data.error) {
                message.info(this.props.intl.get("cannot interpolate."))
                this.setState({
                    operateState: OperationType.ADD_FAILED,
                    errorMessage: response.data.error
                })
                reject()
                // record_activity(activitiesCode.generateMoreFacts, 0 , {filename:fileName, response: response});
            }
            this.setState({
                operateState: OperationType.GENERATED
            })
            resolve(response)
            // record_activity(activitiesCode.generateMoreFacts, 1 , {filename:fileName, response: response});

        })
    }



    render() {
        const { intl, initDone} = this.props;
        let tip = initDone && intl.get("adding");
        let factIndex=this.props.editingFact.index;
        let {recommendedEfacts,recommendedSfacts,recommendedMfacts,facts } = this.props;
        let recommendedFacts=[];
        let fact_location='';
        if(factIndex===0) {
            recommendedFacts=recommendedSfacts
            fact_location='first'
        }else if(factIndex===facts.length-1){
            recommendedFacts=recommendedEfacts
            fact_location='last'
        }else{
            recommendedFacts=recommendedMfacts
            fact_location='middle'
        }
        if(recommendedFacts[0]!=="none")
            recommendedFacts.map((item, index) => {
                item.generatedScript = genFactSentence(item, intl.options.currentLocale)
                item.generatedSubtitle = genSubtitle(item, intl.options.currentLocale)
                return null
            })

        const AddView = () => {
            return (
                <div className='add' style={{display:recommendedFacts.length===0||recommendedFacts[0]==="none"?"flex":"none"}}>
                    <div className="addImage" onClick={()=>this.addFacthere(fact_location,factIndex)}></div>
                    {/* <div className="addImage" onClick={this.addFact}></div> */}
                </div>
            )
        }

        return(
        <Collapse defaultActiveKey={['FactChartEditor-4']}>
            <Panel header={initDone && intl.get("Data Insight Suggestions")} key="FactChartEditor-4" className="">
                <div className="DataFacts">
                    <Spin tip={tip} spinning={(recommendedFacts[0]!=="none"&&recommendedFacts.length===0)||this.state.operateState === OperationType.ADDING} wrapperClassName="editSpining">
                        <div className='factsList'>
                            <ul>
                                {
                                    recommendedFacts[0]!=="none"?recommendedFacts.map((fact, index) => {
                                        return <FactCard key={fact.uuid + fact.index} dataresLocal = {this.props.dataresLocal} schemaFilterLocal = {this.props.schemaFilterLocal}  {...this.props} fact={fact} index={index} id={fact.uuid} isReady={true} factLocation={fact_location} factIndex={this.props.editingFact.index}/>
                                        
                                    }):null
                                }
                            </ul>
                        </div>
                    </Spin>
                </div>
                <div>
                    <AddView />
                </div>
            </Panel>
        </Collapse>

        )
    }
} 