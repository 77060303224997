import React from 'react';
import { Rate } from 'antd';
import EventCard from '@/components/CardListView/EventCardView/EventCard';
// import _ from 'lodash';

let playTimer = 0;
let moment = require('moment');
const compareTime=new Date("2021-11-2")
export default class CardItemView extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            hoverId: '',
            currentPlayEvent: null,
            isHovered: false,
            story: this.props.story
        }
    }
    componentDidMount() {
        if (this.visNode) {
            let vis = this.visNode.children[0].children[0];
            if (vis)
                vis.style.transform = "translate(-70px,-28px)scale(0.43)";
        }
    }
    componentDidUpdate() {
        if (this.visNode) {
            let vis = this.visNode.children[0].children[0];
            if (vis)
                vis.style.transform = "translate(-70px,-28px)scale(0.43)";
        }
    }

    hoverCard = () => {
        const { isHovered } = this.state
        if (isHovered) return
        const { story } = this.props
        let factsLen = story.events.length
        let playIndex = 0;
        //立即执行
        this.setState({
            currentPlayEvent: story.events[playIndex],
            isHovered: true
        })
        playIndex++;
        //每秒播放一张
        playTimer = setInterval(() => {
            if (playIndex >= factsLen) {
                playIndex = 0;
            }
            this.setState({
                currentPlayEvent: story.events[playIndex]
            })
            playIndex++;
        }, 1200)
    }

    moveOut = () => {
        this.setState({
            isHovered: false
        })
        clearInterval(playTimer)
    }

    click = () => {
        const { story } = this.state
        const { facts, relations, data, schema, storyParameter, aggregationLevel, resultCoverage, fileName, real_filename,showSuggestion,subtitle,dataresLocal,schemaFilterLocal,filtersLocal,filtersTitleLocal  } = story
        this.props.updateCommentStoryInfo(facts, relations, data, schema, story.title, fileName, real_filename,storyParameter, aggregationLevel, resultCoverage, showSuggestion,subtitle,dataresLocal,schemaFilterLocal,filtersLocal,filtersTitleLocal )
        this.props.updateOperateRelatedId({ ...this.props.OperateRelatedId, sid: story.sid })
        this.props.onViewARelatedStoryListener(story)
        clearInterval(playTimer)
    }

    render() {

        const StoryInfo = ({ story }) => (
            <div className='rightInfo'>
                <span> {story.user && story.user.username}</span>
                <span>{typeof story.num_of_rates !== 'undefined' ? story.num_of_rates : '--'} {this.props.intl.get("story.card.rates")},{typeof story.num_of_shares !== 'undefined' ? story.num_of_shares : '--'} {this.props.intl.get("story.card.shares")}</span>
                <span>{moment(story.time).format('MMM.Mo,YYYY')}</span>
                {
                    (story && story.rating) ?
                        <Rate disabled
                            //defaultValue={parseInt(story.rating)}
                            defaultValue={(story && parseInt(story.rating.toFixed(1).split('.')[1]) > 5) ?
                                parseInt(`${story.rating.toFixed(1).split('.')[0]}`) + 1
                                : parseInt(story && story.rating.toFixed(1).split('.')[1]) === 0 ? parseInt(story && story.rating.toFixed(1)) : parseFloat(`${story && story.rating.toFixed(1).split('.')[0]}.5`)}
                            style={{ fontSize: '8px', color: '#F28B30', marginTop: '4px' }} allowHalf />
                        :
                        <Rate disabled defaultValue={0} style={{ fontSize: '8px', color: '#F28B30', marginTop: '4px' }} />

                }
            </div>
        )
        const { story, isHovered, currentPlayEvent } = this.state
        //console.log("sigle view", story.title)
        return (
            <div
                className='relatedStoryDiv'
                onMouseOver={() => this.hoverCard()}
                onMouseLeave={() => this.moveOut()}
                onClick={() => this.click()}>
                <div className='storyName'>
                    {story.title}
                </div>
                <div className='line'></div>
                <div className='storyInfo'>
                    <div className='relatedChart' ref={(node) => this.visNode = node}>
                        <EventCard
                            width={100}
                            height={90}
                            event={story.events && story.events[story.MaxSignificanceId || 0]}
                            isHovered={isHovered}
                            currentPlayEvent={currentPlayEvent}
                            key={story.events && story.events[story.MaxSignificanceId || 0] && story.events[story.MaxSignificanceId || 0].id}
                            index={story.events && story.events[story.MaxSignificanceId || 0] && story.events[story.MaxSignificanceId || 0].index}
                            storyData={story.data}
                            schema={story.schema}
                            chartSize={"small"}
                            showSuggestion={story.showSuggestion}
                            {...this.props} 
                            oldStory={compareTime>new Date(story.time.split("T")[0])}/>

                    </div>
                    <StoryInfo story={story} />
                </div>
            </div>
        )
    }
}
