import React, { Component } from "react";
import { Input, Icon } from 'antd';
import './NoBoderRadiusSearchView.less'

export default class NoBoderRadiusSearchView extends Component {
    constructor(props) {
        super(props);
        this.state = {
            value: this.props.defaultValue
        }
        this.searchValue = this.props.defaultValue
    }

    componentWillReceiveProps(newProps) {
        //console.log("componentWillReceiveProps", newProps.defaultValue, this.state.value)
        if (newProps.defaultValue !== this.state.value) {
            this.setState({
                value: newProps.defaultValue
            })
            this.searchValue = newProps.defaultValue
        }
    }
    onChange = (e) => {
        this.setState({
            value: e.target.value
        })
        this.searchValue = e.target.value
        if (e.target.value === '') {
            this.props.onResetSearchListener()
        }
    }
    onPressEnter = (e) => {
        this.searchValue = e.target.value;
        this.search();
    }
    search = () => {
        //console.log("search", this.searchValue)
        this.props.search(this.searchValue)
    }

    render() {
        const { value } = this.state
        const { intl } = this.props
        return (
            <div className='searchView'>
                <Input
                    allowClear={true}
                    onChange={(e) => this.onChange(e)}
                    onPressEnter={(e) => this.onPressEnter(e)}
                    value={value}
                    // allowClear
                    placeholder={intl.get("input search text")}
                    suffix={
                        <Icon type=" " onClick={() => this.search()} className='searchImage' />
                    }
                />
            </div >
        )
    }
}