const templates = [
    {
        'id': 0,
        'template': '{{Sentence A}} The reason is {{Sentence B}}',
    },
    {
        'id': 1,
        'template': '{{Sentence A}} This is because {{Sentence B}}',
    },
    {
        'id': 2,
        'template': '{{Sentence A}} It is due to the fact that {{Sentence B}}',
    },
]

export default templates;