export default {
    logInMenue: [
        { "Explore": ["stories", "datasets"] },
        { "Create": ["story"] },
        { "Learn more": ["tutorial", "about calliope","user manual"] },
        { "Contact": ["contact us"] },
        { "Legal": ["term of use"] }
    ],
    logoutMenue: [
        { "Explore": ["stories", "datasets"] },
        // { "Create": ["story"] },
        { "Learn more": ["tutorial", "about calliope","user manual"] },
        { "Contact": ["contact us"] },
        { "Legal": ["term of use"] }
    ],
    mockUserInfo: {
        accountid: "5a3e5396-05aa-41a6-99c7-1f9f52f08720",
        avatar: "5a3e5396-05aa-41a6-99c7-1f9f52f08720.JPG",
        token: "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJleHAiOjE1ODkzNDc5NjEsInVzZXJfbmFtZSI6IjE0OTE4MTI2ODNAcXEuY29tIiwianRpIjoiMzJjMWVkZGItOTc3ZS00YTYxLTkxN2UtODc4YzcwNmFhMzMzIiwiY2xpZW50X2lkIjoiYjE4YTZhYTktODlkZi00NTE2LWJiNTAtYTRlZjFlMjA3MzNmIiwic2NvcGUiOlsiYWxsIl19.pBGV2hdYfVMZvEjpLM18GoVG2mOe325ZxUmzcLoraqo",
        uid: 1,
        username: "xi li"
    }
}