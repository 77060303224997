import React, { Component } from 'react';
import { Row, Col, Select, Button, Radio } from 'antd';
// import { CloseOutlined } from '@ant-design/icons';
import AggregationType from '../../../constant/AggregationType';
import getSupportedChartTypes from '@/tool/getSupportedChartTypes';
import getSuitableBreakdownList from '@/tool/getSuitableBreakdownList';
const { Option } = Select;

export default class Outlier extends Component {
    render() {
        let { handleChartChange, getFieldValue, isDisabled, handleMeasureChange, handleAGGChange, handleFilterChange, onRadioChange, removeFilter, handleSubOk, handleSubCancel, handleGbChange, showModal, intl, initDone } = this.props;
        let fact = this.props.editingFact;
        // let fact=this.props.currentFact
        // let schema = this.props.schema;
        let schema=this.props.schemaFilterLocal;
        let measureList = schema.filter(key => key['type'] === "numerical")
        measureList.push({ field: "COUNT", type: "numerical" });

        const aggregationType = [],
            subspaceList = schema.filter(key => key['type'] !== "numerical"),//只能categorical, temporal
            groupbyList = getSuitableBreakdownList(fact.type, fact.chart, schema),
            subValueList = getFieldValue(this.props.dataresLocal, this.props.filterField),
            supportedChartTypes = getSupportedChartTypes(fact, schema, true);

        // let modalPosition;
        // if (document.getElementById('add-subspace')) {
        //     modalPosition = document.getElementById('add-subspace').getBoundingClientRect()
        // }

        let measure = []
        if (!fact.measure.length) {
            measure = [{}]
        } else {
            measure = fact.measure.map(key => {
                return {
                    "field": key.aggregate === "count" ? "COUNT" : key.field,
                    "aggregate": key.aggregate
                }
            })
        }

        for (let key in AggregationType) {
            if (key !== 'COUNT')
                aggregationType.push(AggregationType[key])
        }

        return (
            <div>
                <Row key={'chart'} className="shelf">
                    <Col span={8} className="channelName">{initDone && intl.get("visualization")}</Col>
                    <Col span={16}>
                        <Select className="select-box" id="select-chart" defaultValue={fact.chart} value={fact.chart} onChange={handleChartChange}>
                            {supportedChartTypes.map((key) => <Option key={key.chart} value={key.chart}>{key.chart}</Option>)}
                        </Select>
                    </Col>
                </Row>

                {measure.map((key, i) => <Row className={i === 0 ? 'shelf' : ''} key={'measure' + i}>
                    <Col span={8} className={i === 0 ? 'channelName' : ''}>{i === 0 ? initDone && intl.get("measure") : ''}</Col>
                    <Col span={16}>
                        <Row>
                            <Col span={14}>
                                <Select className="select-box" id={"select-measure" + i} defaultValue={key.field} value={key.field} onChange={(value) => handleMeasureChange(value, i)}>
                                    {measureList.map((key) =>
                                        <Option key={key.field} value={key.field} disabled={isDisabled(measure, 'field', key.field)}>{key.field}</Option>
                                    )}
                                </Select>
                            </Col>
                            <Col span={10}>
                                <Select className="select-box" id={"select-agg" + i}
                                    disabled={key.field === "COUNT" ? true : false}
                                    defaultValue={key.aggregate}
                                    value={key.aggregate === "count" ? '' : key.aggregate}

                                    onChange={(value) => handleAGGChange(value, i)}
                                >
                                    {aggregationType.map((key) => <Option key={key} value={key}>{key}</Option>)}
                                </Select>
                            </Col>
                        </Row>
                    </Col>
                </Row>)}

                {fact.subspace.map((key, i) => <Row className={i === 0 ? 'shelf' : ''} key={key.field}>
                    <Col span={8} className={i === 0 ? 'channelName' : ''}>{i === 0 ? initDone && intl.get("subspace") : ''}</Col>
                    <Col span={16}>
                        <Row>
                            <Col span={2}></Col>
                            <Col span={18} title={`${key.field} = ${key.value}`} style={{ overflow: 'hidden', whiteSpace: 'nowrap', textOverflow: 'ellipsis' }}>{`${key.field} = ${key.value}`}</Col>
                            <Col span={4} className="channelSlot">
                            <Button shape="circle" type="link" size="small" style={{ fontSize: '12px', position: 'relative', left: 2 }} icon="close" onClick={() => removeFilter(key)} />
                            </Col>
                        </Row>
                    </Col>
                </Row>)}
                <div className="subspaceFrame" style={{ border: '1px solid #c0c0c0', borderRadius: '2px' }}>
                    <Row className={fact.subspace.length === 0 ? 'shelf' : ''}>
                        <Col span={8} className={fact.subspace.length === 0 ? 'channelName' : ''}>{fact.subspace.length === 0 ? initDone && intl.get("subspace") : ''}</Col>
                        <Col span={16} style={{ border: '1px solid #dfdfdf', borderRadius: '2px', textAlign: 'center' }}>
                            <Button id='add-subspace' ref='addSubBtn' onClick={showModal}>
                                +
                        </Button>
                        </Col>
                    </Row>
                    {this.props.subVisible ? (<div><Row className="shelf">
                        <Col span={8} className="channelName">{initDone && intl.get("field")}</Col>
                        <Col span={16}>
                            <Select className="select-box" id="select-field" defaultValue='please select' value={this.props.subSelectValue} onChange={handleFilterChange}>
                                {subspaceList.map((key) => <Option key={key.field} value={key.field} disabled={isDisabled(fact.subspace, 'field', key.field)}>{key.field}</Option>)}
                            </Select>
                        </Col>
                    </Row>
                        <Row style={{ display: this.props.filterField ? 'block' : 'none' }}>
                            <Col span={8} className="channelName" style={{width:'100%',  maxWidth: '100%', background: '#e2e2e2', color: 'black' }}>{initDone && intl.get("values")}</Col>
                            <Col span={1}></Col>
                            <Col span={15} style={{width:'100%',  overflow: 'scroll', maxWidth: '100%' }}>
                                <Radio.Group name="radiogroup" style={{ marginLeft: '5px' }} buttonStyle="solid" onChange={onRadioChange}>
                                    {subValueList.map((key) => <Radio.Button key={key} style={{ marginRight: '3px', borderRadius: '5px' }} value={key}>{key}</Radio.Button>)}
                                </Radio.Group>
                            </Col>
                            {/* {subValueType === "temporal" ?    <Col span={15} style={{ overflow: 'scroll', padding:'0 15px',maxWidth:'100%'}}><TemporalFilter subValueListTemp={this.props.subValueList} subValueType={subValueType} /> </Col>:   <Col span={15} style={{ overflow: 'scroll', maxWidth: '100%'}}> <CategoricalFilter subValueListTemp={subValueList} subValueType={subValueType} /></Col>} */}

                            <Col span={12} style={{width:'100%',  paddingLeft: "150px", maxWidth: "100%" }}>
                                <Button style={{ fontSize: "12px", border: "0px solid", borderRadius: "4px", background: "#dcdcdc", marginRight: '4px', height: '25px' }} onClick={handleSubCancel}>{initDone && intl.get("Cancel")}</Button>
                                <Button style={{ fontSize: "12px", border: "0px solid", borderRadius: "4px", background: "#FCBE31", height: '25px' }} onClick={handleSubOk}>{initDone && intl.get("OK")}</Button>
                            </Col>
                        </Row></div>) : null}
                </div>

                <Row key={'groupby'} className="shelf">
                    <Col span={8} className="channelName">{initDone && intl.get("breakdown")}</Col>
                    <Col span={16}>
                        <Select className="select-box" id="select-groupby" defaultValue={fact.groupby[0]} value={fact.groupby[0]} onChange={handleGbChange}>
                            {groupbyList.map((key) => <Option key={key.field} value={key.field}>{key.field}</Option>)}
                        </Select>
                    </Col>
                </Row>

                {fact.focus.map((key, i) => <Row className={i === 0 ? 'shelf' : ''} key={i}>
                    <Col span={8} className={i === 0 ? 'channelName' : ''}>{i === 0 ? initDone && intl.get("focus") : ''}</Col>
                    <Col span={16}>
                        <Row>
                            <Col span={2}></Col>
                            <Col span={18} style={{ overflow: 'hidden', whiteSpace: 'nowrap', textOverflow: 'ellipsis' }}>{key.value}</Col>
                            <Col span={4} className="channelSlot" style={{ height: 24 }}>
                                {/* <Button shape="circle" type="link" onClick={() => removeFilter(key)}><CloseOutlined/></Button> */}
                            </Col>
                        </Row>
                    </Col>
                </Row>)}

                <Row className={fact.focus.length === 0 ? 'shelf' : ''} style={{ display: fact.focus.length === 0 ? 'flex' : 'none' }}>
                    <Col span={8} className={fact.focus.length === 0 ? 'channelName' : ''}>{fact.focus.length === 0 ? initDone && intl.get("focus") : ''}</Col>
                    <Col span={16}>
                        <span>No outlier</span>
                        {/* <Button className="add-btn" id='add-focus' style={{border:null}}>No outlier</Button> */}
                    </Col>
                </Row>
            </div>
        )
    }
}
