import React, { Component } from 'react';
import MobileView from '../StoryViewForComment/MobileView';
import StorylineWebView from '../StoryViewForComment/StorylineWebView';
import FactSheetView from '../StoryViewForComment/FactSheetView';
import mediaQueryConfig from '@/constant/mediaQueryConfig';
import getUserEndSize from '@/tool/getUserEndSize';
import LayoutType from '@/constant/LayoutType';
import * as api from '@/axios/api';


export default class PublishView extends Component {
    constructor(props) {
        super(props);
        this.state = {
            usertitle: "",
            mobilePreTitle: 'The story of',
            userEnd: ''
        };
    }
    componentWillMount() {
        mediaQueryConfig.map((media) => {
            let mql = window.matchMedia(media)
            this.mediaQueryChanged(mql)
            mql.addListener(this.mediaQueryChanged);
            return media
        })
    }
    mediaQueryChanged = (mql) => {
        if (mql.matches) {
            this.props.updateUserEnd(mql.media)
            let userEnd = this.props.userEnd
            if (!userEnd) {
                userEnd = getUserEndSize(mql.media)
            }
        }
    }

    componentDidMount = async () => {
        this.props.generateStory([], [], '')
        let redirectUrl = window.location.href
        let startPos = redirectUrl && redirectUrl.lastIndexOf("/") + 1
        let endPos = redirectUrl.length
        let uuid = redirectUrl.substring(startPos, endPos)

        api.getPublic(uuid).then(async (response) => {
            const { title, fileName,real_filename, data, schema, facts, relations, storyParameter, resultCoverage, aggregationLevel, showSuggestion,subtitle,dataresLocal,schemaFilterLocal,filtersLocal,filtersTitleLocal  } = JSON.parse(response.sharedstory.json)
            this.props.updateCommentStoryInfo(facts, relations, data, schema, title, fileName,real_filename, storyParameter, aggregationLevel, resultCoverage, showSuggestion,subtitle,dataresLocal,schemaFilterLocal,filtersLocal,filtersTitleLocal )
            this.props.setAggregationLevel(aggregationLevel);
            this.props.generateStory(facts, [], resultCoverage)
            this.setState({
                usertitle: title
            });
            if (title) {
                document.title = `Calliope: ${this.state.mobilePreTitle} ${title}`

            }
        })
    }
    componentWillReceiveProps(newProps) {
        const { layoutType } = this.props
        if (newProps.userEnd !== this.state.userEnd) {
            this.setState({
                userEnd: newProps.userEnd
            })
            this.updateVisSize(newProps.userEnd, layoutType)
        }
    }
    updateVisSize = (userEnd, layoutType) => {
        let width;
        let height;
        if (!layoutType) {
            layoutType = this.props.layoutType
        }

        switch (userEnd) {
            case 'browser-max-pro':
                width = '750'
                if (layoutType === LayoutType.SLIDER_MOBILE) {
                    // height = '550' 
                    width = 0
                } else if (layoutType === LayoutType.STORYLINE_WEB) {
                    height = '349'
                } else if (layoutType === LayoutType.FACTSHEET) {
                    height = window.screen.height * 0.7
                }
                break;
            case 'browser-max':
                width = '750'
                if (layoutType === LayoutType.SLIDER_MOBILE) {
                    width = 0
                    // height = '550'
                } else if (layoutType === LayoutType.STORYLINE_WEB) {
                    height = '349'
                } else if (layoutType === LayoutType.FACTSHEET) {
                    height = window.screen.height * 0.7
                }
                break;
            case 'browser-min':
                width = '750'
                if (layoutType === LayoutType.FACTSHEET) {
                    height = window.screen.height * 0.7
                } else if (layoutType === LayoutType.STORYLINE_WEB) {
                    height = '349'
                } else if (layoutType === LayoutType.SLIDER_MOBILE) {
                    width = 0
                    //height = '349'
                }
                else {
                    height = '499'
                }
                break;
            case 'ipad':
                width = '599'
                if (layoutType === LayoutType.FACTSHEET) {
                    height = window.screen.height * 0.7
                } else if (layoutType === LayoutType.STORYLINE_WEB) {
                    //height = '255'
                    height = '349'
                } else if (layoutType === LayoutType.SLIDER_MOBILE) {
                    width = 0
                    // height = '349'
                }

                else {
                    height = '456'
                }
                break;
            case 'ipad-min':
                width = '574'
                if (layoutType === LayoutType.FACTSHEET) {
                    height = '626'
                } else if (layoutType === LayoutType.STORYLINE_WEB) {
                    //height = '245'
                    height = '349'
                } else if (layoutType === LayoutType.SLIDER_MOBILE) {
                    width = 0
                    // height = '349'
                }

                else {
                    height = '456'
                }
                break;
            case 'iphone-pro':
                width = '352'
                if (layoutType === LayoutType.FACTSHEET) {
                    height = '548'
                } else if (layoutType === LayoutType.SLIDER_MOBILE) {
                    width = 0
                    // height = '349'
                }
                else {
                    height = '548'
                }
                break;
            case 'iphone':
                width = '352'
                if (layoutType === LayoutType.FACTSHEET) {
                    height = '548'
                } else if (layoutType === LayoutType.SLIDER_MOBILE) {
                    width = 0
                    // height = '349'
                }
                else {
                    height = '478'
                }
                break;
            default:
                break;
        }
        this.props.updateVisContainerSize({
            width,
            height
        })
    }
    render() {
        let { layout, facts, relations, data, fileName, real_filename,subtitle,dataresLocal,schemaFilterLocal,filtersLocal,filtersTitleLocal} = this.props;
        const { usertitle, mobilePreTitle } = this.state;
        facts = facts.map((x, index) => {
            x.index = index;
            return x
        })
        let aggfacts = facts.filter(e => {
            if (!e.aggregated) {
                return true
            } else {
                return e.aggregated && e.aggregatedFact
            }
        })
        let storyview;
        switch (layout) {
            case LayoutType.STORYLINE_WEB:
                storyview = <StorylineWebView forPublish={true}
                    // {...this.props}
                    visContainerSize={this.props.visContainerSize}
                    relations={relations}
                    data={data}
                    wholeFacts={this.props.facts} facts={aggfacts} usertitle={usertitle} dataresLocal={dataresLocal} schemaFilterLocal={schemaFilterLocal} filtersLocal={filtersLocal} filtersTitleLocal={filtersTitleLocal} />
                break;
            case LayoutType.SLIDER_MOBILE:
                storyview = <MobileView {...this.props} forPublish={true} isNoHeight={true} usertitle={usertitle} mobilePreTitle={mobilePreTitle} dataresLocal={dataresLocal} schemaFilterLocal={schemaFilterLocal} filtersLocal={filtersLocal} filtersTitleLocal={filtersTitleLocal}  />
                break;
            case LayoutType.FACTSHEET:
                storyview =
                    <FactSheetView
                        fileName={fileName}
                        visContainerSize={this.props.visContainerSize}
                        relations={relations}
                        data={data}
                        facts={facts}
                        forPublish={true}//******important********forPublish={false} 控制不显示工具栏 并且控制页面展开显示 
                        usertitle={usertitle} subtitle={subtitle} real_filename={real_filename} dataresLocal={dataresLocal} schemaFilterLocal={schemaFilterLocal} filtersLocal={filtersLocal} filtersTitleLocal={filtersTitleLocal} />
                break;

            default:
                break;
        }
        return (
            <div style={{ height: '100%', display: 'flex', flexDirection: 'column', justifyContent: 'center' }}>
                {storyview}
            </div>
        )
    }
}
