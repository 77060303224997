import ActionType from '@/action/type';

const initialState = {
    containerSize: {
        // width: "750",
        // height: "550"
    }
};

export default (state = initialState, action) => {
    const newState = Object.assign({}, state);
    switch (action.type) {
        case ActionType.UPDATE_VIS_CONTAINER_SIZE:
            //console.log("UPDATE_VIS_CONTAINER_SIZE",action.containerSize)
            newState.containerSize = action.containerSize;
            return newState;
        default:
            break;
    }
    return newState;
}      