const templates = [
    {
        'id': 0,
        'template': '当{{subspace}}时， {{focus}} 的 {{agg}} {{measure}} 占全部 {{agg}} {{measure}} 的 {{parameter}}.',
    },
    {
        'id': 1,
        'template': '当{{subspace}}时， {{focus}} 占 {{agg}} {{measure}} 的 {{parameter}}.',
    },
    {
        'id': 2,
        'template': '当{{subspace}}时， {{focus}} 在 {{agg}} {{measure}} 中的占比为 {{parameter}}.',
    },
]

export default templates;