import React, { Component } from "react";
import { message } from 'antd';
import AvetarView from '../AvatarView/AvatarView';
import TextareaAutosize from 'react-autosize-textarea';
import config from '@/axios/config';
import './PostAcommentView.less';

export default class PostAcommentView extends Component {

    constructor(props) {
        super(props);
        this.state = {
            value: 1,
            textValue: this.props.textValue || '',
            isShowCommentView: false,
            isInputing: false
        }
    }
    onChange = e => {
        //console.log('radio checked', e.target.value);
        this.setState({
            value: e.target.value,
        });
    };
    onFocus = () => {
        if (this.props.isLogIn) {
            this.setState({ isShowCommentView: true });
        } else {
            window.location.href = config.url.loginRedirectUrl
        }
    }
    cancelComment = () => {
        this.setState({
            isShowCommentView: false,
            textValue: ''
        });
        this.props.cancelCommentListener()
    }
    inputText = ({ target: { value } }) => {
        if (value === '') {
            this.setState({
                textValue: value,
                isInputing: false
            });
        } else {
            this.setState({
                textValue: value,
                isInputing: true
            });
        }

    };
    postComment = () => {
        if (this.state.textValue === '') {
            message.info(this.props.intl.get("please enter your comment"))
            return;
        }
        this.setState({
            textValue: '',
            isInputing: false,
            isShowCommentView: false
        });
        this.props.postACommentListener(this.state.textValue)
    }

    render() {
        const { textValue, isShowCommentView, isInputing } = this.state;
        const { userInfo, isLogIn, intl } = this.props;
        //console.log("isLogIn", isLogIn)
        return (
            <div className='comments'>
                <AvetarView {...this.props} userInfo={userInfo} />
                <div className='postWrapper'>
                    <TextareaAutosize
                        className='TextareaAutosize'
                        resize={'none'}
                        value={textValue}
                        onFocus={this.onFocus}
                        onChange={(e) => this.inputText(e)}
                        placeholder={isLogIn ? intl.get("Please enter your comment") : intl.get("Please Log in before post your comment")}
                    />
                    <div className='postDiv' onClick={this.postDiv} style={{ visibility: isShowCommentView ? 'visible' : 'hidden' }}>
                        <div onClick={this.cancelComment} className='CancelBtn'>
                            {intl.get("Cancel")}
                        </div>
                        <div onClick={() => this.postComment()} className='CommentBtn' style={{ backgroundColor: isInputing ? '#F48B1E' : '#EBEDF2' }} >{intl.get("Comment")}</div>
                    </div>
                </div>
            </div>

        );

    }
}