import React, { Component } from "react";
import { Icon } from 'antd';
import './CommentsControlView.less';

export default class CommentsControlView extends Component {
    constructor(props) {
        super(props)
        this.state = {
            isArrowUp: false
        }
    }
    clickArrow = () => {
        this.setState({
            isArrowUp: !this.state.isArrowUp
        })
        this.props.onControlListener(!this.state.isArrowUp)
    }

    render() {
        const { comments, isCollapse, total, intl } = this.props
        const { isArrowUp } = this.state

        return (
            <div className='commentListLength'>
                <div className='smallLine'></div>
                <span style={{ cursor: 'pointer' }} onClick={this.clickArrow}> {intl.get("Comments", { Comments: comments && comments.length, total })}</span>
                <Icon
                    style={{ display: !isCollapse ? 'none' : 'block', position: 'absolute', fontSize: '10px', right: '5px', top: '30%' }}
                    type={isArrowUp ? "up" : 'down'}
                    onClick={this.clickArrow} className='arrow' />
            </div>
        )
    }
}