import React, { Component } from 'react';
import { Spin } from 'antd';
import PostAcommentView from '@/components/ForumView/PostAcomment/PostAcomment';
import CommentsControlView from './CommentsControlView/CommentsControlView';
import CommentListView from '@/components/ForumView/CommentList/CommentListView';
import * as api from '@/axios/api';
import activitiesCode from '@/axios/activitiesCode';
//import _ from 'lodash';
import './CommentsView.less';
//let moment = require('moment');

export default class CommentsView extends Component {

    constructor(props) {
        super(props);
        this.state = {
            userName: this.props.userInfo.username,
            value: 1,
            comments: this.props.comments || [],
            action: null,
            isShowPostAReply: false,
            openReplyPannel: false,
            postAReplyIndex: '',
            isCollapse: false,
            isShowCommentsList: true
        }
    }

    componentWillMount() {
        //console.log("IndexLeftMenueView...")
        // 创建媒体查询列表对象
        var mql = window.matchMedia(`(max-width: 880px)`);
        this.mediaQueryChanged(mql)
        // 添加监听器
        mql.addListener(this.mediaQueryChanged);
    }

    componentWillReceiveProps(newProps) {
        if (typeof newProps.comments !== 'undefined' && newProps.comments !== this.state.comments) {
            this.setState({
                comments: newProps.comments
            })
            //console.log("componentWillReceiveProps", newProps.comments)
        }
    }

    mediaQueryChanged = (mql) => {
        if (mql.matches) { // 媒体查询
            this.setState({
                isCollapse: true,
                isShowCommentsList: false
            })
        } else {
            this.setState({
                isCollapse: false,
                isShowCommentsList: true
            })
        }
    }

    controlCommentList = (isOpen) => {
        this.setState({
            isShowCommentsList: isOpen
        })
    }
    postCommentToBackend = (value) => {
        let body = {
            comment: value
        }
        let { sid } = this.props.OperateRelatedId
        //let { userInfo } = this.props

        api.postComments(sid, body).then((reslove) => {
            // let newComment = {
            //     cid: reslove.cid,
            //     content: value,
            //     isdelete: 0,
            //     replies: [],
            //     replyto: 0,
            //     sdown: 0,
            //     sid,
            //     sup: 0,
            //     time: moment().format(),
            //     uid: userInfo.uid,
            //     user: userInfo
            // }
            // const { commentTree, count } = this.state.comments
            // let currentCommentTree = _.cloneDeep(commentTree)
            // currentCommentTree.unshift(newComment)
            // this.setState({
            //     comments: {
            //         commentTree: currentCommentTree,
            //         count: count + 1
            //     }
            // })
            this.props.updateCommentsList()
            //activitiesApi
            let uid = this.props.userInfo.uid
            let body = {
                action: activitiesCode.commentStory,
                objectid: parseInt(sid),
                meta: reslove.cid.toString(), //评论id
            }
            api.activitiesApi(uid, body).then(response => {
            })
        }, reject => {
        })
    }
    commentAstory = (value) => {
        this.setState({
            textValue: '',
            isShowPostAReply: false,
            openReplyPannel: false
        })
        this.postCommentToBackend(value);
    }
    render() {
        const { isCollapse, isShowCommentsList } = this.state;
        const { loadingComments, isHiddenCommentStoryView, intl } = this.props
        const { commentTree, count } = this.state.comments
        //console.log("render", commentTree, count)
        return (
            <div className='CommentsView' >
                <CommentsControlView
                    isCollapse={isCollapse}
                    comments={commentTree}
                    total={count}
                    onControlListener={(isOpen) => this.controlCommentList(isOpen)}
                    intl={intl} />
                <div className='postComments' style={{ display: isHiddenCommentStoryView ? "none" : "block" }}>
                    <PostAcommentView
                        // userName={userName}
                        postACommentListener={(value) => this.commentAstory(value)}
                        cancelCommentListener={() => { }}
                        isSmallAvatar={isCollapse}
                        {...this.props} ></PostAcommentView>
                </div>
                {
                    isShowCommentsList ?
                        <div className='commentList-container' >
                            <CommentListView
                                isSmallAvatar={isCollapse}
                                commentsList={commentTree}
                                {...this.props}
                            />
                            {(loadingComments)
                                ?
                                <div className="loading-container">
                                    <Spin tip={intl.get("Loading")} />
                                </div>
                                : null
                            }
                        </div >

                        :
                        null
                }

            </div >
        )
    }
}