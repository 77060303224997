/* eslint no-eval: 0 */
import React, { Component } from 'react';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import uuidv4 from 'uuid/v4';
import EventView from './EventView';
import Fact from '@/model/fact'

//import _ from 'lodash';
import './sequenceview.less';
import { genSubtitle, genFactSentence} from '../../sentencer';
import config from '@/axios/config'
import {getFactChartType} from '../../tool/fact2vis';
import * as api from '@/axios/api'
import OperationType from '@/constant/OperationType'
import activitiesCode from '@/axios/activitiesCode';
import AddFact from './AddFact';
import {message} from 'antd'
import _ from 'lodash';
import { DropTarget } from 'react-dnd'
import generateIteratively from '@/network/generateIteratively';

const Types = {
    CARD: 'card'
}

const getListStyle = isDraggingOver => ({
    // background: isDraggingOver ? 'lightblue' : 'lightgrey',
    display: 'flex',
    overflow: 'auto',
});
const calcIndex = function(pos){
    let candidates = document.getElementsByClassName("creatstory-eventview");
    const borderGap = 60;
    let index = 0;
    let prev;
    let insert;
    let updateFlag=false;
    let update;
    for(let slot of candidates){
        let box = slot.getBoundingClientRect();
            if(pos.x <= box.left + box.width/4 && pos.x >= box.left - borderGap && pos.y >= box.top && pos.y <= box.bottom){
                return { insert: index ,updateSingleFlag:false,update:update}
            }
            if(pos.x <= box.right - box.width/4 && pos.x >= box.left + box.width/4 && pos.y >= box.top && pos.y <= box.bottom){
                slot.style['background']="rgb(255, 176, 7)"
                return { insert: insert ,updateSingleFlag:true,update:index}
            }

        index ++;
        prev = box;
    }
    if(!prev) return { insert: 0 ,updateSingleFlag:false,update:update}
    //last one
    if(!insert && prev && pos.x <= prev.right + borderGap && pos.x >= prev.right - prev.width/2 && pos.y >= prev.top && pos.y <= prev.bottom){
        insert = index;
        updateFlag=false;
    }
    if(pos.y > prev.bottom && pos.y <= prev.bottom + borderGap){
        insert = index;
        updateFlag=false;
    }

    return { insert: insert ,updateSingleFlag:updateFlag, update:update}
}
let recordbreakTimes=0;
let recordPreIndex=-1;
const cardDropTarget = {
    hover(props, monitor, component) {
        // This is fired very often and lets you perform side effects
        // in response to the hover. You can't handle enter and leave
        // here—if you need them, put monitor.isOver() into collect() so you
        // can use componentDidUpdate() to handle enter/leave.

        // You can access the coordinates if you need them
        // const clientOffset = monitor.getClientOffset()
        // const componentRect = findDOMNode(component).getBoundingClientRect()

        // // You can check whether we're over a nested drop target
        // const isOnlyThisOne = monitor.isOver({ shallow: true })

        // // You will receive hover() even for items for which canDrop() is false
        // const canDrop = monitor.canDrop()
        let pos = monitor.getClientOffset();
       
        recordPreIndex=props.hoverFactIndex
        if(calcIndex(pos).insert){
            if(recordbreakTimes<1){
                component.props.setHoverIndex(calcIndex(pos).insert);
                // recordbreakTimes=0
            } 
            if(calcIndex(pos).insert===recordPreIndex) recordbreakTimes++;
            if(calcIndex(pos).insert!==recordPreIndex) recordbreakTimes=0;
        }
    },

    drop(props, monitor, component) {
        if (monitor.didDrop()) {
            // If you want, you can check whether some nested
            // target already handled drop
            return
        }

        // Obtain the dragged item
        component.props.setHoverIndex(-1);
        let pos = monitor.getClientOffset();
        return calcIndex(pos);
    }
}

/**
 * Specifies which props to inject into your component.
 */
function collect(connect, monitor) {
  return {
    // Call this function inside render()
    // to let React DnD handle the drag events:
    connectDropTarget: connect.dropTarget(),
    // You can ask the monitor about the current drag state:
    isOver: monitor.isOver(),
    // isOverCurrent: monitor.isOver({ shallow: true }),
    canDrop: monitor.canDrop(),
    // itemType: monitor.getItemType()
  }
}


 class SequenceView extends Component {
    constructor(props) {
        super(props)
        const { facts, relations, aggregationLevel,algorithm} = this.props;
        this.state = {
            facts,
            relations,
            aggregationLevel,
            algorithm
        }
    }
    shouldComponentUpdate(nextProps){
        if(!_.isEqual(nextProps.hoverFactIndex,this.props.hoverFactIndex)) return true
        if(_.isEqual(nextProps.facts,this.props.facts)&&(_.isEqual(nextProps.selectedFactIndex,this.props.selectedFactIndex))) return false
        return true
    }
    onDragStart = (result) => {
        //this.props.selectFact(result.source.index)
    }

    onDragEnd = (result) => {
        // dropped outside the list
        if (!result.destination) {
            return;
        }
        this.props.orderFacts(result.source.index, result.destination.index);
          //activitiesApi
          let uid = this.props.userInfo.uid
          let boby = {
              action: activitiesCode.editLayout,
              objectid: -1,
              meta: `facts:${this.props.facts}`,
          }
          api.activitiesApi(uid, boby).then(response => {
              //   console.log("activitiesApi",response)
          })

        // record_activity(activitiesCode.editLayout, 1 , this.props.facts);
    }

    onChangeAggregation = (value) => {
        this.props.setAggregationLevel(value / 100);
    }

    addEmptyFact=(insert)=>{
        let emptyFact = new Fact(
            '',
            [],
            [],
            [],
            [],
            [],
            "", // chart
            0,
            0,
            0
        )
        // record_activity(activitiesCode.insertNewFact, 1 , emptyFact)
        this.props.insertEmptyFact(insert,emptyFact)
    }

    interpolateFacts=(insert,events)=>{
        let url=config.url.interpolateMCTS
        let startFact={}
        let endFact={}
        let startEvent=events[insert-1].fact
        let endEvent=events[insert].fact
        let interpolatedFacts=[]
        if(this.props.algorithm==="MCTS")  url=config.url.interpolateMCTS
        if(this.props.algorithm==="QL") url=config.url.interpolateQL
        if(startEvent.type!=="") 
            startFact={
                "type":startEvent.type,
                "measure":startEvent.measure,
                "groupby":startEvent.groupby,
                "subspace":startEvent.subspace,
                "focus":startEvent.focus
            } 
        if(endEvent.type!=="") 
            endFact={
                "type":endEvent.type,
                "measure":endEvent.measure,
                "groupby":endEvent.groupby,
                "subspace":endEvent.subspace,
                "focus":endEvent.focus
            } 
        if(endEvent.type===""||startEvent.type==="") return;
        this.interpolate(url,this.props.calSimilarityType,startFact,endFact).then(response=>{
            let facts = response.data.story.facts.facts;
            facts.pop()
            facts.shift()
            let tempFacts = [];
            let interval=Math.ceil(facts.length/(15-events.length))+1
            let endFlag=0;
            if(facts.length<=15-events.length) tempFacts=facts
            else{
                if(events.length===14){
                    if(facts.length>1)
                    tempFacts.push(facts[facts.length-1])
                    else tempFacts.push(facts[0])
                }
                else{
                    for(let i=0;i<facts.length;i+=interval){
                        tempFacts.push(facts[i])
                        endFlag=i
                    }
                    if(endFlag!==facts.length-1 && facts.length>1) tempFacts.push(facts[facts.length-1])    
                }
            }
           
            for (let factDict of tempFacts) {
                let fact = new Fact(
                    factDict['type'],
                    factDict['measure'],
                    factDict['subspace'],
                    factDict['groupby'],
                    factDict['focus'],
                    factDict['parameter'],
                    "", // chart
                    factDict['score'],
                    factDict['information'],
                    factDict['significance']
                )
                fact.uuid = uuidv4();
                fact.chart = getFactChartType(this.props.schema, fact);//针对生成页面的chart
                fact.generatedScript = genFactSentence(fact, this.props.currentLocale)
                fact.generatedSubtitle = genSubtitle(fact, this.props.currentLocale)
                interpolatedFacts.push(fact);
            }
            // this.props.generateStory(tempFacts.slice(), [
            //     "none",
            //     "similarity",
            //     "similarity",
            //     "similarity",
            //     "similarity",
            //     "similarity"
            // ], 1);
            this.props.insertInterpolatedFacts(insert,interpolatedFacts)
            // this.props.changeInterpolationFlag(true)
        })
    }

    
    interpolate = (url,similarity,startFact,endFact) => {
        //  let url = this.getGenerateURL("");
        return new Promise(async (resolve, reject) => {
            let fileName = this.props.fileName;
            let filters = _.cloneDeep(this.props.filtersLocal);
            this.setState({
                operateState: OperationType.GENERATING
            });
            this.props.loadingGeneratingView(true)
            let data;
            if(this.props.algorithm==="MCTS")
                data = {
                    file_name: fileName,
                    filters: JSON.stringify(filters),
                    max_story_length: 6,
                    start_fact:startFact,
                    end_fact:endFact,
                    similarity:similarity,
                }
            if(this.props.algorithm==="QL")
                data = {
                    file_name: fileName,
                    filters: JSON.stringify(filters),
                    max_story_length: 6,
                    start_fact:startFact,
                    end_fact:endFact,
                    similarity:similarity,
                    ql_mode: "top",
                }
           
          
            const response = await api.interpolate(url, data)
            console.log("response",response)

            if (response.data.fail) {
                console.log('fail')
                message.info(this.props.intl.get("cannot interpolate."))
                this.setState({
                    operateState: OperationType.FAILED,
                    errorMessage: response.data.fail
                })
                reject()
            }
            if (response.data.error) {
                console.log('error')
                message.info(this.props.intl.get("cannot interpolate."))
                this.setState({
                    operateState: OperationType.FAILED,
                    errorMessage: response.data.error
                })
                reject()
            }
  
            this.setState({
                operateState: OperationType.GENERATED
            })


            this.props.loadingGeneratingView(false)
            resolve(response)
              //activitiesApi
              let uid = this.props.userInfo.uid
              let boby = {
                  action: activitiesCode.interpolateFacts,
                  objectid: -1,
                  meta: `startFact:${startFact}, endFact:${endFact}, response:${response}`
              }
              api.activitiesApi(uid, boby).then(response => {
                  //   console.log("activitiesApi",response)
              })
        })
    }

    generateIteratively = async (insert,events) => {
        if(!this.props.fileName.split(".")[0]) return;
        //const { storyParameter, fileName, method } = this.props;
        const { fileName, algorithm,calSimilarityType,maximumFact} = this.props;
        let tempFacts = [];
        let tree = ""
        let startFact=""
        let endFact=""
        let startEvent=events[insert-1].fact
        let endEvent=events[insert].fact
        let filters = _.cloneDeep(this.props.filtersLocal);
        if(startEvent.type!=="") 
            startFact={
                "type":startEvent.type,
                "measure":startEvent.measure,
                "groupby":startEvent.groupby,
                "subspace":startEvent.subspace,
                "focus":startEvent.focus
            } 
        if(endEvent.type!=="") 
            endFact={
                "type":endEvent.type,
                "measure":endEvent.measure,
                "groupby":endEvent.groupby,
                "subspace":endEvent.subspace,
                "focus":endEvent.focus
            } 
        if(endEvent.type===""||startEvent.type==="") return;
        this.setState({
            operateState: OperationType.GENERATING
        });
        this.props.loadingGeneratingView(true)
        let inserttemp=insert
        while(true) {
            const response = await generateIteratively(fileName, 6, '', startFact,endFact, tree, algorithm, JSON.stringify(filters),calSimilarityType,maximumFact) 
            
            if (response.data.fail) {
                message.info(this.props.intl.get("cannot interpolate."))
                this.setState({
                    operateState: OperationType.ADD_FAILED,
                    errorMessage: response.data.fail
                })
                return;
            }
            if (response.data.error) {
                message.info(this.props.intl.get("cannot interpolate."))
                this.setState({
                    operateState: OperationType.ADD_FAILED,
                    errorMessage: response.data.error
                })
                return;
            }
            let tempdata
            if(response.data.story) tempdata=response.data
            else tempdata=eval('('+response.data+')')
            const facts = tempdata.story.facts.facts;
            if(facts===null){
                break;
            }
            facts.pop()
            facts.shift()
            tree = tempdata.tree;
            tempFacts = [];
            if(facts&&facts.length>0){
                if(algorithm==="MCTS"){
                    let factDict=facts[facts.length-1] //mcts逐个生成
                    let fact = new Fact(
                        factDict['type'],
                        factDict['measure'],
                        factDict['subspace'],
                        factDict['groupby'],
                        factDict['focus'],
                        factDict['parameter'],
                        "", // chart
                        factDict['score'],
                        factDict['information'],
                        factDict['significance']
                    )
                    fact.uuid = uuidv4();
                    fact.chart = getFactChartType(this.props.schema, fact);//针对生成页面的chart
                    fact.generatedScript = genFactSentence(fact, this.props.intl.options.currentLocale)
                    fact.generatedSubtitle =genSubtitle(fact,this.props.intl.options.currentLocale)
                    tempFacts.push(fact);
                    this.props.insertInterpolatedFacts(inserttemp,tempFacts)
                    tempFacts=[]
                    inserttemp++;
                }else{  //Qlearning一次性返回
                    for (let factDict of facts) {
                        let fact = new Fact(
                            factDict['type'],
                            factDict['measure'],
                            factDict['subspace'],
                            factDict['groupby'],
                            factDict['focus'],
                            factDict['parameter'],
                            "", // chart
                            factDict['score'],
                            factDict['information'],
                            factDict['significance']
                        )
                        fact.uuid = uuidv4();
                        fact.chart = getFactChartType(this.props.schema, fact);//针对生成页面的chart
                        fact.generatedScript = genFactSentence(fact, this.props.intl.options.currentLocale)
                        fact.generatedSubtitle =genSubtitle(fact,this.props.intl.options.currentLocale)
                        tempFacts.push(fact);
                    }
                    this.props.insertInterpolatedFacts(insert,tempFacts)

                }
            }
            if(tempdata.flag) break;
            
        }
        //生成故事结束
        this.setState({
            operateState: OperationType.GENERATED
        })
        this.props.loadingGeneratingView(false)
        //activitiesApi
        let uid = this.props.userInfo.uid
        let boby = {
            action: activitiesCode.interpolateFacts,
            objectid: -1,
            meta: `startFact:${startFact}, endFact:${endFact}`
        }
        api.activitiesApi(uid, boby).then(response => {
            //   console.log("activitiesApi",response)
        })
    }
    render() {
        const { facts, relations, aggregationLevel } = this.props;
        //const { facts, relations, aggregationLevel } = this.state;
        let isDragDisabled = aggregationLevel > 0;
        let events = facts.map(function (x, i) {
            return {
                id: uuidv4(),
                index: i,
                fact: x,
                relation: relations[i]
            }
        });
        events = events.filter(e => {
            if (!e.fact.aggregated) {
                return true
            } else {
                return e.fact.aggregated && e.fact.aggregatedFact
            }
        })

        const { connectDropTarget } = this.props
        return connectDropTarget(
            <div style={{ position: 'relative', display: 'flex', flexDirection: "row" ,height:"100%"}}>
                <DragDropContext onDragStart={this.onDragStart} onDragEnd={this.onDragEnd}>
                    <Droppable droppableId="storyline" direction="horizontal">
                        {(provided, snapshot) => (
                            <div
                                ref={provided.innerRef}
                                {...provided.droppableProps}
                                style={getListStyle(snapshot.isDraggingOver)}
                            >
                                {events.map(function (event, index) {
                                    if (event.fact) {
                                        
                                        // (<div key={index} style={{width: index===events.length-1 ?"185px":"180px"}}>
                                        return  <Draggable key={index} index={index} draggableId={event.id} isDragDisabled={isDragDisabled}>
                                            {(provided, snapshot) => (
                                                <div
                                                    ref={provided.innerRef}
                                                    {...provided.draggableProps}
                                                    {...provided.dragHandleProps}
                                                    // style={{width:index+1===events.length?"190px":""}}
                                                >
                                                    <EventView key={event.id} index={event.index} uuid={event.id} fact={event.fact} isSelected={this.props.selectedFactIndex === event.index} {...this.props} />
                                                    <div className="mixButton" style={{display:index+1===events.length?'none':'flex'}}>
                                                        <div className="addEmptyChart" onClick={events.length<15?()=>this.addEmptyFact(index+1):null}></div>
                                                        <div className="interpolateCharts" style={{display:index+1===events.length||events[index+1].fact.type===""||events[index].fact.type===""?"none":""}} onClick={events.length<15?()=>this.generateIteratively(index+1,events): null}></div>
                                                    </div>
                                                </div>
                                            )}
                                          
                                        </Draggable>
                                    }
                                }.bind(this))}
                                {provided.placeholder}
                            </div>
                        )}
                    </Droppable>
                </DragDropContext>
                {/* mixmum of story length: 15 */}
                <div className="finalAddFact" style={{display:events.length?"flex":"none"}}>
                    <div className="addEmptyChart" onClick={events.length<15?()=>this.addEmptyFact(events.length):null}></div>
                </div>
                <AddFact {...this.props} events={events}/>
            </div>
        )
    }
}
export default DropTarget(
    Types.CARD,
    cardDropTarget,
    collect
  )(SequenceView)
  


  // import _ from 'lodash';
// import React, { Component } from 'react';
// import DropView from './DropView';

// export default class SequenceView extends Component {
//     constructor(props) {
//         super(props)
//        this.state={
//             fact:this.props.fact
//        }
//     }
//     shouldComponentUpdate(nextProps, nextState) {
//         if(nextProps.hoverFactIndex===undefined) return false;
//         // if (!_.isEqual(nextProps.facts,this.props.facts)||(!_.isEqual(this.props.hoverFactIndex,nextProps.hoverFactIndex))||(!_.isEqual(this.props.selectedFactIndex,nextProps.selectedFactIndex))){
//         //     return true
//         // } 
//         // return false
//         if(!_.isEqual(nextProps.hoverFactIndex,this.props.hoverFactIndex)) return true
//         if(_.isEqual(nextProps.facts,this.props.facts)&&(_.isEqual(nextProps.selectedFactIndex,this.props.selectedFactIndex))) return false
//         return true
//     }

//     // addFact = () => {
//     //     this.props.addFact();
//     //     if (this.listNode) {
//     //         setTimeout(() => {
//     //             this.listNode.scrollIntoView({
//     //                 behavior: "smooth",
//     //                 inline: "end"
//     //             })
//     //         }, 1000)
//     //     }
//     // }
   
//     render() {   
 
//         return (
//            <DropView {...this.props}/>
//         )
//     }
// }

  


