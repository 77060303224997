import { connect } from 'react-redux';
import { fileName,real_filename, data, schema, facts, title, relations, selectedFactIndex, storyParameter, resultCoverage, uuid } from '@/selector/story';
import { userEnd, layoutType } from '@/selector/user';
import PublishView from './PublishView';
import { visContainerSize } from '@/selector/vis';
import * as storyAction from '../../action/storyAction';
import * as dataAction from '../../action/dataAction';
import * as userAction from '@/action/userAction';
import * as visAction from '@/action/visAction';
import { dataresLocal, filtersLocal, filtersTitleLocal, schemaFilterLocal, subtitle } from '../../selector/story';

const mapStateToProps = (state) => ({
    fileName: fileName(state),
    real_filename:real_filename(state),
    data: data(state),
    schema: schema(state),
    facts: facts(state),
    title: title(state),
    relations: relations(state),
    selectedFactIndex: selectedFactIndex(state),
    storyParameter: storyParameter(state),
    resultCoverage: resultCoverage(state),
    uuid: uuid(state),
    visContainerSize: visContainerSize(state),
    userEnd: userEnd(state),
    layoutType: layoutType(state),
    filtersLocal:filtersLocal(state),
    filtersTitleLocal:filtersTitleLocal(state),
    dataresLocal:dataresLocal(state),
    schemaFilterLocal:schemaFilterLocal(state),
    subtitle:subtitle(state)
})

const mapDispatchToProps = dispatch => {
    return {
        //vis
        updateVisContainerSize: (containerSize) => dispatch(visAction.updateVisContainerSize(containerSize)),
        //user
        updateUserEnd: (userEndSize) => dispatch(userAction.updateUserEnd(userEndSize)),
        //story
        selectFact: (index) => dispatch(storyAction.selectFact(index)),
        generateStory: (facts, relations, coverage) => dispatch(storyAction.generateStory(facts, relations, coverage)),
        updateCommentStoryInfo: (facts, relations, data, schema, title, fileName, real_filename,storyParameter, aggregationLevel, resultCoverage, showSuggestion, subtitle,dataresLocal,schemaFilterLocal,filtersLocal,filtersTitleLocal ) => dispatch(storyAction.updateCommentStoryInfo(facts, relations, data, schema, title, fileName,real_filename, storyParameter, aggregationLevel, resultCoverage, showSuggestion, subtitle,dataresLocal,schemaFilterLocal,filtersLocal,filtersTitleLocal )),
        //data
        changeData: (fileName,real_filename) => dispatch(dataAction.changeData(fileName, real_filename)),
        uploadData: (fileName,real_filename, schema, data) => dispatch(dataAction.uploadData(fileName,real_filename, schema, data)),
        setAggregationLevel: (level) => dispatch(storyAction.setAggregationLevel(level)),
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(PublishView);
