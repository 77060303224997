import React, { Component } from 'react';
import { Input, Icon } from 'antd';
import './SearchView.less'

export default class SearchView extends Component {

    constructor(props) {
        super(props);
        this.searchValue = ''
    }
    onChange = (e) => {
        this.searchValue = e.target.value
    }
    onPressEnter = (e) => {
        this.searchValue = e.target.value;
        this.search();
    }
    search = () => {
        this.props.onSearchListener(this.searchValue)
    }
    render() {
        const { intl } = this.props
        return (
            <Input
                allowClear={true}
                className='seachDiv'
                onChange={(e) => this.onChange(e)}
                onPressEnter={(e) => this.onPressEnter(e)}
                placeholder={intl.get("input search text")}
                suffix={
                    <Icon type=" " onClick={() => this.search()} className='searchImage' />
                }
            />
        )
    }
}