import React from 'react';
import {
  HashRouter as Router,
  Switch,
  Route,
  useParams
} from "react-router-dom";

import LoginLoadingView from '@/components/LoginLoadingView/index';
import LogoutLoadingView from '@/components/LogoutLoadingView/index';
import HomePage from '@/pages/HomePage/index';
import IndexPage from '@/pages/IndexPage/index';
import CreateStoryPage from '@/pages/CreateStoryPage/index';
import PublishView from '@/view/PublishView';
import LayoutType from '@/constant/LayoutType';

import './App.less'
import intl from 'react-intl-universal';
// common locale data
require('intl/locale-data/jsonp/en.js');
require('intl/locale-data/jsonp/zh.js');

// app locale data
const locales = {
  "en-US": require('./locales/en-US.json'),
  "zh-CN": require('./locales/zh-CN.json'),
};

export default class App extends React.Component {
  state = {
    initDone: false,
    currentLocale: ''
  }

  loadLocales(lang) {
    // init method will load CLDR locale data according to currentLocale
    // react-intl-universal is singleton, so you should init it only once in your app
    intl.init({
      currentLocale: lang,
      locales,
    })
      .then(() => {
        // After loading CLDR locale data, start to render
        this.setState(
          { initDone: true, currentLocale: lang });
      });
  }

  initDefaultLang = () => {
    let userDefaultLang = navigator.language || navigator.userLanguage;//常规浏览器语言和IE浏览器
    let lang = userDefaultLang.substr(0, 2);//截取lang前2位字符
    if (lang === 'zh') {
      lang = "zh-CN"
    } else {
      lang = "en-US"
    }
    if (this.state.currentLocale !== lang) {
      this.loadLocales(lang)
    }
  }

  componentDidMount() {
    this.initDefaultLang()
  }
  render() {
    const { initDone, currentLocale } = this.state
    function Child() {
      // We can use the `useParams` hook here to access
      // the dynamic pieces of the URL.
      let { id, layout } = useParams();
      let publishView
      switch (layout) {
        case 'mobile':
          publishView = <PublishView layout={LayoutType.SLIDER_MOBILE} storyId={id} intl={intl} />
          break;

        case 'storyline':
          publishView = <PublishView layout={LayoutType.STORYLINE_WEB} storyId={id} intl={intl} />
          break;

        case 'factsheet':
          publishView = <PublishView layout={LayoutType.FACTSHEET} storyId={id} intl={intl} />
          break;

        default:
          break;
      }
      return (
        <div>
          {publishView}
        </div>
      );
    }

    return (
      <Router>
        <Switch>
          <Route exact path="/publish/mobile/*">
            <PublishView layout={LayoutType.SLIDER_MOBILE} isHiddenShareModal={true} intl={intl} />
          </Route>
          <Route exact path="/publish/storyline/*">
            <PublishView layout={LayoutType.STORYLINE_WEB} isHiddenShareModal={true} intl={intl} />
          </Route>
          <Route exact path="/publish/factsheet/*">
            <PublishView layout={LayoutType.FACTSHEET} isHiddenShareModal={true} intl={intl} />
          </Route>
          <Route exact path="/publish/:layout/:id" children={<Child />} />

          <Route path='/index/story/' render={
            props => {
              return <CreateStoryPage history={props.history} initDone={initDone} intl={intl} currentLocale={currentLocale}
                onChangeLocaleListener={lang => this.loadLocales(lang)}></CreateStoryPage>
            }
          }>
          </Route>
          <Route path='/logout' render={
            props => {
              return <LogoutLoadingView history={props.history} location={props.location} initDone={initDone} intl={intl} ></LogoutLoadingView>
            }
          }>
          </Route>
          <Route path='/index' render={
            props => {
              return <IndexPage history={props.history} location={props.location} initDone={initDone} intl={intl} currentLocale={currentLocale}
                onChangeLocaleListener={lang => this.loadLocales(lang)}></IndexPage>
            }
          }>
          </Route>
          <Route path='/homePage' render={
            props => {
              return <HomePage history={props.history} location={props.location} initDone={initDone} intl={intl}
                currentLocale={currentLocale}
                onChangeLocaleListener={lang => this.loadLocales(lang)}></HomePage>
            }
          }>
          </Route>
          <Route path='/' render={
            props => {
              return <LoginLoadingView history={props.history} location={props.location} initDone={initDone} intl={intl}></LoginLoadingView>
            }
          }>
          </Route>
        </Switch>
      </Router>
    )
  }
}
