/* eslint no-eval: 0 */
import React from 'react';
import "./index.less";
import { Row, Col,message,Modal} from 'antd';
import OperationType from '@/constant/OperationType'
import activitiesCode from '@/axios/activitiesCode';
import * as api from '@/axios/api';
import Fact from '@/model/fact';
import config from '@/axios/config';
import uuidv4 from 'uuid/v4';
import { genFactSentence,genSubtitle} from '../../../sentencer/index';
import generateIteratively from '@/network/generateIteratively';
import _ from 'lodash';
import * as d3 from 'd3'
const addFactNum=10
export default class RightBottomButton extends React.Component{
    constructor(props) {
        super(props);
        this.state = {
            remakeJudge:false//判断是否进行清空图表的操作
        }
    }
    componentDidMount() {
      
    }
    componentDidUpdate() {
        
    }

    clickReset = () =>{
        // this.props.changeInterpolationFlag(false)
        // this.props.optimizeLayout(false)

        d3.selectAll('.selection').remove();
        // d3.selectAll('.xBrushInit').remove();
        this.props.updateFiltersChangeFlag(false)
        this.props.updateFilters(_.cloneDeep(this.props.filtersOrigin))
        this.props.updateFiltersTitle(_.cloneDeep(this.props.filtersTitleOrigin))
        this.props.updateFiltersLocal(_.cloneDeep(this.props.filtersOrigin), _.cloneDeep(this.props.filtersTitleOrigin), _.cloneDeep(this.props.data), _.cloneDeep(this.props.schema) )
        // let tempFacts=[]
        // for (let i=0;i<2;i++) {
        //     let fact = new Fact(
        //         '',
        //         [],
        //         [],
        //         [],
        //         [],
        //         [],
        //         "", // chart
        //         0,
        //         0,
        //         0
        //     )
        //     tempFacts.push(fact);
        // }
        // this.props.generateStory(tempFacts.slice(), [
        //     "none",
        //     "similarity",
        // ], 1);
        
       
        // this.props.unusedFacts.map((d,i)=>{
        //     this.props.deleteUnusedFact(0)
        // })
      
        // if(!this.props.unusedFacts.length) this.addFact()

    }

    addFacthere = () => {

        this.setState({
            operateState: OperationType.ADDING
        })
        //TODO:会有重复的fact
        this.adding(config.url.generateRandomlyNew).then(response => {
            const facts = response.data.story.facts;

            let tempFacts = [];
            for (let factDict of facts) {
                let fact = new Fact(
                    factDict['type'],
                    factDict['measure'],
                    factDict['subspace'],
                    factDict['groupby'],
                    factDict['focus'],
                    factDict['parameter'],
                    "", // chart
                    factDict['score'],
                    factDict['information'],
                    factDict['significance']
                );
                    fact.uuid = uuidv4();
                    tempFacts.push(fact);
                }
                this.props.addFact(tempFacts);
        });
    }

    adding = (url) => {
        return new Promise(async (resolve, reject) => {
            let fileName = this.props.fileName;
            let filters = _.cloneDeep(this.props.filtersLocal);
     
            let data;
            if(filters)
            data= {
                file_name: fileName,
                max_story_length: addFactNum,
                topic_filters: JSON.stringify(filters),
                // method: 'mcts'
            }
            else{
                data= {
                    file_name: fileName,
                    max_story_length: addFactNum,
                    // filters: JSON.stringify(filters),
                    // method: 'mcts'
                }
            }
         
            const response = await api.generate(url, data)
            console.log('response-adding', response)
            if (response.data.fail) {
                message.info(this.props.intl.get("cannot interpolate."))
                this.setState({
                    operateState: OperationType.ADD_FAILED,
                    errorMessage: response.data.fail
                })
                reject()
            }
            if (response.data.error) {
                message.info(this.props.intl.get("cannot interpolate."))
                this.setState({
                    operateState: OperationType.ADD_FAILED,
                    errorMessage: response.data.error
                })
                reject()
            }
            this.setState({
                operateState: OperationType.GENERATED
            })
            resolve(response)
        })
    }


    filteredgenerate = (algorithm) => {
        let url = algorithm==="MCTS"?config.url.interpolateMCTS:config.url.interpolateQL

        if(!this.props.fileName.split(".")[0]) return;

        return new Promise(async (resolve, reject) => {
            let fileName = this.props.fileName;

            let filters = _.cloneDeep(this.props.filters);

        
            this.props.updateFiltersChangeFlag(false)
            this.props.updateFiltersLocal(this.props.filters, this.props.filtersTitle, this.props.datares, this.props.schemaFilter) 
            this.setState({
                operateState: OperationType.GENERATING
            });
            this.props.loadingGeneratingView(true)

            let data = {
                file_name: fileName,
                filters: JSON.stringify(filters),
                max_story_length: 6,
                // method: "mcts",
                similarity:"w2v",
                start_fact:"",
                end_fact:"",
            }
           
            if(this.props.unusedFacts)
            if(this.props.unusedFacts.length>0)
            this.props.unusedFacts.map((d,i)=>{
                this.props.deleteUnusedFact(0)
                return null
            })


            if(this.props.recommendedEfacts)
            if(this.props.recommendedEfacts.length>0)
            this.props.recommendedEfacts.map((d,i)=>{
                this.props.deleteEFact(0)
                return null
            })
            if(this.props.recommendedSfacts)
            if(this.props.recommendedSfacts.length>0)
            this.props.recommendedSfacts.map((d,i)=>{
                this.props.deleteSFact(0)
                return null
            })
            if(this.props.recommendedMfacts)
            if(this.props.recommendedMfacts.length>0)
            this.props.recommendedMfacts.map((d,i)=>{
                this.props.deleteMFact(0)
                return null
            })
            const response = await api.interpolate(url, data)

            if (response.data.fail) {
                message.info(this.props.intl.get("cannot interpolate."))
                console.log('fail')
                this.setState({
                    operateState: OperationType.ADD_FAILED,
                    errorMessage: response.data.fail
                })
                reject()
                return
                // record_activity(activitiesCode.editTopic, 0 , {filename:fileName, filters: filters});
            }
            if (response.data.error) {
                message.info(this.props.intl.get("cannot interpolate."))
                console.log('error')
                this.setState({
                    operateState: OperationType.ADD_FAILED,
                    errorMessage: response.data.error
                })
                reject()
                return
                // record_activity(activitiesCode.editTopic, 0 , {filename:fileName, filters: filters});
            }
            const facts = response.data.story.facts.facts;
            let tempFacts = [];

            for (let factDict of facts) {
                let fact = new Fact(
                    factDict['type'],
                    factDict['measure'],
                    factDict['subspace'],
                    factDict['groupby'],
                    factDict['focus'],
                    factDict['parameter'],
                    "", // chart
                    factDict['score'],
                    factDict['information'],
                    factDict['significance']
                )
                fact.generatedScript = genFactSentence(fact, this.props.intl.options.currentLocale)
                fact.generatedSubtitle =genSubtitle(fact,this.props.intl.options.currentLocale)
                tempFacts.push(fact);
            }
            this.props.generateStory(tempFacts.slice(), [
                "none",
                "similarity",
                "similarity",
                "similarity",
                "similarity",
                "similarity"
            ], 1);


            this.setState({
                operateState: OperationType.GENERATED
            })

            if(!this.props.unusedFacts.length) this.addFacthere()

            this.props.loadingGeneratingView(false)
            resolve(response)
              //activitiesApi
              let uid = this.props.userInfo.uid
              let boby = {
                  action: activitiesCode.editTopic,
                  objectid: -1,
                  meta: `filename:${fileName}filters:${filters}`,
              }
              api.activitiesApi(uid, boby).then(response => {
                  //   console.log("activitiesApi",response)
              })
            // record_activity(activitiesCode.editTopic, 1 , {filename:fileName, filters: filters});
        })
    }

    generateIteratively = async () => {
        this.setState({
            remakeJudge:false
        })
        if(!this.props.fileName.split(".")[0]) return;
        this.props.generateStory([], [], '')
        //const { storyParameter, fileName, method } = this.props;
        const { fileName, algorithm, calSimilarityType,maximumFact} = this.props;
        let tempFacts = [];
        let relations=[];
        let tree = ""
        let startFact=""
        let endFact=""
        let filters = _.cloneDeep(this.props.filters);
        this.props.updateFiltersChangeFlag(false)
        this.props.updateFiltersLocal(this.props.filters, this.props.filtersTitle, this.props.datares, this.props.schemaFilter) 
        this.setState({
            operateState: OperationType.GENERATING
        });
        this.props.loadingGeneratingView(true)
        if(this.props.unusedFacts)
        if(this.props.unusedFacts.length>0)
        this.props.unusedFacts.map((d,i)=>{
            this.props.deleteUnusedFact(0)
            return null
        })

        if(this.props.recommendedEfacts)
        if(this.props.recommendedEfacts.length>0)
        this.props.recommendedEfacts.map((d,i)=>{
            this.props.deleteEFact(0)
            return null
        })
        if(this.props.recommendedSfacts)
        if(this.props.recommendedSfacts.length>0)
        this.props.recommendedSfacts.map((d,i)=>{
            this.props.deleteSFact(0)
            return null
        })
        if(this.props.recommendedMfacts)
        if(this.props.recommendedMfacts.length>0)
        this.props.recommendedMfacts.map((d,i)=>{
            this.props.deleteMFact(0)
            return null
        })
        while(true) {
            const response = await generateIteratively(fileName, 6, '', startFact,endFact, tree, algorithm, JSON.stringify(filters),calSimilarityType,maximumFact) 
            
            if (response.data.fail) {
                message.info(this.props.intl.get("cannot interpolate."))
                this.setState({
                    operateState: OperationType.ADD_FAILED,
                    errorMessage: response.data.fail
                })
                return;
            }
            if (response.data.error) {
                message.info(this.props.intl.get("cannot interpolate."))
                this.setState({
                    operateState: OperationType.ADD_FAILED,
                    errorMessage: response.data.error
                })
                return;
            }
            let tempdata
            if(response.data.story) tempdata=response.data
            else tempdata=eval('('+response.data+')')
            const facts = tempdata.story.facts.facts;
            if(facts===null){
                break;
            }
            tree = tempdata.tree;
            startFact= tempdata.story.facts.facts[0]
            endFact= tempdata.story.facts.facts[tempdata.story.facts.facts.length-1]
            relations=[];
            tempFacts = [];
            for (let factDict of facts) {
                let fact = new Fact(
                    factDict['type'],
                    factDict['measure'],
                    factDict['subspace'],
                    factDict['groupby'],
                    factDict['focus'],
                    factDict['parameter'],
                    "", // chart
                    factDict['score'],
                    factDict['information'],
                    factDict['significance']
                )
                fact.generatedScript = genFactSentence(fact, this.props.intl.options.currentLocale)
                fact.generatedSubtitle =genSubtitle(fact, this.props.intl.options.currentLocale)
                tempFacts.push(fact);
                relations.push('none')
            }
            this.props.generateStory(tempFacts.slice(), relations, 1)
            if(tempdata.flag) break;
            
        }
        //生成故事结束
        this.setState({
            operateState: OperationType.GENERATED
        })
        if(!this.props.unusedFacts.length) this.addFacthere()
        this.props.loadingGeneratingView(false)
        //activitiesApi
        let uid = this.props.userInfo.uid
        let boby = {
            action: activitiesCode.editTopic,
            objectid: -1,
            meta: `filename:${fileName};filters:${filters}`,
        }
        api.activitiesApi(uid, boby).then(response => {
            //   console.log("activitiesApi",response)
        })
    }
    clickRemakeJudge=()=>{
        this.setState({
            remakeJudge:true
        })
    }
    clickRemakeJudgeNo=()=>{
        this.setState({
            remakeJudge:false
        })
    }

    render() {
        const { intl, initDone} = this.props;

        const RegenerateView = () => {
            return (
                <Row className={this.props.filtersChangeFlag?"RegenerateView changefilter":"RegenerateView"}>
                    {/* <Col onClick={() => this.filteredgenerate(this.props.algorithm)}> */}
                    <Col onClick={()=>this.clickRemakeJudge()}>
                        <p> {initDone && intl.get("Regenerate")}</p>
                    </Col>
                </Row>
            )
        };

        // const ResetThemeView = () =>{
        //     return (
        //         <Row className="ResetTheme">
        //             <Col onClick={() => this.clickReset()}>
        //                 {/* <div ></div> */}
        //                 <p> {initDone && intl.get("Reset")}</p>
        //             </Col>
        //         </Row>
        //     )
        // }
        return(
        <div className="leftTopicPanelButton" style={{width:"290px"}}>
            <RegenerateView/>
            <Modal
                key = {"modal_clear"}
                className = "clear_story"
                // closable = {false}
                // title={initDone && intl.get("Clear")}
                centered
                visible={this.state.remakeJudge}
                // destroyOnClose = {true}
                onOk={() => this.generateIteratively()}
                onCancel={() => this.clickRemakeJudgeNo()}
                okText = {initDone && intl.get("OK")}
                 cancelText = {initDone && intl.get("Cancel")}
                style={{width:"400px"}}
                >
                    <div>
                    <div className="clearModal_title">{initDone && intl.get("Warning")}</div>
                    <div className="clearModal_hint">{initDone && intl.get("RegenerateModal")}</div>
                </div>
            </Modal> 
            {/* <ResetThemeView/> */}
        </div>

        )
    }
} 