import React, { Component } from "react";
import { Rate, message } from 'antd';
import * as api from '@/axios/api';
import activitiesCode from '@/axios/activitiesCode';
import ShareModalView from '@/components/ShareModalView/index';
import LayoutType from '@/constant/LayoutType';
import { getPublishLink } from '@/tool/publish';
import _ from 'lodash';
const compareTime=new Date("2021-11-2")
let winRef;
export default class RateAndShareView extends Component {
    constructor(props) {
        super(props)
        this.state = {
            story: this.props.story,
            isRatedBefore: false,
            visible: false
        }
    }

    shareStory = (story) => {
        //console.log("shareStory", this.props)
        // let tempUrl = 'www.idvxlab.com/' //先通过用户点击打开页面，然后再对页面进行重定向,解决safri使用window.open被阻止的问题
        // winRef = window.open(tempƒUrl, '_blank')
        // // this.props.shareStory(true)
        // this.getPublicShareLinkAndHref(story.sid)
        // const { OperateRelatedId } = this.props
        // this.props.updateOperateRelatedId({ ...OperateRelatedId, sid: story.sid })
        if(this.state.story){
            let oldStory=compareTime>new Date(this.state.story.time.split("T")[0])
            if(oldStory){
                message.info(this.props.intl.get("share story error"))
                return
            }
        }
        let newStory = _.cloneDeep(this.state.story)
        newStory.num_of_shares += 1
        this.setState(
            {
                story: newStory,
                visible: true
            }
        )
    }
    getPublicShareLinkAndHref = (sid) => {
        api.getSharedlink(sid).then((response) => {
            let uuid = response.url.split("publish/")[1]
            let sharePublishUrl = getPublishLink(this.props.layoutType) + uuid
            winRef.location = sharePublishUrl
            //activitiesApi
            let uid = this.props.userInfo.uid
            let body = {
                action: activitiesCode.shareStory,
                objectid: parseInt(sid),
                meta: response.url,
            }
            api.activitiesApi(uid, body).then(response => {
            })
        }, reject => {
            winRef.close()
            message.info(this.props.intl.get('Get public share link error'))
        })
    }
    rateStory = (value) => {
        const { story, isRatedBefore } = this.state
        let sid = story && story.sid
        let body = {
            rate: value
        }
        api.rating(sid, body).then((response) => {
            if (response.success) {
                let newStory = _.cloneDeep(story)
                newStory.rating = value
                if (story.rating && !isRatedBefore) {
                    newStory.num_of_rates += 1
                }
                this.setState({
                    story: newStory,
                    isRatedBefore: true
                })
                //activitiesApi
                let uid = this.props.userInfo && this.props.userInfo.uid
                let body = {
                    action: activitiesCode.rateStory,
                    objectid: sid,
                    meta: value.toString()
                }
                api.activitiesApi(uid, body).then(response => {
                })
            } else {
                message.info(this.props.intl.get("Rate failed"))
            }

        }, (reject) => {

        })
    }
    closeShareModal = () => {
        this.setState({
            visible: false
        })
    }
    render() {
        const { story, isRatedBefore, visible } = this.state
        const { layoutType, intl } = this.props
        //console.log("render", story)
        return (
            <div className='storyFunction'>
                {
                    (story && story.rating) ?
                        <Rate
                            allowHalf
                            disabled={isRatedBefore}
                            className='starDiv'
                            value={(story && parseInt(story.rating.toFixed(1).split('.')[1]) > 5) ?
                                parseInt(`${story.rating.toFixed(1).split('.')[0]}`) + 1
                                : parseInt(story && story.rating.toFixed(1).split('.')[1]) === 0 ? parseInt(story && story.rating.toFixed(1)) : parseFloat(`${story && story.rating.toFixed(1).split('.')[0]}.5`)}
                            onChange={(value) => this.rateStory(value)} />
                        :
                        <Rate className='starDiv' disabled={isRatedBefore} value={0} onChange={(value) => this.rateStory(value)} />
                }
                <span className='RateTxt'>{(story && story.rating && story.rating.toFixed(2)) || (0).toFixed(2)}</span>

                <span className='shareTxt'>{`(${(story && typeof story.num_of_rates !== 'undefined') ? story.num_of_rates : '--'} ${intl.get("ratings")})`}</span>

                <div className='shareDiv' style={{ display: layoutType === LayoutType.STORY_TEXT || layoutType === LayoutType.TABLE ? 'none' : 'flex' }}>
                    <div className='shareImage' onClick={() => this.shareStory(story)}></div>
                    <span className='shareTxt' onClick={() => this.shareStory(story)}> {story && story.num_of_shares} {intl.get("shares")}</span>
                </div>
                <ShareModalView visible={visible} closeShareModal={this.closeShareModal}  {...this.props} />
            </div>
        )
    }
}