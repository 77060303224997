import { connect } from 'react-redux';
import { fileName, real_filename, schema, relations, storyParameter, maxStoryLength, data, resultCoverage, facts, rewardWeight, title,subtitle ,filters,filtersTitle,datares,schemaFilter,filtersChangeFlag,dataresLocal, filtersLocal, filtersTitleLocal, schemaFilterLocal } from '@/selector/story';
import * as dataAction from '@/action/dataAction';
import * as factAction from '@/action/factAction';
import * as storyAction from '@/action/storyAction';
import * as userAction from '@/action/userAction';
import HeaderOperatorView from './HeaderOperatorView';
import { factsheetLayoutType } from '../../../selector/user';


const mapStateToProps = (state) => ({
    schema: schema(state),
    facts: facts(state),
    relations: relations(state),
    data: data(state),
    title: title(state),
    subtitle:subtitle(state),
    resultCoverage: resultCoverage(state),
    fileName: fileName(state),
    real_filename: real_filename(state),
    storyParameter: storyParameter(state),
    maxStoryLength: maxStoryLength(state),
    rewardWeight: rewardWeight(state),
    filters:filters(state),
    filtersTitle:filtersTitle(state),
    datares:datares(state),
    schemaFilter:schemaFilter(state),
    filtersChangeFlag:filtersChangeFlag(state),
    filtersLocal:filtersLocal(state),
    filtersTitleLocal:filtersTitleLocal(state),
    dataresLocal:dataresLocal(state),
    schemaFilterLocal:schemaFilterLocal(state),
    factsheetLayoutType:factsheetLayoutType(state)
})

const mapDispatchToProps = dispatch => {
    return {
        //data
        visualizeData: (data) => dispatch(dataAction.visualizeData(data)),
        changeData: (fileName, real_filename) => dispatch(dataAction.changeData(fileName, real_filename)),
        uploadData: (fileName, real_filename, schema, data) => dispatch(dataAction.uploadData(fileName, real_filename, schema, data)),
        //fact
        changeCurrentEditing:(editstate,editingCurrentFact)=>dispatch(factAction.changeCurrentEditing(editstate,editingCurrentFact)),
       
        //story
        changeTitle: (title) => dispatch(storyAction.changeTitle(title)),
        changeSubTitle:(subtitle)=>dispatch(storyAction.changeSubTitle(subtitle)),
        generateStory: (facts, relations, coverage) => dispatch(storyAction.generateStory(facts, relations, coverage)),

        undo:() => dispatch(storyAction.undo()),
        redo:() => dispatch(storyAction.redo()),

        //user
        changeFactsheetLayoutType:(factsheetLayoutType) => dispatch(userAction.changeFactsheetLayoutType(factsheetLayoutType))

    }
}

export default connect(mapStateToProps, mapDispatchToProps)(HeaderOperatorView);
