const getUserEndSize = (userEndSize) => {
    if (userEndSize === "(min-width: 1920px)") {
        return 'browser-max-pro';
    } else if (userEndSize === "(max-width: 1920px) and (min-width: 1300px)") {
        return 'browser-max';
    } else if (userEndSize === "(max-width: 1300px) and (min-width: 1080px)") {
        //console.log("browser-min...")
        return 'browser-min';
    } else if (userEndSize === "(max-width: 1080px) and (min-width: 880px)") {
        //console.log("ipad...")
        return 'ipad';
    } else if (userEndSize === "(max-width: 880px) and (min-width: 675px)") {
        return 'ipad-min';
    } else if (userEndSize === "(max-width: 675px) and (min-width: 414px)") {
        return 'iphone-pro'
    } else if (userEndSize === "(max-width: 414px)") {
        return 'iphone';
    }
    else {
        return ''
    }
}

export default getUserEndSize