const activitiesCode = {
    //datasets
    searchDatasets: 1,
    uploadDatasets: 2,
    editDatasets: 3,
    deleteDatasets: 4,
    //story
    searchStories: 5,
    createStories: 6,
    viewStory: 7,
    shareStory: 8,
    commentStory: 9,
    rateStory: 10,
    updateStory: 11,
    deleteStory: 12,
    upOrDownStory: 13,
    reply: 14,
    updateReply: 15,
    deleteReply: 16,
    //edit
    generateMoreFacts:17,
    editLayout:18,
    insertNewFact:19,
    deleteFact:20,
    editDiagram:21,
    interplateFacts:22,
    editTopic:23,
}
export default activitiesCode;