import React, { Component } from 'react';
import BlankView from './BlankView';
import ConfigureFact from './Fact';

import './FactEditor.less';

export default class FactEditor extends Component {
    // constructor(props){
    //     super(props);
    // }
    render() {
        let {editingFact,lang} = this.props;
        if(!editingFact){
        // if (editingFact.chart==="") {
            return (
                <BlankView {...this.props} />
            )
        } else {
            return (
                <ConfigureFact {...this.props} lang = {lang}/>
            )
        }
    }
}
