import React from 'react';
import { Icon } from 'antd';
import * as api from '@/axios/api';
import activitiesCode from '@/axios/activitiesCode';
import config from '@/axios/config';
import { message } from 'antd';
import './SaveStoryView.less';

export default class SaveStoryView extends React.Component {
    saveStory = () => {
        const { sid } = this.props.OperateRelatedId
        if (sid) { //更新故事
            this.editStory().then(() => {
                message.info(this.props.intl.get("Auto saved successfully"))
                this.resetStateAndToNewPage()
            })
            return
        }
        //发布故事
        this.publishStory().then((newSid) => {
            this.props.updateOperateRelatedId({ ...this.props.OperateRelatedId, sid: newSid })
            message.info(this.props.intl.get("Auto saved successfully"))
            this.resetStateAndToNewPage()
        }, reject => {
            message.info(this.props.intl.get("Publishing  story error"))
        })
    }
    editStory = () => {
        let hide = message.loading(this.props.intl.get("Updating"), 0);
        const { title, facts, relations, data, schema, aggregationLevel, storyParameter, resultCoverage, fileName,real_filename, showSuggestion,subtitle,dataresLocal,schemaFilterLocal,filtersLocal,filtersTitleLocal  } = this.props;
        // if (!facts || !data || !schema) return
        let { sid } = this.props.OperateRelatedId
        let averageFactScore = (facts.map(x => x.score).reduce((a, b) => a + b, 0)) / facts.length;
        if (!averageFactScore) averageFactScore = 0
        let description = `This generated data story includes ${facts.length} facts.The average importance score is ${parseInt(averageFactScore * 1000) / 1000} bit and the data coverage is ${parseInt(resultCoverage * 10000) / 100}%.`

        let storyObj = {
            facts,
            relations,
            data,
            schema,
            aggregationLevel,
            storyParameter,
            resultCoverage,
            fileName,
            real_filename,
            title,
            showSuggestion,
            subtitle,
            dataresLocal,
            schemaFilterLocal,
            filtersLocal,
            filtersTitleLocal
        }

        let body = {
            title,
            description,
            json: JSON.stringify(storyObj)
        }
        return new Promise((resolve, reject) => {
            api.updateStory(sid, body).then(() => {
                const { facts, relations, data, schema, storyParameter, aggregationLevel, resultCoverage, fileName,real_filename, title, showSuggestion,subtitle,dataresLocal,schemaFilterLocal,filtersLocal,filtersTitleLocal  } = storyObj
                this.props.updateCommentStoryInfo(facts, relations, data, schema, title, fileName,real_filename, storyParameter, aggregationLevel, resultCoverage, showSuggestion,subtitle,dataresLocal,schemaFilterLocal,filtersLocal,filtersTitleLocal )
                hide();
                resolve();
                //activitiesApi
                let uid = this.props.userInfo.uid
                let body = {
                    action: activitiesCode.updateStory,
                    objectid: sid,
                    meta: JSON.stringify(
                        {
                            title,
                            description,
                            json: JSON.stringify(storyObj)
                        }
                    ),
                }
                api.activitiesApi(uid, body).then(response => {
                })

            }, error => {
                hide();
                reject();
                message.info(this.props.intl.get("please try again"))
            })
        })
    }
    publishStory = () => {
        const hide = message.loading(this.props.intl.get("Publishing"), 0);
        const { title, facts, relations, data, schema, aggregationLevel, storyParameter, resultCoverage, fileName,real_filename, showSuggestion,subtitle,dataresLocal,schemaFilterLocal,filtersLocal,filtersTitleLocal  } = this.props;
        if (!facts || !data || !schema) return
        let { did } = this.props.OperateRelatedId
        //console.log("this.props", title, facts, relations, data, schema, aggregationLevel, storyParameter, resultCoverage, fileName)
        let averageFactScore = (facts.map(x => x.score).reduce((a, b) => a + b, 0)) / facts.length;
        if (!averageFactScore) averageFactScore = 0
        let description = `This generated data story includes ${facts.length} facts.The average importance score is ${parseInt(averageFactScore * 1000) / 1000} bit and the data coverage is ${parseInt(resultCoverage * 10000) / 100}%.`

        let storyObj = {
            facts,
            relations,
            data,
            schema,
            title,
            fileName,
            real_filename,
            aggregationLevel,
            storyParameter,
            resultCoverage,
            showSuggestion,
            subtitle,
            dataresLocal,
            schemaFilterLocal,
            filtersLocal,
            filtersTitleLocal
        }
        //console.log("publishStory storyObj", storyObj)
        let body = {
            did,
            title,
            description,
            json: JSON.stringify(storyObj)
        }

        return new Promise((resolve, reject) => {
            api.createStory(body).then((response) => {
                hide();
                let newSid = response.sid
                this.props.updateOperateRelatedId({ ...this.props.OperateRelatedId, sid: newSid })
                //activitiesApi
                let uid = this.props.userInfo.uid
                let body = {
                    action: activitiesCode.createStories,
                    objectid: newSid,
                    meta: null,
                }
                api.activitiesApi(uid, body).then(response => {
                })
                resolve(newSid)
            }, error => {
                hide();
                reject()
            })
        })
    }
    resetStateAndToNewPage = () => {
        this.props.leaveCreatePage(false) //important ************* reset state
        //这里兼容了，离开页面去其他页面 和 退出登陆 两种的逻辑
        if (this.props.isToLogout) {
            setTimeout(() => {
                //退出登陆 
                this.props.visualizeData({})
                api.logout().then((response) => {
                    let redirectUrl
                    if (process.env.NODE_ENV === 'production') {
                        redirectUrl = config.url.logoutRedirectUrl
                    } else {
                        redirectUrl = "http://localhost:3000/#/logout"
                    }
                    window.location.href = `${config.url.logoutAuthSystem}?redirectUrl=${redirectUrl}`
                })
            }, 500)
        } else {
            //离开页面去其他页面 
            if (this.props.newPage) {
                setTimeout(() => {
                    this.props.history.push(this.props.newPage)
                }, 500)
            }
        }
    }
    render() {
        const { intl } = this.props
        const FooterView = (props) => {
            return (<div className='wrapper'>
                <div className='centerWrapper' onClick={() => {
                    //直接离开
                    props.leaveCreatePage(false) //important ************* reset state
                    //这里兼容了，离开页面去其他页面 和 退出登陆 两种的逻辑
                    if (this.props.isToLogout) {
                        setTimeout(() => {
                            //退出登陆 
                            this.props.visualizeData({})
                            api.logout().then((response) => {
                                let redirectUrl
                                if (process.env.NODE_ENV === 'production') {
                                    redirectUrl = config.url.logoutRedirectUrl
                                } else {
                                    redirectUrl = "http://localhost:3000/#/logout"
                                }
                                window.location.href = `${config.url.logoutAuthSystem}?redirectUrl=${redirectUrl}`
                            })
                        }, 500)
                    } else {
                        if (this.props.newPage) {
                            props.history.push(this.props.newPage)
                        }
                    }
                }}>
                    <span style={{ padding: '9px' }}>{props.intl.get("Do not save")}</span>
                </div>
                <div className='centerWrapper' onClick={() => {
                    //关闭弹窗
                    props.leaveCreatePage(false) //important ************* reset state
                }}>
                    <span>{props.intl.get("Cancel")}</span>
                </div>
                <div className='centerWrapper' onClick={() => {
                    props.onSaveStory()
                    //主动保存数据
                    props.leaveCreatePage(false) //important ************* reset state

                }}>
                    <span style={{ color: '#F28B30' }}>{props.intl.get("Save")}</span>
                </div>
            </div >);
        }

        return (
            <div className='sPannelDiv'>
                <Icon type=' ' className='saveIcon' />
                <div>
                    <span className='boldTxt'>{intl.get("SaveHint")}</span>
                    <br />
                    <span className='txt'>{intl.get("If not")}</span>
                    <FooterView {...this.props} onSaveStory={this.saveStory} />
                </div>
            </div>
        )
    }
}