import { connect } from 'react-redux';
import { getUserInfo, isLeftCollapsed, isOpenLeftFloatMenue, isMobilephoneEnd, isWindowUpScroll, userEnd, layoutType, isLogIn, getCardNumber, isHiddenCommentStoryView, OperateRelatedId, lastPage, isShareStory } from '@/selector/user';
import { fileName,real_filename, schema, relations, selectedFactIndex, storyParameter, data, resultCoverage, aggregationLevel, facts, rewardWeight, title, showSuggestion } from '@/selector/story';
import { visContainerSize } from '@/selector/vis';
import * as userAction from '@/action/userAction';
import * as visAction from '@/action/visAction';
import * as dataAction from '../../action/dataAction';
import * as factAction from '../../action/factAction';
import * as storyAction from '../../action/storyAction';
import routeMapping from './routeMapping';
import { datares, dataresLocal, filters, filtersLocal, filtersTitle, filtersTitleLocal, schemaFilter, schemaFilterLocal, subtitle } from '../../selector/story';

const mapStateToProps = (state) => ({
    //selectedMenue: selectedMenue(state),
    isLeftCollapsed: isLeftCollapsed(state),
    isOpenLeftFloatMenue: isOpenLeftFloatMenue(state),
    isLogIn: isLogIn(state),
    userInfo: getUserInfo(state),
    facts: facts(state),
    relations: relations(state),
    data: data(state),
    showSuggestion: showSuggestion(state),
    title: title(state),
    resultCoverage: resultCoverage(state),
    fileName: fileName(state),
    real_filename:real_filename(state),
    isMobilephoneEnd: isMobilephoneEnd(state),
    isWindowUpScroll: isWindowUpScroll(state),
    userEnd: userEnd(state),
    schema: schema(state),
    selectedFactIndex: selectedFactIndex(state),
    storyParameter: storyParameter(state),
    rewardWeight: rewardWeight(state),
    aggregationLevel: aggregationLevel(state),
    layoutType: layoutType(state),
    visContainerSize: visContainerSize(state),
    getCardNumber: getCardNumber(state),
    isHiddenCommentStoryView: isHiddenCommentStoryView(state),
    OperateRelatedId: OperateRelatedId(state),
    lastPage: lastPage(state),
    isShareStory: isShareStory(state),
    filters:filters(state),
    filtersTitle:filtersTitle(state),
    datares:datares(state),
    schemaFilter:schemaFilter(state),
    filtersLocal:filtersLocal(state),
    filtersTitleLocal:filtersTitleLocal(state),
    dataresLocal:dataresLocal(state),
    schemaFilterLocal:schemaFilterLocal(state),
    subtitle:subtitle(state)
})

const mapDispatchToProps = dispatch => {
    return {
        //user
        updateOperateRelatedId: ({ did, sid }) => dispatch(userAction.updateOperateRelatedId({ did, sid })),
        updateStoryCardNumber: (cardNumber) => dispatch(userAction.updateStoryCardNumber(cardNumber)),
        updateUserEnd: (userEndSize) => dispatch(userAction.updateUserEnd(userEndSize)),
        windowUpScroller: (isWindowUpScroll) => dispatch(userAction.windowUpScroller(isWindowUpScroll)),
        openLeftFloatMenue: (isOpenLeftFloatMenue) => dispatch(userAction.openLeftFloatMenue(isOpenLeftFloatMenue)),
        setLeftCollapsed: (isLeftCollapsed) => dispatch(userAction.setLeftCollapsed(isLeftCollapsed)),
        isChangeToggle: (isChangeToggle) => dispatch(userAction.updateHeaderbarToggle(isChangeToggle)),
        changeLayOutType: (layoutType) => dispatch(userAction.changeLayOutType(layoutType)),
        changeUserEnd: (isMobilephoneEnd) => dispatch(userAction.changeUserEnd(isMobilephoneEnd)),
        updateLastPage: (lastPage) => dispatch(userAction.updateLastPage(lastPage)),
        shareStory: (isShareStory) => dispatch(userAction.shareStory(isShareStory)),
        //comment
        hiddenCommentAStory: (isHidden) => dispatch(userAction.isHiddenCommentAStory(isHidden)),
        //data
        visualizeData: (data) => dispatch(dataAction.visualizeData(data)),
        changeData: (fileName, real_filename) => dispatch(dataAction.changeData(fileName, real_filename)),
        uploadData: (fileName, real_filename, schema, data) => dispatch(dataAction.uploadData(fileName, real_filename, schema, data)),
        connectData: (fileName, real_filename, schema, data) => dispatch(dataAction.connectData(fileName, real_filename, schema, data)),
        //vis
        updateVisContainerSize: (containerSize) => dispatch(visAction.updateVisContainerSize(containerSize)),
        //fact
        addFact: (facts) => dispatch(factAction.addFact(facts)),
        updateFact: (index, fact) => dispatch(factAction.updateFact(index, fact)),
        deleteFact: (index) => dispatch(factAction.deleteFact(index)),
        orderFacts: (sourceIndex, destinationIndex) => dispatch(factAction.orderFacts(sourceIndex, destinationIndex)),
        //story
        selectFact: (index) => dispatch(storyAction.selectFact(index)),
        generateStory: (facts, relations, coverage) => dispatch(storyAction.generateStory(facts, relations, coverage)),
        changeMethod: (method) => dispatch(storyAction.changeMethod(method)),
        setStoryParameter: (maxStoryLength, information, chartDiversity, timeLimit) => dispatch(storyAction.setStoryParameter(maxStoryLength, information, chartDiversity, timeLimit)),
        setRewardWeight: (logicality, diversity, integrity) => dispatch(storyAction.setRewardWeight(logicality, diversity, integrity)),
        setAggregationLevel: (level) => dispatch(storyAction.setAggregationLevel(level)),
        changeTitle: (title) => dispatch(storyAction.changeTitle(title)),
        updateCommentStoryInfo: (facts, relations, data, schema, title, fileName, real_filename, storyParameter, aggregationLevel, resultCoverage, showSuggestion,subtitle,dataresLocal,schemaFilterLocal,filtersLocal,filtersTitleLocal ) => dispatch(storyAction.updateCommentStoryInfo(facts, relations, data, schema, title, fileName, real_filename, storyParameter, aggregationLevel, resultCoverage, showSuggestion,subtitle,dataresLocal,schemaFilterLocal,filtersLocal,filtersTitleLocal )),
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(routeMapping);
