import { connect } from 'react-redux';
import { selectedFactIndex, showSuggestion } from '@/selector/story';
import { layoutType, isMobilephoneEnd, userEnd } from '@/selector/user';
import { visContainerSize } from '@/selector/vis';
import StoryView from './StoryView';
import * as visAction from '@/action/visAction';
import * as storyAction from '../../action/storyAction';
import * as factAction from '../../action/factAction';
import * as userAction from '@/action/userAction';

const mapStateToProps = (state) => ({
    selectedFactIndex: selectedFactIndex(state),
    // storyParameter: storyParameter(state),
    // resultCoverage: resultCoverage(state),
    // aggregationLevel: aggregationLevel(state),
    layoutType: layoutType(state),
    isMobilephoneEnd: isMobilephoneEnd(state),
    userEnd: userEnd(state),
    visContainerSize: visContainerSize(state),
    showSuggestion: showSuggestion(state)
})

const mapDispatchToProps = dispatch => {
    return {
        updateUserEnd: (userEndSize) => dispatch(userAction.updateUserEnd(userEndSize)),
        updateFact: (index, fact) => dispatch(factAction.updateFact(index, fact)),
        selectFact: (index) => dispatch(storyAction.selectFact(index)),
        changeTitle: (title) => dispatch(storyAction.changeTitle(title)),
        changeLayOutType: (layoutType) => dispatch(userAction.changeLayOutType(layoutType)),
        changeUserEnd: (isMobilephoneEnd) => dispatch(userAction.changeUserEnd(isMobilephoneEnd)),
        updateVisContainerSize: (containerSize) => dispatch(visAction.updateVisContainerSize(containerSize)),
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(StoryView);
