import { createSelector } from 'reselect';
import Cookies from 'js-cookie';
import getUserEndSize from '@/tool/getUserEndSize';
import Menues from '@/constant/Menues';
// user
export const isShareStory = state => state.user.isShareStory;
export const isShowGenerate = state => state.user.isShowGenerate;
export const newPage = state => state.user.newPage;
export const isToLogout = state => state.user.isToLogout;
export const isLeavingCreatePage = state => state.user.isLeaveCreatePage;
export const isInterruptProcess = state => state.user.isInterruptProcess;

export const lastPage = state => {
    return state.user.lastPage
}
export const OperateRelatedId = state => {
    return state.user.OperateRelatedId
}
export const selectedMenue = state => {
    return state.user.clickedMenue
}

export const getUserInfo = state => {
    return Cookies.get("userInfo") && JSON.parse(Cookies.get("userInfo")) ? JSON.parse(Cookies.get("userInfo")) : {
        username: "",
        uid: -1
    }
}

export const isCollapsed = state => {
    return state.user.isCollapsed
}

export const isLeftCollapsed = state => {
    return state.user.isLeftCollapsed
}
export const isNewHeaderbarToggle = state => {
    return state.user.updateHeaderbarToggle
}
export const isOpenLeftFloatMenue = state => {
    return state.user.isOpenLeftFloatMenue
}
export const layoutType = state => {
    return state.user.layoutType
}
export const isMobilephoneEnd = state => {
    return state.user.isMobilephoneEnd
}

export const isWindowUpScroll = state => {
    return state.user.isWindowUpScroll
}
export const userEndSize = state => {
    return state.user.userEndSize
}


export const getCardNumber = state => {
    return state.user.cardNumber
}

export const isHiddenCommentStoryView = state => {
    return state.user.isHiddenCommentAStory
}

export const userEnd = createSelector(
    userEndSize,
    function (userEndSize) {
        //console.log("userEnd",userEndSize,getUserEndSize(userEndSize))
        return getUserEndSize(userEndSize)
    })

export const isLogIn = createSelector(
    getUserInfo,
    function (userInfo) {
        if (userInfo && userInfo.uid === -1) {
            return false
        }
        return true
    }
)

export const menues = createSelector(
    getUserInfo,
    function (userInfo) {
        if (userInfo && userInfo.uid === -1) {
            return Menues.logoutMenue
        }
        return Menues.logInMenue
    }
)


export const factsheetLayoutType = state => state.user.factsheetLayoutType