import * as d3 from 'd3';

const draw = (props) => {

    // console.log('props.info', props);
    var requestData = props.info;
    var requestDataValues = props.info.values.map(d => d.replace(',', '')); //去除数据中的","" 比如"1,385.66"
    // var requestData = props.filters[props.filterIndex].value;
    // console.log('requestData', requestData)
    var data = [];

    var map = new Map();

    map.clear();

    var arr = requestData.values.sort(function (a, b) {
        return parseFloat(a) - parseFloat(b);
    });
    arr = arr.map((value) => {
        return parseFloat(value);
    })
    var range = parseFloat((Math.max(...arr) - Math.min(...arr)) / 20);


    for (let i = 0; i < 20; i++) {
        map.set(i, 0);
    }

    for (let i = 0; i < arr.length - 1; i++) {
        var setname = parseInt((arr[i] - Math.min(...arr)) / range);
        map.set(setname, map.get(setname) + 1);
    }
    map.set(19, map.get(19) + 1);


    for (let [key, value] of map) {
        var datum = {};
        // datum.index = i;
        datum.index = key;
        datum.value = value;
        data.push(datum);
    }

    // console.log('data',data);

    // var margin = {top: 20, right: 10, bottom: 20, left: 40},
    var margin = { top: 0, right: 0, bottom: 0, left: 0 },
        width = 196 - margin.left - margin.right,
        height = 36 - margin.top - margin.bottom;


    var x = d3.scaleBand().range([0, width]),
        //([0, width], .1),
        y = d3.scaleLinear().range([height, 0]);

    // var xAxis2 = d3.svg.axis().scale(x).orient("bottom").tickValues([]);
    x.domain(data.map(function (d) { return d.index }));
    y.domain([0, Math.max(...map.values())]);

 

    var brush = d3.brushX()
        //  .x(x) 
        .extent([[0, 0], [width, height]])
        .on("brush", brushed);


    var svg = d3.select('#' + props.id).append("svg")
        .attr("width", width + margin.left + margin.right)
        .attr("height", height + margin.top + margin.bottom)
      //  .attr("transform", "translate(" + margin.left + "," + margin.top + ")");


    svg.append("rect")
        .attr("width", width)
        .attr("height", height)
        .attr("x", "0")
        .attr("y", "0")
        .attr("fill", "#f0f0f0")




    var context = svg.append("g")
        .attr("class", "context")
     //   .attr("transform", "translate(" + margin.left + "," + margin.top + ")");

    updateScale(data, [0, width]);

    var subBars = context.selectAll('.subBar')
        .data(data)

    subBars.enter().append("rect")
        .classed('subBar', true)
        .attr("height", (d) => height - y(d.value))
        .attr("width", (d) => x.bandwidth())
        .attr("x", (d) => x(d.index))
        .attr("y", (d) => y(d.value))
        .attr("fill", "#777");
    // .attr(
    // {
    // height: function (d)
    // {
    //     console.log('ddd', height);
    //     console.log('ddd', y(d.value))
    //     return height - y(d.value);
    // },
    // width: function(d){ return x.rangeBand()},
    // x: function(d) {

    //     return x(d.index);
    // },
    // y: function(d)
    // {
    //     return y(d.value)
    // }
    // })
    context.append("g")
        .attr("class", "x brush")
        .call(brush)
        .selectAll("rect")
        .attr("y", 0)
        .attr("width", width)
        .attr("height", height);


    let brushInitStart = props.filters[props.filterIndex].brushLocation == undefined ? 0 : props.filters[props.filterIndex].brushLocation[0];// eslint-disable-line 
    let brushInitEnd = props.filters[props.filterIndex].brushLocation == undefined ? 196 : props.filters[props.filterIndex].brushLocation[1];// eslint-disable-line 

    if(brushInitStart === 0 && brushInitEnd === 196) {

    }else {
        context.append("rect")
            .attr("class", "xBrushInit")
            .call(brush)
            // .selectAll("rect")
            .attr("x", brushInitStart)
            .attr("y", 0)
            .attr("width", brushInitEnd - brushInitStart)
            .attr("height", height);
    }
    


    


    function brushed() {
        d3.select('.xBrushInit').remove();
        if (!d3.event.selection) return;
        if (d3.event.selection === null) d3.event.selection = [0, width];
        var selected = null;
        selected = x.domain()
            .filter(function (d) {
                return (d3.event.selection[0] <= x(d)) && (x(d) <= d3.event.selection[1]);
            });
        if(selected.length === 0) {
            selected = x.domain()
            .filter(function (d) {
                return (d3.event.selection[0] <= x(d));
            });
        }
        
        
        var start;
        var end;


        if (d3.event.selection[0] !== d3.event.selection[1]) {
            if(selected.length === 1) {
                start = selected[0];
                end = selected[0] + 1;
                
            } else {
                start = selected[0];
                end = selected[selected.length - 1];
            }
            
        } else {
            start = 0;
            end = data.length;
        }

        // var updatedData = data.slice(start, end);
        
        // var responseDate = [];
        // for(var i=0;i<updatedData.length;i++){
        //     responseDate.push(updatedData[i].index*range + Math.min(...arr));
        // }
        var maxValue = (end  + 1)* range + Math.min(...arr);
        var minValue = (start === 0 ? 0 : start - 1) * range + Math.min(...arr);
        var newData = requestDataValues.filter(d => d >= minValue && d <= maxValue)


        d3.selectAll('#' + props.id + 'minValue').remove();
        d3.selectAll('#' + props.id + 'maxValue').remove();

        let temporalTextDiv = d3.select('#' + props.id).append("div").attr("class", "textpostion");

  //  d3.select('#' + props.id).append("div").attr("class", "textpostion")
        temporalTextDiv
            .append("span")
            .style("position", "relative")
            .style("bottom", "60px")
            .style("font-size", "9px")
            .style("float", "left")
            .text(minValue.toFixed(2))
            .attr('id', props.id + 'minValue')

        // .transfrom('');

        temporalTextDiv
            .append("span")
            .style("position", "relative")
            .style("bottom", "60px")
            .style("font-size", "9px")
            .style("float", "right")
            .text(maxValue.toFixed(2))
            .attr('id', props.id + 'maxValue')

        var filters = props.filters;
        // d3.select('text').remove();

        // d3.select('.topicDetails').append("text")
        // .text(minValue);
        filters[props.filterIndex].value = newData;
        filters[props.filterIndex].type = 'equal';

        filters[props.filterIndex].value1 = [minValue, maxValue];
        filters[props.filterIndex].brushLocation = [d3.event.selection[0], d3.event.selection[1]];


        // props.updateFilters(filters);
        props.updateFiltersChangeFlag(true); 

        // updateScale(updatedData, d3.event.selection)


    }

    let temporalTextDiv = d3.select('#' + props.id).append("div").attr("class", "textpostion");



    let maxValue = props.filters[props.filterIndex].value1 == undefined ? Math.max(...requestDataValues) : props.filters[props.filterIndex].value1[1];// eslint-disable-line 
    let minValue = props.filters[props.filterIndex].value1 == undefined ? Math.min(...requestDataValues) : props.filters[props.filterIndex].value1[0];// eslint-disable-line 

    temporalTextDiv
        .append("span")
        .style("position", "relative")
        .style("bottom", "60px")
        .style("font-size", "9px")
        .style("float", "left")
        .text(minValue.toFixed(2))
        .attr('id', props.id + 'minValue')

    // .transfrom('');

    temporalTextDiv
        .append("span")
        .style("position", "relative")
        .style("bottom", "60px")
        .style("font-size", "9px")
        .style("float", "right")
        .text(maxValue.toFixed(2))
        .attr('id', props.id + 'maxValue')

    function updateScale(data, selection) {
        // var tickScale = d3.scalePow().range([data.length / 10, 0]).domain([data.length, 0]).exponent(.5)
        // console.log('UPDATEd3.event',selection);
        var brushValue = selection[1] - selection[0];
        if (brushValue === 0) {
            brushValue = width;
        }

        // var tickValueMultiplier = Math.ceil(Math.abs(tickScale(brushValue)));
        // var filteredTickValues = data.filter(function (d, i) { return i % tickValueMultiplier === 0 }).map(function (d) { return d.index })

    }


    return svg;
}
export default draw;

