import React, { Component } from "react";
import { Rate, Spin, Button } from 'antd';
import CardItemView from './CardItem/index';
import { fact2chart } from '@/tool/fact2vis';
import './RelatedStoryList.less';
let playTimer = 0;
let moment = require('moment');

export default class RelatedStoryList extends Component {
    constructor(props) {
        super(props);
        this.state = {
            data: this.props.stories,
        }
    }

    componentWillReceiveProps(newProps) {
        if (newProps.stories !== this.state.data) {
            this.setState({
                data: newProps.stories
            })
        }
    }
    componentDidMount() {
        if (this.mobileVisNode) {
            let vis = this.mobileVisNode.children[0];
            if (vis)
                vis.style.transform = "translate(-30px,0px) scale(0.7)";
        }
    }
    componentDidUpdate() {
        if (this.mobileVisNode) {
            let vis = this.mobileVisNode.children[0];
            if (vis)
                vis.style.transform = "translate(-30px,0px) scale(0.7)";
        }
    }
    click = (story) => {
        const { facts, relations, data, schema, storyParameter, aggregationLevel, resultCoverage, fileName,real_filename, showSuggestion,subtitle,dataresLocal,schemaFilterLocal,filtersLocal,filtersTitleLocal } = story
        this.props.updateCommentStoryInfo(facts, relations, data, schema, story.title, fileName,real_filename, storyParameter, aggregationLevel, resultCoverage, showSuggestion,subtitle,dataresLocal,schemaFilterLocal,filtersLocal,filtersTitleLocal )
        this.props.updateOperateRelatedId({ ...this.props.OperateRelatedId, sid: story.sid })
        this.props.onViewARelatedStoryListener(story)
        clearInterval(playTimer)
    }
    onLoadMore = () => {
        this.props.onLoadMore()
    }
    render() {
        const { isMobilephoneEnd, loadingRelatedStory, isLoadedAll, intl } = this.props;

        const StoryInfo = ({ story }) => (
            <div className='rightInfo'>
                <span> {story.user && story.user.username}</span>
                <span>{typeof story.num_of_rates !== 'undefined' ? story.num_of_rates : '--'} rates,{typeof story.num_of_shares !== 'undefined' ? story.num_of_shares : '--'} shares</span>
                <span>{moment(story.time).format('MMM.Mo,YYYY')}</span>
                {
                    (story && story.rating) ?
                        <Rate disabled
                            //defaultValue={parseInt(story.rating)}
                            defaultValue={(story && parseInt(story.rating.toFixed(1).split('.')[1]) > 5) ?
                                parseInt(`${story.rating.toFixed(1).split('.')[0]}`) + 1
                                : parseInt(story && story.rating.toFixed(1).split('.')[1]) === 0 ? parseInt(story && story.rating.toFixed(1)) : parseFloat(`${story && story.rating.toFixed(1).split('.')[0]}.5`)}
                            style={{ fontSize: '8px', color: '#F28B30', marginTop: '4px' }} allowHalf />
                        :
                        <Rate disabled defaultValue={0} style={{ fontSize: '8px', color: '#F28B30', marginTop: '4px' }} />

                }
            </div>
        )

        return (
            <div className="relatedStorys-container">
                {
                    this.state.data.map((story, index) => {
                        return isMobilephoneEnd
                            ?
                            <div className='mobilephoneCardDiv' key={"relatedStorys_" + index}
                                onClick={() => this.click(story)} >
                                <div className='relatedChart' ref={(node) => { this.mobileVisNode = node }} >
                                    {
                                        fact2chart({ schema: story.schemaFilterLocal?story.schemaFilterLocal:story.schema, showSuggestion: story.showSuggestion }, story.events[0].id, story.events[0].fact,story.dataresLocal?story.dataresLocal:story.data, "small", false)
                                    }
                                </div>
                                <div style={{ flex: 1, padding: "0px 11px", transform: "translate(-50px, 0px)" }}>
                                    <div className='storyName'>
                                        {story.title}
                                    </div>
                                    <div className='line'></div>
                                    <StoryInfo story={story} />
                                </div>
                            </div>
                            :
                            <CardItemView key={"relatedStorys_" + index} story={story} intl={intl} onViewARelatedStoryListener={this.props.onViewARelatedStoryListener} />
                    })
                }
                {loadingRelatedStory && (
                    <div className="loading-container">
                        <Spin tip={intl.get("Loading")} />
                    </div>
                )}
                {isLoadedAll ?
                    <span style={{ margin: '0 auto', color: 'grey' }}> {intl.get("no more data")}</span>
                    :
                    <Button onClick={this.onLoadMore} style={{ width: "100%" }}>{intl.get("load more")} </Button>
                }
            </div>
        )
    }
}