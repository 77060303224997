import React, { Component } from 'react';
import { Icon, Avatar } from 'antd';
import HorizontalMenuView from './HorizontalMenuView';
import Cookies from 'js-cookie';
import Menues from '@/constant/Menues';
import LocaleConfigView from './LocaleConfig/LocaleConfigView'
import config from '@/axios/config';
import * as api from '@/axios/api';
import './HeadBarView.less';
import '@/pages/common.less';

export default class HeadBarView extends Component {
    constructor(props) {
        super(props)
        this.state = {
            userEnd: this.props.userEnd
        }
    }

    openLeftFloatMenue = () => {
        this.props.openLeftFloatMenue(!this.props.isOpenLeftFloatMenue);
    };

    toggleCollapsed = () => {
        this.props.setLeftCollapsed(!this.props.isLeftCollapsed);
    };
    componentWillReceiveProps(newProps) {
        if (newProps.userEnd !== this.state.userEnd) {
            if (this.props.isOpenLeftFloatMenue && newProps.userEnd.startsWith('browser') && !this.props.isLoadedInIndexPage) {
                this.props.openLeftFloatMenue(false)
            }
            this.setState({
                userEnd: newProps.userEnd
            })
        }
    }
    userInfo = () => {
        window.open(config.url.authenUrl, '_blank')
    }
    LogIn = () => {
        if (process.env.NODE_ENV === 'production') {
            window.location.href = config.url.loginRedirectUrl
        } else {
            //登陆
            api.loginadmintest().then(() => {
                //跳转到/检查token
                let expireDay = 7
                Cookies.set("userInfo", Menues.mockUserInfo, {
                    expires: new Date(new Date().getTime() + expireDay * 24 * 60 * 60 * 1000),
                })
                //redirect 
                this.props.history.push('/')
            }, () => {
            })
        }
    }
    LogOut = () => {
        const { isShouldSave } = this.props
        let pathname = this.props.history.location.pathname
        if (isShouldSave && (pathname === "/index/story/create" || pathname.startsWith("/index/story/edit"))) {
            this.props.leaveCreatePage(true) //为了显示保存框
            this.props.isClickedLogout(true) //为了当用户点击保存后，可以执行退出登陆的逻辑
            if (pathname.startsWith("/index/story/edit")) {
                //console.log("LogOut...")
                this.props.updateLastPage("/index/explore/stories")
            }
            return
        }
        this.props.visualizeData({})
        api.logout().then((response) => {
            let redirectUrl
            if (process.env.NODE_ENV === 'production') {
                redirectUrl = config.url.logoutRedirectUrl
            } else {
                redirectUrl = "http://localhost:3000/#/logout"
            }
            window.location.href = `${config.url.logoutAuthSystem}?redirectUrl=${redirectUrl}`
        })
    }
    SignUp = () => {
        return;
    }

    back = () => {
        const { isShouldSave } = this.props
        if (isShouldSave && (this.props.history.location.pathname === "/index/story/create" || this.props.history.location.pathname.startsWith("/index/story/edit"))) {
            this.props.leaveCreatePage(true)
            this.props.toNewPage("/homePage")
            return
        }
        this.props.history.push("/homePage");
        this.props.updateLastPage("/homePage")
    }
    render() {
        const { userInfo, isLogIn, userEnd, isLoadedInIndexPage, isNewHeaderbarToggle, intl } = this.props;
        return (
            <div className='headerBarWrapper'>
                <div className='headerBarLeft'>
                    {
                        isLoadedInIndexPage ?
                            isNewHeaderbarToggle ?
                                <Icon onClick={() => this.openLeftFloatMenue()} className='toggleWrapper' type=' '></Icon>
                                :
                                <Icon onClick={() => this.toggleCollapsed()} className='toggleWrapper' type=' '></Icon>
                            :
                            userEnd.startsWith('i') ? <Icon onClick={() => this.openLeftFloatMenue()} className='toggleWrapper' type=' '></Icon> : null
                    }
                    <div className='logo' onClick={() => this.back()}></div>
                    <div className='calliopeTxt rowDiv'>Calliope・Data</div>
                </div>
                <div className='horizontalMenu'>
                    {
                        (!isLoadedInIndexPage && !userEnd.startsWith('i'))
                            ?
                            <HorizontalMenuView {...this.props} />
                            :
                            null
                    }
                </div>
                <div className='headerBarRight'>
                    <LocaleConfigView {...this.props} />
                    {
                        isLogIn ?
                            <Avatar className='AvatarImg'
                                onClick={this.userInfo}
                                src={`${config.url.userImage}/${userInfo.avatar}`}
                            >{userInfo && userInfo.username.substr(0, 1)}</Avatar>
                            :
                            <Icon className='userimg' type=' '></Icon>
                    }
                    {
                        isLogIn ?
                            <div className='userName' onClick={this.userInfo} >{userInfo && userInfo.username}</div>
                            :
                            <div className='LogInBt' onClick={this.LogIn} >{intl.get("logIn")}</div>
                    }
                    {
                        isLogIn ?
                            <div className='Logout' onClick={this.LogOut}>{intl.get("logOut")}</div>
                            :
                            null
                    }
                </div>

            </div>
        )
    }
}