import React, { Component } from 'react';
import { fact2chart } from '../../../tool/fact2vis';
import './eventview.css';
import { isValid } from '../../FactView/helper'
import _ from 'lodash';

export default class EventView extends Component {

    clickFact = () => {
        const { selectFact, index } = this.props;
        selectFact(index);
    }
    shouldComponentUpdate(nextProps, nextState) {
        let { fact, size, totalLen } = this.props;
        let nextFact = nextProps.fact;// this.forceUpdate()
        return !_.isEqual(fact, nextFact) || size !== nextProps.size || totalLen !== nextProps.totalLen;
    }
    render() {
        //let {uuid, fact, data, size, totalLen, outerHeight, remainHeight, schema} = this.props;
        let { uuid, fact, data,size, schema, showSuggestion,totalLen } = this.props;
        let schemaFilter=_.cloneDeep(this.props.schemaFilterLocal);
        let datares 
        if(this.props.dataresLocal&&this.props.dataresLocal!==[]){    
            datares=_.cloneDeep(this.props.dataresLocal);
        }
        else datares = data
        let specData 
        if (schemaFilter&&schemaFilter!==[]){
            specData= {
                "schema": schemaFilter,
                "showSuggestion": showSuggestion
            };
        }
        else{
            specData= {
                "schema": schema,
                "showSuggestion": showSuggestion
            };
        }
        let vis;
        let tarnsformstyle;
        // let width, height;
        let chartSize = 'small';

        switch (size) {
            case '2p3':// 每行三个
                // width = outerHeight >= 235 ? 230 : 150;
                // // height = totalLen===5 ? 150: (outerHeight-100) //160;
                // height = totalLen>=4 ? outerHeight * 0.9: outerHeight * 0.6;
                chartSize = 'small';
                if (totalLen === 4) tarnsformstyle = 'translate(-50px,10px) scale(0.6)'
                else if (totalLen === 2) {
                    if(this.props.factsheetLayoutType==="Vertical")
                    tarnsformstyle = 'translate(0px,-80px)'
                }
                else if (totalLen === 1) {
                    if(this.props.factsheetLayoutType==="Horizontal")
                    tarnsformstyle = 'translate(0px, -230px) scale(1.5)'
                }
                
                else if (totalLen === 5) tarnsformstyle = 'translate(-50px,10px) scale(0.55)'
                else tarnsformstyle = ''
                break;
            case '2p':
                // width = outerHeight >= 235 ? 230 : 150;
                // // height = totalLen===5 ? 150 : (outerHeight-100)//160;
                // height = totalLen>=4 ? outerHeight * 0.9: outerHeight * 0.6;
                chartSize = 'small';
                break;
            case '3p':
                // width = outerHeight >= 235 ? 320 : 180;
                // // height = totalLen===5 ? 150 : (outerHeight-100)//160;
                // // height = totalLen>=4 ? outerHeight * 0.9: outerHeight - 120
                // height = outerHeight > 235 ? outerHeight * 0.6 : outerHeight * 0.9;
                chartSize = 'middle';
                if (totalLen === 4) {
                    tarnsformstyle = 'translate(-40px,20px) scale(0.7)'
                }
                else if (totalLen === 2) {
                    if(this.props.factsheetLayoutType==="Vertical")
                    tarnsformstyle = 'translate(0px,-70px)'
                }
                else if (totalLen === 1) {
                    if(this.props.factsheetLayoutType==="Horizontal")
                    tarnsformstyle = 'translate(0px, -220px) scale(1.5)'
                    else
                    tarnsformstyle = 'translate(0px, -350px)'
                }
                else if (totalLen === 5) tarnsformstyle = 'translate(-50px,0px) scale(0.7)'
                else tarnsformstyle = ''
                break

            case '4p':
                // width = 400;
                chartSize = 'wide';
                // // height = totalLen===5 ? 150 : (outerHeight-80);
                // height = outerHeight > 200 ? outerHeight*0.6 : outerHeight *0.6
                // if(fact.chart ==='Color Filling Map') {
                //     width = 280;
                //     height = outerHeight * 0.85
                // }
                
                break
            case '6p':
                //width = 500;
                chartSize = 'wide';
                if (fact.chart === 'Color Filling Map') {
                    // width = 350;
                    // height = outerHeight * 0.85
                    chartSize = 'middle';
                }

                else if (fact.chart === 'Vertical Bar Chart' || fact.chart === 'Isotype Bar Chart' || fact.chart === 'Line Chart') {
                    // width = 600;
                    // height = outerHeight * 0.85
                    chartSize = 'wide';
                } else chartSize = 'wide';

                if (totalLen === 4) {
                    if (fact.chart === 'Color Filling Map') {
                        tarnsformstyle ='translate(0px,0px) scale(1)'
                    }
                    else tarnsformstyle ='translate(0px,-45px) scale(0.6)'
                }
                else if(totalLen===2){
                    if(this.props.factsheetLayoutType==="Vertical")
                    tarnsformstyle ='translate(0px, -60px) scale(1.2)'
                }
                else if(totalLen===1){
                    if(this.props.factsheetLayoutType==="Horizontal")
                    tarnsformstyle ='translate(0px, -160px) scale(1.8)'
                    else
                    tarnsformstyle ='translate(0px, -300px) scale(1.2)'
                }
                
                else if (totalLen === 5) {
                    if(fact.chart === 'Treemap') tarnsformstyle = 'translate(0px,-70px) scale(0.45)'
                    else if (fact.chart === 'Color Filling Map') {
                        tarnsformstyle ='translate(0px,0px) scale(0.7)'
                    }
                    else tarnsformstyle= 'translate(0px,-55px) scale(0.55)'
                }
                else tarnsformstyle = ''

                break
            default:
                break;
        }
        // height = remainHeight>0 ? remainHeight : height
        if (fact.aggregated && fact.aggregatedFact) {
            let vis = fact2chart(specData, uuid, fact, datares, chartSize, true,this.props.oldStory);
            return (
                // <div className="chart-preview">
                <div
                // style={{ marginLeft: 6 }}
                >
                    {vis}
                </div>
                // </div>
            )
        } else {
            if (isValid(this.props.fact)) {
                vis = fact2chart(specData, uuid, fact, datares, chartSize,false,this.props.oldStory);
            }
            return (
                <div style={{ marginLeft: 0,transform: `${tarnsformstyle}` }}>
                    {vis}
                </div>
            )
        }



    }
}
