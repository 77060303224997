import React, { Component } from "react";
import { Spin, message } from 'antd';
import Cookies from 'js-cookie';
//import config from '@/axios/config';
import * as api from '@/axios/api';
//import _ from 'lodash';
import './LoginLoading.less';

export default class LoginLoading extends Component {
    componentWillMount() {
        let redirectUrl = window.location.href
        if (redirectUrl.indexOf('?code=') !== -1) {
            let code = redirectUrl.split("code=")[1].slice(0, 6);
            let body = {
                code
            }
            let isDevToken = false
            if (isDevToken) {
                //查询登陆前的页面路径
                if (this.props.lastPage) {
                    this.props.history.push(this.props.lastPage);
                } else {
                    this.props.history.push('/homePage');
                }
            } else {
                api.accessToken(body).then((response) => {
                    //console.log("accessToken response", response)
                    if (response.success) {
                        let expireDay = 7
                        if (response.user && typeof (response.user) !== 'undefined') {
                            //console.log("Cookies.set", response.user)
                            Cookies.set("userInfo", response.user, {
                                expires: new Date(new Date().getTime() + expireDay * 24 * 60 * 60 * 1000),
                                //domain: "idvxlab.com"
                            })
                        }
                        //删除掉?code=xxxxxx,避免用户后退网站，code失效问题
                        let urlWithCode = window.location.href
                        let deleteString = "?code=" + code
                        let newUrl = urlWithCode.split(deleteString).join('')
                        //console.log("accessToken success", newUrl)
                        window.location.href = newUrl
                    } else {
                        message.info(this.props.intl.get("Login fail"))
                    }
                    //查询登陆前的页面路径
                    if (this.props.lastPage && this.props.lastPage !== '/') {
                        this.props.history.push(this.props.lastPage);
                    } else {
                        this.props.history.push('/homePage');
                    }
                })
            }
            return
        }

        api.checkToken().then(resolve => {
            if (resolve.success) {
            } else {
                if (process.env.NODE_ENV === 'production') {
                    //Cookies.remove("userInfo")
                }
            }
            //查询登陆前的页面路径
            if (this.props.lastPage && this.props.lastPage !== '/') {
                this.props.history.push(this.props.lastPage);
            } else {
                this.props.history.push('/homePage');
            }
        }, reject => {
            if (process.env.NODE_ENV === 'production') {
                //Cookies.remove("userInfo")
            }
            //查询登陆前的页面路径
            if (this.props.lastPage && this.props.lastPage !== '/') {
                this.props.history.push(this.props.lastPage);
            } else {
                this.props.history.push('/homePage');
            }
        })

    }
    render() {
        return (
            <div style={{ width: '100%', height: '100%', display: 'flex', justifyContent: 'center', marginTop: "50px" }}>
                <Spin />
            </div>
        )
    }
}