const findKeyframesRule = (animName) => {
    var rule;
    var ss = document.styleSheets;
    for (var i = 0; i < ss.length; ++i) {
        for (var x = 0; x < ss[i].cssRules.length; ++x) {
            rule = ss[i].cssRules[x];
            if (rule.name === animName && (rule.type === CSSRule.KEYFRAMES_RULE || ss[i].cssRules[x].type === CSSRule.WEBKIT_KEYFRAMES_RULE)) {
                return rule;
            }
        }
    }

}
export default findKeyframesRule;