const templates = [
    {
        'id': 0,
        'template': '当{{subspace}}时，根据 {{agg}} {{measure}} 对 {{groupby}}s 进行排名，排名前三的是 {{no.1}}、{{no.2}}、{{no.3}}.',
    },
    {
        'id': 1,
        'template': '当{{subspace}}时， {{no.1}}、 {{no.2}}和{{no.3}}是 {{agg}} {{measure}}取值最高的三个 {{groupby}}s.',
    },
    {
        'id': 2,
        'template': '当{{subspace}}时， {{no.1}}、 {{no.2}}和{{no.3}}在 {{agg}} {{measure}}的取值上排名前三',
    },
]

export default templates;