import React, { Component } from "react";
import { Spin } from 'antd';
import Cookies from 'js-cookie';
import './LogoutLoading.less';

export default class LogoutLoading extends Component {
    componentWillMount() {
        if (this.props.lastPage.startsWith("/index/story/edit")) {
            this.props.history.push("/index/explore/stories");
            return
        }
        if (this.props.lastPage) {
            this.props.history.push(this.props.lastPage);
        } else {
            this.props.history.push('/homePage'); //页面检查token
        }
        //游客身份或者登陆状态过期
        Cookies.remove("userInfo")
    }
    render() {
        return (
            <div style={{ width: '100%', height: '100%', display: 'flex', justifyContent: 'center', marginTop: "50px" }}>
                <Spin />
            </div>
        )
    }
}