import React, { Component } from 'react'
import uuidv4 from 'uuid/v4';
import { TransformWrapper, TransformComponent } from "react-zoom-pan-pinch";
import ReactToPrint from 'react-to-print';
import Draggable from 'react-draggable';
import { Radio, Menu, Dropdown } from 'antd';
import './index.less';
import _ from 'lodash';
import { normalized } from './helper'
import { factDistance } from '../../../tool/distance'
import FactSheet from './FactSheet'

export default class FactSheetView extends Component {
    constructor(props) {
        super(props)

        this.componentRef = React.createRef();
        this.zoomRef = React.createRef();
        let tempHeight = this.props.factsheetLayoutType==="Horizontal"?(this.props.visContainerSize.height - 120):(this.props.visContainerSize.height - 120)
        let tempZoomLevel = this.props.factsheetLayoutType==="Horizontal"?((this.props.visContainerSize.height - 120) / 880):((this.props.visContainerSize.height - 120) / 1166)
        let basicHeight = this.props.factsheetLayoutType==="Horizontal"? 880:1166
        let basicWidth = this.props.factsheetLayoutType==="Horizontal"?  1300:825

        this.state = {
            factsheetLayoutType:this.props.factsheetLayoutType,
            windowWidth: 0,
            containerHeight: tempHeight,
            containerWidth: this.props.visContainerSize.width,
            zoomState: 'ZoomOut',
            zoomLevel:tempZoomLevel,
            cursorType: 0,
            tsKey: uuidv4(),
            basicHeight:basicHeight,
            basicWidth:basicWidth
        }
    }

    UNSAFE_componentWillUpdate(nextProps, nextState) {
        if (nextProps.factsheetLayoutType !== this.state.factsheetLayoutType) {
            let tempHeight = nextProps.factsheetLayoutType==="Horizontal"?(nextProps.visContainerSize.height - 120):(nextProps.visContainerSize.height - 120)
            let tempZoomLevel = nextProps.factsheetLayoutType==="Horizontal"?((nextProps.visContainerSize.height - 120) / 880):((nextProps.visContainerSize.height - 120) / 1166)
            let basicHeight = nextProps.factsheetLayoutType==="Horizontal"? 880:1166 
            let basicWidth = nextProps.factsheetLayoutType==="Horizontal"? 1300: 825
    
            this.setState({
                factsheetLayoutType:nextProps.factsheetLayoutType,
                containerHeight: tempHeight,
                tsKey: uuidv4(),
                zoomLevel: tempZoomLevel,
                basicHeight:basicHeight,
                basicWidth:basicWidth
            })
        }

        if (nextProps.visContainerSize.height - 120 !== this.state.containerHeight) {
            let _h = nextProps.visContainerSize.height - 120
            this.setState({
                containerHeight: _h,
                tsKey: uuidv4(),
                zoomLevel: (nextProps.visContainerSize.height - 120) / this.state.basicHeight,
            })
        }

        if (nextProps.visContainerSize.width !== this.state.containerWidth) {
            let _w = nextProps.visContainerSize.width
            this.setState({
                containerWidth: _w,
                tsKey: uuidv4(),
                zoomLevel: (nextProps.visContainerSize.height - 120) / this.state.basicHeight,
            })
        }
    }

    factDis(prevFact, nextFact) {
        let _prev = prevFact.aggregated && prevFact.aggregatedFact ? [prevFact, prevFact.aggregatedFact] : [prevFact]
        let _next = nextFact.aggregated && nextFact.aggregatedFact ? [nextFact, nextFact.aggregatedFact] : [nextFact]
        let diss = []
        _prev.forEach((p) => {
            _next.forEach((n) => {
                diss.push(factDistance(p, n))
            })
        })
        return _.min(diss)
    }
    calculateVertical_(relations, facts) {
        if (facts.length === 0 || facts[0] === undefined) return
        function addOne(arr, res, final) {
            if (arr.length === 0) {
                final.push(res);
                return
            }
            _.range(1, 4).forEach((i) => {
                let c = arr.slice(0, i);
                if (c.length < i) return
                let temp_arr = arr.slice(i)
                let res_temp = [...res]
                res_temp.push(c)
                addOne(temp_arr, res_temp, final)
            })
        }
        let _res = [], result = [];
        addOne(_.range(0, facts.length), _res, result)
        result = result.filter((r) => {
            return r.length <= _.max([r.length > 2 ? 2 : 1, Math.round(facts.length / 2)])
        })
        let _result = result.filter((r) => {
            return r.length <= 5
        })
        // console.log(_result)
        if (_result.length !== 0) { // 可以塞下5行
            result = _result
        }
        // 取最大score
        let max_score = Math.max.apply(Math, facts.map(f => {
            return f.score;
        }));
        // 处理result的布局排列, 其中result[i]是一种排列方式
        let compare_list = [], num_list = [];
        let area_list = []
        // let results_line = []
        // let results_multi = [];
        result.forEach((_result, index) => {
            let sim_avg_line = [], sim_cross_line = []
            _result.forEach((r, index) => {
                // r是一行
                for (let _i = 1; _i < r.length; _i++) {
                    sim_avg_line.push(this.factDis(facts[r[_i - 1]], facts[r[_i]]))
                    // sim_avg_line.push(factDistance(facts[r[_i-1]], facts[r[_i]]))
                }
                if (index !== _result.length - 1) sim_cross_line.push(factDistance(facts[r[r.length - 1]], facts[r[r.length - 1] + 1]))
            })
            let m_DBI = _result.length === 1 ? 0 : _.mean(sim_cross_line) ? _.mean(sim_cross_line) : 0 - _.mean(sim_avg_line);
            // 处理具体布局
            // let area_temps = [[]];
            let area_temp = [];
            _result.forEach((r) => {

                // r是单行
                if (r.length === 3) {
                    area_temp.push(..._.times(3, _.constant(2)))
                } else if (r.length === 1) {
                    area_temp.push(6)
                } else {
                    let _relation = relations[r[1]]
                    if (_relation === 'generalization') { //1:2
                        area_temp.push(2)
                        area_temp.push(4)
                    } else if (_relation === 'elaboration') {
                        area_temp.push(4)
                        area_temp.push(2)
                    } else {
                        area_temp.push(..._.times(2, _.constant(3)))
                    }
                }

            })
            area_list.push(area_temp)
            // area_temps.forEach((area_temp)=>{
            let s = 0;
            for (let i = 0; i < facts.length; i++) {
                let _score = normalized(facts[i].score, max_score, 0) ? normalized(facts[i].score, max_score, 0) : 0
                s += _score * (area_temp[i] === 2.1 ? 2 : area_temp[i])
            }
            s = s / (_result.length * 6)
            m_DBI = normalized(m_DBI, 1, -1)

            compare_list.push(s + m_DBI)
            num_list.push([s, m_DBI])
        })
        let final_layout = result[_.indexOf(compare_list, _.max(compare_list))]
        let factStyle = {};
        final_layout.forEach((perLine, index) => {
            if (perLine.length === 3) { // 3个并列
                perLine.forEach((factId) => {
                    factStyle[factId] = '2p3'
                })
            } else if (perLine.length === 1) { // 单个
                factStyle[perLine[0]] = '6p'
            } else { //2个并列
                let _relation = relations[perLine[1]]
                if (_relation === 'generalization') { //1:2
                    factStyle[perLine[0]] = '2p'
                    factStyle[perLine[1]] = '4p'
                } else if (_relation === 'elaboration') {
                    factStyle[perLine[0]] = '4p'
                    factStyle[perLine[1]] = '2p'
                } else {
                    factStyle[perLine[0]] = '3p'
                    factStyle[perLine[1]] = '3p'
                }
            }
        })
        let last_in_line5 = -1;
        if (final_layout.length > 5) {
            last_in_line5 = final_layout[4].slice(-1)[0]
        }
        return [factStyle, final_layout.length, last_in_line5]
        // let last_in_line5 = -1;
        // if(results_multi[_.indexOf(compare_list, _.max(compare_list))].length > 5) {
        //     last_in_line5 = results_multi[_.indexOf(compare_list, _.max(compare_list))][4].slice(-1)[0]
        // }
        // return [factStyle, results_multi[_.indexOf(compare_list, _.max(compare_list))].length, last_in_line5]
    }

    calculateHorizontal_(relations, facts) {
        if (facts.length === 0 || facts[0] === undefined) return
        function addOne(arr, res, final) {
            if (arr.length === 0) {
                final.push(res);
                return
            }
            _.range(1, 6).forEach((i) => {
                let c = arr.slice(0, i);
                if (c.length < i) return
                let temp_arr = arr.slice(i)
                let res_temp = [...res]
                res_temp.push(c)
                addOne(temp_arr, res_temp, final)
            })
        }
        let _res = [], result = [];
        addOne(_.range(0, facts.length), _res, result)
        result = result.filter((r) => {
            return r.length <= _.max([r.length > 2 ? 2 : 1, Math.round(facts.length / 2)])
        })
        let _result = result.filter((r) => {
            return r.length <= 3
        })
        if (_result.length !== 0) { // 可以塞下5行
            result = _result
        }
        // 取最大score
        let max_score = Math.max.apply(Math, facts.map(f => {
            return f.score;
        }));
        // 处理result的布局排列, 其中result[i]是一种排列方式
        let compare_list = [], num_list = [];
        let area_list = []
        // let results_line = []
        // let results_multi = [];
        result.forEach((_result, index) => {
            let sim_avg_line = [], sim_cross_line = []
            _result.forEach((r, index) => {
                // r是一行
                for (let _i = 1; _i < r.length; _i++) {
                    if(!this.props.interpolationFlag)
                    sim_avg_line.push(this.factDis(facts[r[_i - 1]], facts[r[_i]]))
                    else sim_avg_line.push(1)
                    // sim_avg_line.push(factDistance(facts[r[_i-1]], facts[r[_i]]))
                }
                if (index !== _result.length - 1) {
                    if(!this.props.interpolationFlag)
                    sim_cross_line.push(factDistance(facts[r[r.length - 1]], facts[r[r.length - 1] + 1]))
                    else sim_cross_line.push(1)
                }
            })

            let m_DBI = _result.length === 1 ? 0 : _.mean(sim_cross_line) ? _.mean(sim_cross_line) : 0 - _.mean(sim_avg_line);
            // 处理具体布局
            // let area_temps = [[]];
            let area_temp = [];
            _result.forEach((r) => {

                // r是单行
                if (r.length === 5) {
                    area_temp.push(..._.times(5, _.constant(2)))
                } else if (r.length === 1) {
                    area_temp.push(6)
                } else if (r.length === 2) {
                    area_temp.push(..._.times(2, _.constant(5)))
                } else if (r.length === 3) {
                    area_temp.push(..._.times(3, _.constant(4)))
                } 
                else {
                        area_temp.push(..._.times(4, _.constant(3)))
                }

            })
            area_list.push(area_temp)
            // area_temps.forEach((area_temp)=>{
            let s = 0;
            for (let i = 0; i < facts.length; i++) {
                let _score 
                if(!this.props.interpolationFlag)
                _score = normalized(facts[i].score, max_score, 0) ? normalized(facts[i].score, max_score, 0) : 0
                else _score=0
                
                s += _score * (area_temp[i] === 2.1 ? 2 : area_temp[i])
            }
            s = s / (_result.length * 6)
            m_DBI = normalized(m_DBI, 1, -1)

            compare_list.push(s + m_DBI)
            num_list.push([s, m_DBI])
        })
        let final_layout = result[_.indexOf(compare_list, _.max(compare_list))]
        let factStyle = {};
        final_layout.forEach((perLine, index) => {
            if (perLine.length === 3) { // 3个并列
                perLine.forEach((factId) => {
                    factStyle[factId] = '2p3'
                })
            } else if (perLine.length === 1) { // 单个
                factStyle[perLine[0]] = '6p'
            } else if((perLine.length === 2)) { //2个并列
                let _relation = relations[perLine[1]]
                if (_relation === 'generalization') { //1:2
                    factStyle[perLine[0]] = '2p'
                    factStyle[perLine[1]] = '4p'
                } else if (_relation === 'elaboration') {
                    factStyle[perLine[0]] = '4p'
                    factStyle[perLine[1]] = '2p'
                } else {
                    factStyle[perLine[0]] = '3p'
                    factStyle[perLine[1]] = '3p'
                }
            }else if((perLine.length === 4)) { //4个并列
                perLine.forEach((factId) => {
                    factStyle[factId] = '4p2'
                })
            }else{ //5个并列
                perLine.forEach((factId) => {
                    factStyle[factId] = '5p'
                })
            }
        })
        let last_in_line5 = -1;
        if (final_layout.length > 3) {
            last_in_line5 = final_layout[2].slice(-1)[0]
        }
        return [factStyle, final_layout.length, last_in_line5]
    }



    setScroll = (status) => {
        var setGrabbingVar = setGrabbing(this.container);
        var setGrabVar = setGrab(this.container);
        function setGrabbing(container) {
            container.style.cursor = 'grabbing'
        }
        function setGrab(container) {
            container.style.cursor = 'grab'
        }
        this.container.scrollTop = 0;
        this.container.style.overflowY = status ? 'scroll' : 'hidden';
        this.container.style.cursor = status ? 'grab' : 'default';
        if (status) {
            this.container.addEventListener('mousedown', setGrabbingVar);
            this.container.addEventListener('mouseup', setGrabVar)
        } else {
            this.container.removeEventListener('mousedown', setGrabbingVar);
            this.container.removeEventListener('mouseup', setGrabVar)
        }
    }


    zoomChange(value, width, containerHeight, duration, why) {
        duration = 500;
        let wid_origin = this.state.basicWidth
        if (value === 'ZoomIn') {
            if (why === 'radio') this.setScroll(true);
            let originX = (width / wid_origin) >= 1 ? -(Math.abs(width - wid_origin)) / 2 * (width / wid_origin) : -(Math.abs(width - wid_origin)) / 2 * (width / wid_origin)
            this.zoomRef.props.transform(originX, 0, `${(width / wid_origin)}`, duration, 'easeOut');
            setTimeout(() => {
                this.setState({
                    zoomState: 'ZoomIn',
                    zoomLevel: width / wid_origin
                })
            }, 700)
        } else {
            this.setScroll(false);
            this.zoomRef.props.transform((width - this.state.basicWidth * (containerHeight) / this.state.basicHeight) / 2, 0, containerHeight / this.state.basicHeight, duration, 'easeOut');
            setTimeout(() => {
                this.setState({
                    zoomState: 'ZoomOut',
                    zoomLevel: containerHeight / this.state.basicHeight
                })
            }, 700)
        }
        this.dragFS.props.children.props.children.props.componentRef.current.offsetParent.style.transform = "translate(0px, 0px)"
    }

    getZoomLevel() {
        let level = [0.1, 0.25, 0.50, 0.75, 1.00, 1.25, 1.50, 2.00]
        return <Menu>
            {
                level.map((subMunue, idx) => {
                    return <Menu.Item key={"subMunue" + idx} className='MenuItem' onClick={() => this.changeZoomLevel(subMunue, this.state.containerWidth, this.state.containerHeight, '')}>{subMunue * 100 + '%'}</Menu.Item>
                })
            }
        </Menu>

    }

    changeZoomLevel(level, width, containerHeight, why) {
        if (level === this.state.zoomLevel) return
        if (Math.abs(this.dragFS.state.y) >= this.state.containerHeight) {
            this.dragFS.setState({
                y: 0
            })
        }
        if ((this.state.zoomLevel < this.state.containerWidth / this.state.basicWidth && level >= this.state.containerWidth / this.state.basicWidth) || level < this.state.containerWidth / this.state.basicWidth || Math.abs(this.dragFS.state.x) >= this.state.containerWidth) {
            this.dragFS.setState({
                x: 0
            })
        }

        let x = level >= this.state.containerWidth / this.state.basicWidth ? this.dragFS.state.x : (width - this.state.basicWidth * level) / 2
        if (why === 'height' || (why === 'full' && this.state.containerWidth / this.state.basicWidth > 1)) {
            this.dragFS.props.children.props.children.props.componentRef.current.offsetParent.style.transform = "translate(0px, 0px)"
            this.dragFS.setState({
                y: 0,
                x: 0
            })
        } else if (why === 'full' || why === 'width') {
            this.dragFS.props.children.props.children.props.componentRef.current.offsetParent.style.transform = "translate(0px, 0px)"
            this.dragFS.setState({
                y: 0,
                x: 0
            })
            x = 0;
        }
        this.zoomRef.props.transform(x, 0, level, 500, 'easeOut');
        setTimeout(() => {
            this.setState({
                zoomLevel: level
            })
        }, 700)

    }

    clickZoomLevel(direction) {
        let newZoomLevel = this.state.zoomLevel + direction * 0.1
        this.zoomRef.props.transform((this.state.containerWidth - this.state.basicWidth * newZoomLevel) / 2, 0, newZoomLevel, 300, 'easeOut');
        setTimeout(() => {
            this.setState({
                zoomLevel: newZoomLevel
            })
        }, 500)
    }

    changePointer(type) {
        // type: 0 pointer
        // tyoe: 1 hand
        var setGrabbingVar = setGrabbing(this.container);
        var setGrabVar = setGrab(this.container);
        function setGrabbing(container) {
            container.style.cursor = 'grabbing'
        }
        function setGrab(container) {
            container.style.cursor = 'grab'
        }
        this.container.style.cursor = type === 1 ? 'grab' : 'default';
        if (type === 0) {
            this.dragFS.props.children.props.children.props.componentRef.current.offsetParent.style.transform = "translate(0px, 0px)"
            this.container.addEventListener('mousedown', setGrabbingVar);
            this.container.addEventListener('mouseup', setGrabVar)
        } else {
            this.container.removeEventListener('mousedown', setGrabbingVar);
            this.container.removeEventListener('mouseup', setGrabVar)
        }
        this.setState({
            cursorType: type
        })
    }

    render() {
        let { facts, relations } = this.props;
        let _count = 0;
        facts.forEach(fact => {
            if ((fact.measure.length === 0 && fact.subspace.length === 0 && fact.groupby.length === 0 && fact.focus.length === 0 && fact.parameter.length === 0)) {
                _count += 1;
            }
        })
        if (_count === facts.length) {
            facts = []
        }
        let events = facts.map(function (x, i) {
            return {
                id: uuidv4(),
                index: i,
                fact: x,
                relation: relations[i]
            }
        });
        events = events.filter(e => {
            if (!e.fact.aggregated) {
                return true
            } else {
                return e.fact.aggregated && e.fact.aggregatedFact
            }
        })
        facts = events.map(function (x, i) {
            return x.fact
        })
        relations = events.map(function (x, i) {
            return x.relation
        })
        let factStyle, factLine, last_in_line5,slotMarginTop,slotHeight
        if(this.props.factsheetLayoutType==="Horizontal"){
           [factStyle, factLine, last_in_line5]= this.calculateHorizontal_(relations, facts) || [{}, 0, -1]
            events = last_in_line5 !== -1 ? events.slice(0, last_in_line5 + 1) : events
            factLine = last_in_line5 !== -1 ? 3 : factLine
            slotMarginTop = _.min([(720 - factLine * 240) / (factLine), 20])
            slotMarginTop = slotMarginTop === 0 ? 6 : slotMarginTop
            slotHeight = factLine === 3 ? 240 : (720 - slotMarginTop * factLine) / factLine
        }
        else {
            [factStyle, factLine, last_in_line5]= this.calculateVertical_(relations, facts) || [{}, 0, -1]
            events = last_in_line5 !== -1 ? events.slice(0, last_in_line5 + 1) : events
            factLine = last_in_line5 !== -1 ? 5 : factLine
            slotMarginTop = _.min([(1000 - factLine * 200) / (factLine), 20])
            slotMarginTop = slotMarginTop === 0 ? 6 : slotMarginTop
            slotHeight = factLine === 5 ? 200 : (1000 - slotMarginTop * factLine) / factLine
        }
        // let [factStyle, factLine, last_in_line5] = this.calculate_(relations, facts) || [{}, 0, -1]

        // events = last_in_line5 !== -1 ? events.slice(0, last_in_line5 + 1) : events
        // factLine = last_in_line5 !== -1 ? 5 : factLine

        // let slotMarginTop = _.min([(1000 - factLine * 200) / (factLine), 20])
        // slotMarginTop = slotMarginTop === 0 ? 6 : slotMarginTop
        // let slotHeight = factLine === 5 ? 200 : (1000 - slotMarginTop * factLine) / factLine
        const title = this.props.fileName.split('.')[0].toUpperCase();

        return (
            <div style={{
                height: this.props.forPublish ? '90%' : 'calc(100% - 0px)',
                position: 'relative'
            }}>

                {!this.props.forPublish ? (<div className="factsheetContainer" ref={el => (this.container = el)} style={{ height: `${this.props.forPublish ? '' : `${this.state.containerHeight + 10}px`}`, width: `${this.state.containerWidth}px`, backgroundColor: '', margin: this.props.StoryViewFlag||this.props.isHiddenFactView?'0': '0 -45px'}}><TransformWrapper
                    key={this.state.tsKey}
                    defaultScale={this.state.zoomLevel}
                    defaultPositionX={(this.state.containerWidth - this.state.basicWidth * this.state.zoomLevel) / 2}
                    defaultPositionY={0}
                    options={{
                        limitToBounds: false, minScale: (this.state.containerHeight - 100) / this.state.basicHeight, centerContent: false
                    }}
                    pan={{
                        disabled: true,
                        lockAxisX: true,
                        lockAxisY: false,
                        velocityEqualToMove: true,
                        enableVelocity: true
                    }}
                    wheel={{
                        wheelEnabled: false,
                        touchPadEnabled: false
                    }}
                    doubleClick={{ disabled: true }}
                >
                    {({ setTransform, resetTransform, setDefaultState }) => (
                        <React.Fragment>
                            <div className="tools" style={{ display: 'none' }}>
                                <Radio.Group size="small" ref={el => this.zoomRef = el} defaultValue="ZoomOut" onChange={(e) => this.zoomChange(e.target.value, this.state.containerWidth, this.state.containerHeight, 0, 'radio')} transform={setTransform} reset={resetTransform} re={setDefaultState}>
                                </Radio.Group>
                            </div>
                            <TransformComponent style={{ margin: '0 auto' }}>
                                <Draggable defaultPosition={{ x: 0, y: 0 }} axis={this.state.zoomLevel >= this.state.containerWidth / this.state.basicWidth ? 'both' : 'y'} cancel=".fs_edit" disabled={this.state.cursorType === 0 ? true : false} enableUserSelectHack={true} ref={el => this.dragFS = el}>
                                    <div style={{ width: `${this.state.containerWidth}px`, height: `${this.state.containerWidth / this.state.basicWidth * this.state.basicHeight}px`, minHeight: `${this.props.forPublish ? '' : this.state.containerHeight + 'px'}` }}>
                                        <FactSheet componentRef={this.componentRef} slotMarginTop={slotMarginTop} events={events} slotHeight={slotHeight} factLine={factLine} title={title} factStyle={factStyle} {...this.props} />
                                    </div>
                                </Draggable>
                            </TransformComponent>

                        </React.Fragment>

                    )}
                </TransformWrapper>
                    <div className="bottomTools" >

                        <div className={this.state.cursorType === 0 ? "toolBtn toolPointerChecked" : "toolBtn toolPointer"} onClick={() => this.changePointer(0)}>
                        </div>
                        <div className={this.state.cursorType === 1 ? "toolBtn toolHandChecked" : "toolBtn toolHand"} onClick={() => this.changePointer(1)}>
                        </div>

                        <div className="fs-split" >
                            <i aria-label="icon: plus" className="anticon anticon-plus"><svg style={{ color: '#bec1c3' }} viewBox="64 64 896 896" focusable="false" className="" data-icon="plus" width="1em" height="1em" fill="currentColor" aria-hidden="true"><path d="M482 152h60q8 0 8 8v704q0 8-8 8h-60q-8 0-8-8V160q0-8 8-8z"></path></svg></i>
                        </div>

                        <div className={"toolBtn toolZoomAdd"} onClick={() => this.clickZoomLevel(1)}>
                        </div>
                        <div className={"toolBtn toolZoomMin"} onClick={() => this.clickZoomLevel(-1)}>
                        </div>

                        <div className="fs-zoom">
                            <Dropdown overlay={this.getZoomLevel()} trigger={['click']} style={{ color: '#bec1c3' }}>
                                <div className="ant-dropdown-link" onClick={e => e.preventDefault()} style={{ marginBottom: '1px' }}>
                                    <span className="fs-zoom-text" style={{ width: "35px" }}>{Math.round(this.state.zoomLevel * 100) + '%'}</span>
                                    <div className={"toolBtn toolSort"} style={{ display: 'inline-block', width: '7px', paddingLeft: '2px' }}></div>
                                    {/* <Icon type="down" /> */}
                                </div>
                            </Dropdown>
                        </div>

                        <div className="fs-split">
                            <i aria-label="icon: plus" className="anticon anticon-plus"><svg style={{ color: '#bec1c3' }} viewBox="64 64 896 896" focusable="false" className="" data-icon="plus" width="1em" height="1em" fill="currentColor" aria-hidden="true"><path d="M482 152h60q8 0 8 8v704q0 8-8 8h-60q-8 0-8-8V160q0-8 8-8z"></path></svg></i>
                        </div>

                        <div className={"toolBtn toolHeight"} onClick={() => this.changeZoomLevel(this.state.containerHeight / 1166, this.state.containerWidth, this.state.containerHeight, 'height')}>
                        </div>
                        <div className={"toolBtn toolWidth"} onClick={() => this.changeZoomLevel(this.state.containerWidth / 825, this.state.containerWidth, this.state.containerHeight, 'width')}>
                        </div>
                        <div className={"toolBtn toolFull"} onClick={() => this.changeZoomLevel(1, this.state.containerWidth, this.state.containerHeight, 'full')}>
                        </div>
                        <div className="fs-split">
                            <i aria-label="icon: plus" className="anticon anticon-plus"><svg style={{ color: '#bec1c3' }} viewBox="64 64 896 896" focusable="false" className="" data-icon="plus" width="1em" height="1em" fill="currentColor" aria-hidden="true"><path d="M482 152h60q8 0 8 8v704q0 8-8 8h-60q-8 0-8-8V160q0-8 8-8z"></path></svg></i>
                        </div>
                        <ReactToPrint
                            trigger={() => <div className={"toolBtn toolPrint"} >
                            </div>}
                            content={() => this.componentRef.current}
                        />
                    </div>
                </div>) : (
                        <div className="factsheetContainer" style={{ display: "flex", flexDirection: 'column', alignItems: 'center',overflow:'scroll' }}>
                            <FactSheet componentRef={this.componentRef} slotMarginTop={slotMarginTop} events={events} slotHeight={slotHeight} factLine={factLine} title={title} factStyle={factStyle} {...this.props} />
                        </div>
                    )}
            </div>

        )
    }
}
