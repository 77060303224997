const templates = [
    {
        'id': 0,
        'template': '当{{subspace}}时，与其他 {{groupby}}s 相比， {{focus}} 的 {{agg}} {{measure}} 是一个异常值.',
    },
    {
        'id': 1,
        'template': '当{{subspace}}时， {{focus}} 的 {{agg}} {{measure}} 和其他 {{groupby}}s 相比有显著差异.',
    },
    {
        'id': 2,
        'template': '当{{subspace}}时，相比其他 {{groupby}}s， {{focus}} 的 {{agg}} {{measure}} 存在明显差异',

    },
]

export default templates;