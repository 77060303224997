import { connect } from 'react-redux';
import { fileName, real_filename, schema, relations, storyParameter, maxStoryLength, data, resultCoverage, aggregationLevel, facts, unusedFacts, rewardWeight, title,subtitle,filters,filtersTitle,datares,schemaFilter,filtersChangeFlag,dataresLocal, filtersLocal, filtersTitleLocal, schemaFilterLocal,animationFlag,animationType,algorithm,calSimilarityType,maximumFact, filtersOrigin,filtersTitleOrigin} from '@/selector/story';
import * as dataAction from '@/action/dataAction';
import * as factAction from '@/action/factAction';
import * as storyAction from '@/action/storyAction';
import FactList from './FactList';
import { editingCurrentFact, recommendedEfacts, recommendedMfacts, recommendedSfacts } from '../../../selector/story';
import * as userAction from '@/action/userAction';
import { getUserInfo } from '../../../selector/user';


const mapStateToProps = (state) => ({
    schema: schema(state),
    facts: facts(state),
    unusedFacts: unusedFacts(state),
    relations: relations(state),
    data: data(state),
    title: title(state),
    subtitle:subtitle(state),
    resultCoverage: resultCoverage(state),
    fileName: fileName(state),
    real_filename: real_filename(state),
    storyParameter: storyParameter(state),
    maxStoryLength: maxStoryLength(state),
    rewardWeight: rewardWeight(state),
    aggregationLevel: aggregationLevel(state),
    filters:filters(state),
    filtersTitle:filtersTitle(state),
    datares:datares(state),
    schemaFilter:schemaFilter(state),
    filtersChangeFlag:filtersChangeFlag(state),
    filtersLocal:filtersLocal(state),
    filtersTitleLocal:filtersTitleLocal(state),
    dataresLocal:dataresLocal(state),
    schemaFilterLocal:schemaFilterLocal(state),
    editingCurrentFact:editingCurrentFact(state),
    // new
    animationFlag: animationFlag(state),
    animationType: animationType(state),
    // algorithm
    algorithm:algorithm(state),
    calSimilarityType:calSimilarityType(state),
    maximumFact:maximumFact(state),
    filtersOrigin:filtersOrigin(state),
    filtersTitleOrigin:filtersTitleOrigin(state),
    recommendedSfacts:recommendedSfacts(state),
    recommendedMfacts:recommendedMfacts(state),
    recommendedEfacts:recommendedEfacts(state),
    userInfo: getUserInfo(state),
})

const mapDispatchToProps = dispatch => {
    return {
        //data
        visualizeData: (data) => dispatch(dataAction.visualizeData(data)),
        changeData: (fileName, real_filename) => dispatch(dataAction.changeData(fileName, real_filename)),
        uploadData: (fileName, real_filename, schema, data) => dispatch(dataAction.uploadData(fileName, real_filename, schema, data)),
        //fact
        addFact: (facts) => dispatch(factAction.addFact(facts)),
        insertFact: (index, insert) => dispatch(factAction.insertFact(index, insert)),
        updateFact: (index, fact) => dispatch(factAction.updateFact(index, fact)),
        deleteFact: (index) => dispatch(factAction.deleteFact(index)),
        deleteUnusedFact: (index) => dispatch(factAction.deleteUnusedFact(index)),
        orderFacts: (sourceIndex, destinationIndex) => dispatch(factAction.orderFacts(sourceIndex, destinationIndex)),
        //story
        selectFact: (index) => dispatch(storyAction.selectFact(index)),
        generateStory: (facts, relations, coverage) => dispatch(storyAction.generateStory(facts, relations, coverage)),
        changeMethod: (method) => dispatch(storyAction.changeMethod(method)),
        setStoryParameter: (maxStoryLength, information, chartDiversity, timeLimit) => dispatch(storyAction.setStoryParameter(maxStoryLength, information, chartDiversity, timeLimit)),
        setRewardWeight: (logicality, diversity, integrity) => dispatch(storyAction.setRewardWeight(logicality, diversity, integrity)),
        setAggregationLevel: (level) => dispatch(storyAction.setAggregationLevel(level)),
        changeTitle: (title) => dispatch(storyAction.changeTitle(title)),
        changeSubTitle:(subtitle)=>dispatch(storyAction.changeSubTitle(subtitle)),
        //edit filters
        updateFiltersLocal: (filtersLocal, filtersTitleLocal, dataresLocal, schemaFilterLocal) => dispatch(factAction.updateFiltersLocal(filtersLocal, filtersTitleLocal, dataresLocal, schemaFilterLocal)), 

        //new
        changeToAnimation: (animationFlag) => dispatch(storyAction.changeToAnimation(animationFlag)),

        //edit filters
        updateFilters:(filters)=>dispatch(factAction.updateFilters(filters)),
        updateFiltersTitle:(filtersTitle)=>dispatch(factAction.updateFiltersTitle(filtersTitle)),
        updateDatares:(datares)=>dispatch(factAction.updateDatares(datares)),
        updateSchemaFilter:(schemaFilter)=>dispatch(factAction.updateSchemaFilter(schemaFilter)),
        updateFiltersChangeFlag:(filtersChangeFlag)=>dispatch(factAction.updateFiltersChangeFlag(filtersChangeFlag)),

        changeAlgorithm:(algorithm,calSimilarityType,maximumFact)=>dispatch(storyAction.changeAlgorithm(algorithm,calSimilarityType,maximumFact)),
        deleteEFact:(index)=>dispatch(factAction.deleteEFact(index)),
        deleteSFact:(index)=>dispatch(factAction.deleteSFact(index)),
        deleteMFact:(index)=>dispatch(factAction.deleteMFact(index)),
        insertEmptyFact:(insert,emptyFact) => dispatch(factAction.insertEmptyFact(insert,emptyFact)),
        changeCurrentEditing:(editstate,editingCurrentFact)=>dispatch(factAction.changeCurrentEditing(editstate,editingCurrentFact)),
        loadingGeneratingView: (isGenerateStory) => dispatch(userAction.loadingGeneratingView(isGenerateStory)),
        inserRecommendedFact: (index, insert,factLocation) => dispatch(factAction.inserRecommendedFact(index, insert,factLocation)),
    }
}

export default connect(mapStateToProps, mapDispatchToProps, null, { forwardRef: true })(FactList);
