const templates = [
    {
        'id': 0,
        'template': 'When {{subspace}}, among all {{groupby}}s, {{parameter[0]}} has the {{focus}} {{agg}} {{measure}} value, which is {{parameter[1]}}.',
    },
    {
        'id': 1,
        'template': 'When {{subspace}}, {{parameter[0]}} is the {{groupby}} that has the {{focus}} {{agg}} {{measure}} value, which is {{parameter[1]}}.',
    },
    {
        'id': 2,
        'template': 'When {{subspace}}, among all {{groupby}}s, {{parameter[0]}} has the {{focus}} {{agg}} {{measure}} value.',
    },
]

export default templates;