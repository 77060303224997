import React from 'react';
import { } from 'antd';
import LocaleConfig from '@/constant/LocaleConfig';
import './LocaleConfigView.less';

export default class LocaleConfigView extends React.Component {
    changeLocale = (e) => {
        let currentLang = e.target.innerText
        let newLang;
        if (currentLang === '中 文') {
            newLang = LocaleConfig.Chinese
        } else {
            newLang = LocaleConfig.English
        }
        this.props.onChangeLocaleListener(newLang)
    }
    render() {
        const { currentLocale } = this.props
        let lang;

        if (currentLocale) {
            if (currentLocale === 'en-US') {
                lang = "中 文"
            } else {
                lang = "English"
            }
        }

        return (
            <div className='localeBtn' onClick={(e) => this.changeLocale(e)}>{lang}</div>
        )
    }
}