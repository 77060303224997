const templates = [
    {
        'id': 0,
        'template': 'When {{subspace}}, the top three {{groupby}}s, ordered by their {{agg}} {{measure}}, are {{no.1}}, {{no.2}}, and {{no.3}}.',
    },
    {
        'id': 1,
        'template': 'When {{subspace}}, {{no.1}}, {{no.2}}, and {{no.3}} are the three {{groupby}}s that have the highest {{agg}} {{measure}}.',
    },
    {
        'id': 2,
        'template': 'When {{subspace}}, {{no.1}}, {{no.2}}, and {{no.3}} are the top three {{groupby}}s in terms of {{agg}} {{measure}}.',
    },
]

export default templates;