const templates = [
    {
        'id': 0,
        'template': '当{{subspace}}时， {{parameter[0]}} 的 {{agg}} {{measure}} 等于 {{parameter[1]}}，在所有的 {{groupby}}s 中是 {{focus}}.',
    },
    {
        'id': 1,
        'template': '当{{subspace}}时， {{agg}} {{measure}} 的 {{focus}} 等于 {{parameter[1]}}，其 {{groupby}} 等于 {{parameter[0]}}.',

    },
    {
        'id': 2,
        'template': '当{{subspace}}时， {{parameter[0]}} 的 {{agg}} {{measure}} 取 {{focus}}，等于 {{parameter[1]}}',
    },
]

export default templates;