const templates = [
    {
        'id': 0,
        'template': 'When {{subspace}}, the {{focus}} accounts for {{parameter}} of the {{agg}} {{measure}}.',
    },
    {
        'id': 1,
        'template': 'When {{subspace}}, the {{focus}} makes up {{parameter}} of the {{agg}} {{measure}}.',
    },
    {
        'id': 2,
        'template': 'When {{subspace}}, the percentage of the {{focus}} in {{agg}} {{measure}} is {{parameter}}.',
    },
]

export default templates;