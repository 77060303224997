import { connect } from 'react-redux';
import CardList from './CardList';
import { facts, relations, data, resultCoverage, title } from '@/selector/story';
import { OperateRelatedId, isWindowUpScroll, isLogIn } from '@/selector/user';
import * as userAction from '@/action/userAction';
import * as storyAction from '@/action/storyAction';

const mapStateToProps = (state) => ({
    facts: facts(state),
    relations: relations(state),
    data: data(state),
    resultCoverage: resultCoverage(state),
    isWindowUpScroll: isWindowUpScroll(state),
    OperateRelatedId: OperateRelatedId(state),
    isLogIn: isLogIn(state),
    title: title(state)
})

const mapDispatchToProps = dispatch => {
    return {
        shareStory: (isShareStory) => dispatch(userAction.shareStory(isShareStory)),
        changeLayOutType: (layoutType) => dispatch(userAction.changeLayOutType(layoutType)),
        updateOperateRelatedId: ({ did, sid }) => dispatch(userAction.updateOperateRelatedId({ did, sid })),
        updateCommentStoryInfo: (facts, relations, data, schema, title, fileName,real_filename, storyParameter, aggregationLevel, resultCoverage, showSuggestion,subtitle,dataresLocal,schemaFilterLocal,filtersLocal,filtersTitleLocal ) => dispatch(storyAction.updateCommentStoryInfo(facts, relations, data, schema, title, fileName,real_filename, storyParameter, aggregationLevel, resultCoverage, showSuggestion,subtitle,dataresLocal,schemaFilterLocal,filtersLocal,filtersTitleLocal ))
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(CardList);
