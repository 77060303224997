const templates = [
    {
        'id': 0,
        'template': 'When {{subspace}}, the distribution of the {{agg}} {{measure}}, by different {{groupby}}s, is as follows.',
    },
    {
        'id': 1,
        'template': 'When {{subspace}}, the breakdown of the {{agg}} {{measure}}, by different {{groupby}}s, is as follows.',
    },
    {
        'id': 2,
        'template': 'When {{subspace}}, here is the distribution of the {{agg}} {{measure}}, by different {{groupby}}s.',
    },
]

export default templates;