import React, { Component } from "react";
import { message } from "antd";
import _ from 'lodash';
import moment from "moment";
import './StoryInformationView.less';

export default class StoryInformationView extends Component {
    constructor(props) {
        super(props)
        this.state = {
            isEditable: this.props.isEditable || false,
            title: this.props.title,
            story: this.props.story
        }
    }
    shouldComponentUpdate(nextProps, nextState) {
        const { story } = this.state
        if (_.isEqual(nextProps.story, story)&&_.isEqual(nextProps.isMobileEnd, this.props.isMobileEnd)) {
            return false
        } else {
            this.setState({
                story: nextProps.story
            })
        }
        return true
    }
    reviewAuthor = (user) => {
        const { username, uid } = user
        this.props.history.push({
            pathname: `/index/existingStoriesCreatedBy/${uid}/${username}`
        })
    }
    endEditing = () => {
        let title = this.titleNode.innerText;
        if (title === '') {
            message.info(this.props.intl.get('Title cannot be null'))
            this.titleNode.focus()
            this.titleNode.innerText = this.props.intl.get('please input title here')
            return
        }
        this.props.changeTitle(title)
    }
    render() {
        const { story, intl, isMobileEnd} = this.props
        const { isEditable } = this.state
        return (
            <div className={isEditable?'StoryInformationView':'StoryInformationViewComment'}>
                <div className='titleP'>{intl.get("Story")}
                    <div className='storyTitle'
                        ref={(titleNode) => this.titleNode = titleNode}
                        suppressContentEditableWarning={true}
                        contentEditable={isEditable}
                        onBlur={this.endEditing}
                        onKeyDown={(e) => {
                            //console.log(e.keyCode)
                            if (e.keyCode === 13) {
                                if (this.titleNode) {
                                    this.titleNode.blur()
                                }
                                e.preventDefault()
                            }
                            if (e.keyCode === 8) {//删除按键
                                if (this.titleNode && this.titleNode.innerText.length === 1) {
                                    e.preventDefault()
                                    message.info(intl.get('Title cannot be null'))
                                }
                            }
                        }}
                    >
                        {(story && story.title) || '--'}
                    </div>
                </div>
                <div className='createdInfo'>
                    <p className='createdOn'><span className='views'>{`${(story && story.num_of_views) || 0}  ${intl.get("views")}`}</span></p>
                    <p className='createdBy' style={{ display: isMobileEnd ? 'none' : 'block'}}>{intl.get("Created by")} <span className='creater' onClick={() => this.reviewAuthor(story && story.user)}>{story && story.user && story.user.username}</span></p>
                    {
                        (intl && intl.options.currentLocale === 'zh-CN')
                            ?  
                            <p className='createdOn'  style={{ display: isMobileEnd ? 'none' : 'block'}}>{intl.get("Created on")} <span className='createdTime'> {story && story.time ? moment(story.time).format("YYYY-MM-DD HH:mm") : moment().format("YYYY-MM-DD HH:mm")}</span></p>
                            :
                            <p className='createdOn'  style={{ display: isMobileEnd ? 'none' : 'block'}}>{intl.get("Created on")} <span className='createdTime'> {story && story.time ? moment(story.time).format("dddd MMMM Do, h:mm a") : moment().format("dddd MMMM Do, h:mm a")}</span></p>
                    }
                </div>
            </div>
        )
    }
}